import { useEffect, useState } from "react";
import { getAlldata } from "../controllers/APIController";

export default function useGetSectorName() {
  const [allSectorName, setAllSectorName] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAlldata("sectors")
      .then((data) => {
        const list = data?.data;
        const valuesList = list?.map((item) => {
          return {
            key: item?.attributes?.Name?.toLowerCase(),
            name: item?.attributes?.Name,
          };
        });

        setAllSectorName(valuesList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return { allSectorName, setAllSectorName, isLoading };
}
