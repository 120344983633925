import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";

import { Controlled as CodeMirror } from "react-codemirror2";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import React, { useEffect, useRef, useState } from "react";
import {
  functionDefInfo,
  isValidFunctionDefination,
} from "../../utils/constant";

const initalValidationError = {
  title: "",
  functionDefination: "",
};

const JSonResponseInfoContentComp = () => {
  return (
    <>
      <pre className="text-xs font-semibold ">{functionDefInfo.toString()}</pre>
    </>
  );
};
const EditFormulaModal = ({
  onSubmitHandler,
  id,
  modalHeading,
  isOpen,
  onOpen,
  onOpenChange,
  fieldType,
  item,
}) => {
  const [title, setTitle] = useState("");
  const [functionDefintation, setFunctionDefination] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    ...initalValidationError,
  });
  const currentWordRef = useRef("");

  const validationHandler = () => {
    let isValid = true;
    const newValidationError = { ...initalValidationError };

    if (title === "") {
      newValidationError["title"] = "Title can't be empty";
      isValid = false;
    }

    if (!isValidFunctionDefination(functionDefintation)) {
      newValidationError["functionDefination"] = "Invalid Function Defination.";
      isValid = false;
    }

    setValidationErrors({ ...newValidationError });

    return isValid;
  };
  const submitHandler = (onClose) => {
    const isValid = validationHandler();
    if (isValid === false) return;

    onSubmitHandler({
      id: id,
      title: title,
      fun: functionDefintation,
    });
    onClose();
  };

  const handleKeyDown = (event, onClose) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of form submission on Enter key press
      submitHandler(onClose);
    }
  };
  const handleSuggesstionKeyDown = (editor, event) => {
    if (event.ctrlKey && event.key === " ") {
      // Manually trigger suggestions update
      jsonHint(editor, (suggestions) => {
        editor.showHint({
          hint: function (editor) {
            return suggestions;
          },
        });
      });

      // Prevent default behavior of Ctrl+Space (autocomplete)
      event.preventDefault();
    }
  };
  const handleFunctionDefination = (editor, data, value) => {
    setFunctionDefination(value);

    // const cur = editor.getCursor();
    // const token = editor.getTokenAt(cur);
    // const currEditorWord = token.string;
    // const lastChar = currEditorWord.charAt(currEditorWord.length - 1);
    // const specialCharacters = `/[!@#$%^&*(),.?":= -+{}|<>]/`;
    // if (specialCharacters.includes(lastChar)) {
    //     currentWordRef.current = "";
    // } else {
    //     currentWordRef.current = (currentWordRef.current+lastChar);
    // }
  };

  function jsonHint(editor) {
    const jsonWords = [
      "{}",
      "name",
      "version",
      "author",
      "function",
      "const",
      "let",
      "item1",
      "function1",
      "function2",
      "add12",
      "add2",
    ]; // Add your JSON keywords here

    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);
    const currWord = currentWordRef.current;
    // Filter JSON words that match the current token
    const matches = jsonWords.filter((word) => word.startsWith(currWord));
    setSuggestionList(matches);
    // Return hint object with filtered matches
    return {
      list: matches,
      from: { line: cursor.line, ch: token.start },
      to: { line: cursor.line, ch: token.end },
    };
  }

  useEffect(() => {
    setValidationErrors({ ...initalValidationError });
  }, [isOpen]);

  useEffect(() => {
    setTitle(item.title);
    setFunctionDefination(item.fun);
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <div className="outline-none">
              <ModalHeader className="flex flex-col gap-1">
                Add a New Formula
              </ModalHeader>
              <ModalBody>
                <div>
                  <Input
                    type="text"
                    className="mb-8"
                    isRequired
                    label={fieldType === "link" ? "Name" : "Title"}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={title || ""}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    isInvalid={validationErrors["title"] !== ""}
                    errorMessage={validationErrors["title"]}
                    // onKeyDown={(event) => handleKeyDown(event, onClose)}
                  />
                </div>

                {/* Function Defination Input */}

                <div className="w-full my-2 mb-12">
                  <p className="flex items-center gap-1 text-gray text-sm mb-2">
                    <span>Function Defination</span>
                    <Tooltip
                      content={<JSonResponseInfoContentComp />}
                      className="flex items-center cursor-pointer"
                    >
                      <lord-icon
                        src="https://cdn.lordicon.com/yxczfiyc.json"
                        style={{ width: "18px", height: "18px" }}
                        trigger="hover"
                        colors="primary:#8492a6"
                      ></lord-icon>
                    </Tooltip>
                    <p className="text-[#f76497] text-">
                      {validationErrors["functionDefination"] &&
                        validationErrors["functionDefination"]}
                    </p>
                  </p>

                  <Card>
                    <CardBody className="p-0">
                      <CodeMirror
                        value={functionDefintation}
                        options={{
                          mode: "javascript",
                          theme: "material",
                          lineNumbers: true,
                          tabSize: 2,
                          extraKeys: { "Ctrl-Space": "autocomplete" },
                        }}
                        // onChange={handleInputRead}
                        onBeforeChange={handleFunctionDefination}
                        editorDidMount={(editor) => {
                          //   editor.on("inputRead", handleInputRead);
                          editor.setOption("hintOptions", {
                            hint: jsonHint,
                          });
                        }}
                        onKeyDown={handleSuggesstionKeyDown} // Handle keydown event
                      />
                    </CardBody>
                  </Card>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Update
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditFormulaModal;
