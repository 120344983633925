import React from "react";

const SuggestedTagsList = ({ list = [], onClickHandler }) => {
  if (list.length === 0) return;

//   const clickHandler = (e) => {
//     console.log("hel");
//     onClickHandler();
//   };
  return (
    <div className="absolute border-1 border-gray bg-white top-full mt-2 w-full rounded-md left-0 z-100 max-h-56 overflow-auto hide-scollbar">
      {list?.map((title, index) => {
        return (
          <div
            className="flex items-center gap-2 p-2 cursor-pointer hover:bg-[#f4f4f4]"
            onClick={()=>onClickHandler(title)}
            key={index}
          >
            <lord-icon
              src="https://cdn.lordicon.com/prjooket.json"
              trigger="hover"
              target="#Library"
              state="morph-home-2"
              colors={`primary: "#000000"`}
              style={{ width: "18px", height: "18px" }}
            ></lord-icon>

            <span>{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default SuggestedTagsList;
