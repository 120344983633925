import React, { useEffect, useState } from "react";
import { Avatar, Image, Progress } from "@nextui-org/react";
import { getAlldata } from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/logo.svg";

import { Colors, numColors } from "../../utils/constant";
import OrgFrameworkInfo from "./OrgFrameworkInfo";
import OrgBasicInfo from "./OrgBasicInfo";
import OrgInviteInfo from "./OrgInviteInfo";
import { intialOrgData } from "../../constant/OnboardingOrganization/OnboardingOrganization";

import OrgFinancialInfo from "./OrgFinancialInfo";

const ProgessColors = ["danger", "secondary", "warning", "success"];
const OnboardingOrganization = () => {
  const [currentPage, setCurrrentPage] = useState(0);
  const totalStep = 4;
  const [DataList, setDataList] = useState(intialOrgData);
  const [defaultOrgId, setDefaultOrgId] = useState(null);
  const [allUrlList, setUrlList] = useState([]);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const email = UserLogged && UserLogged.user.email;

  const navigate = useNavigate();

  useEffect(() => {
    getAlldata("organizations")
      .then((data) => {
        const list = [];
        let defaultId = 0;
        data?.data?.forEach((item) => {
          list.push(item?.attributes?.URL);
          if (item?.attributes?.Name?.toLowerCase() === "default") {
            defaultId = item?.id;
          }
        });
        setUrlList(list);
        setDefaultOrgId(defaultId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!UserLogged) {
      navigate("/sign-in");
    }
  }, []);

  const CurrentComp = () => {
    switch (currentPage) {
      case 0:
        return (
          <OrgBasicInfo
            TitleText={""}
            DescriptionText={""}
            DataList={DataList}
            setDataList={setDataList}
            defaultOrgId={defaultOrgId}
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            totalStep={totalStep}
            allUrlList={allUrlList}
          />
        );
      case 1:
        return (
          <OrgFinancialInfo
            TitleText={"Select Your Fiscal Year"}
            DescriptionText={""}
            DataList={DataList}
            setDataList={setDataList}
            defaultOrgId={defaultOrgId}
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            totalStep={totalStep}
          />
        );
      case 2:
        return (
          <OrgFrameworkInfo
            TitleText={"Select Your Framework"}
            DescriptionText={""}
            DataList={DataList}
            setDataList={setDataList}
            defaultOrgId={defaultOrgId}
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            totalStep={totalStep}
          />
        );

      case 3:
        return (
          <OrgInviteInfo
            TitleText={"Invite Your Team"}
            DescriptionText={""}
            DataList={DataList}
            setDataList={setDataList}
            defaultOrgId={defaultOrgId}
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            totalStep={totalStep}
          />
        );
      default:
        return null;
    }
  };

  console.log(DataList);

  return (
    <>
      <div className="w-full min-h-screen px-52 py-12">
        <div className="flex items-center justify-between w-full">
          <Image width={80} alt="NextUI hero Image" src={logo} />
          <Avatar
            showFallback
            className="h-8 w-8 cursor-pointer"
            color={Colors[UserId % numColors]}
            name={email?.toUpperCase().slice(0, 1)}
            src="https://images.unsplash.com/broken"
          />
        </div>
        <div className="flex flex-col mt-4 w-[500px] mx-auto">
          <Progress
            color={ProgessColors[currentPage]}
            size="sm"
            aria-label="Loading..."
            value={25 * currentPage}
          />

          {CurrentComp()}
        </div>
      </div>
    </>
  );
};

export default OnboardingOrganization;
