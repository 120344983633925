import { Tab, Tabs } from "@nextui-org/react";
import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import EditJsonSurveyBuilder from "./EditJsonSurveyBuilder";
import EditUISurveyBuilder from "./EditUISurveyBuilder";


export const EditSurveyBuilder = () => {
  const { _orgId: currOrg } = useParams();

  let tabs = [
    {
      id: "Using Form Builder UI",
      label: "Using UI Builder",
      content: <EditUISurveyBuilder />,
    },
    {
      id: "Using JSON",
      label: "Using JSON",
      content: <EditJsonSurveyBuilder />,
    },
  ];

  return (
    <BreadCrumbsPage>
      <div className="w-full">
        <div className="text-3xl font-medium">Edit a Survey</div>
        <div className="my-8">
          Edit a survey and build that would support your ESG Reporting
        </div>
        <Tabs aria-label="Dynamic tabs" items={tabs} variant="light">
          {(item) => (
            <Tab key={item.id} title={item.label}>
              {item.content}
            </Tab>
          )}
        </Tabs>
      </div>
    </BreadCrumbsPage>
  );
};
