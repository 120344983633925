import environmentImage from "../../Images/environment.svg";
import governanceImage from "../../Images/governance.svg";
import socialImage from "../../Images/social.svg";
import surveyImage from "../../Images/survey.svg";
import generalImage from "../../Images/General.svg";

export const PoliciesCategoryList = [
  {
    key: "general",
    title: "General",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste perferendis recusandae voluptatem.",
  },
  {
    key: "enviroment",
    title: "Enviroment",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste perferendis recusandae voluptatem.",
  },
  {
    key: "social",
    title: "Social",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste perferendis recusandae voluptatem.",
  },
  {
    key: "governance",
    title: "Governance",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste perferendis recusandae voluptatem.",
  },
  {
    key: "surveymetric",
    title: "Survey Metric",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste perferendis recusandae voluptatem.",
  },
];

export const getImageForDomain = (domainName) => {
  switch (domainName.toLowerCase()) {
    case "environment":
      return environmentImage;
    case "governance":
      return governanceImage;
    case "social":
      return socialImage;
    case "survey":
      return surveyImage;
    case "general":
      return generalImage;
    default:
      return generalImage; // Fallback image
  }
};
