import React from "react";
import { Card, Skeleton } from "@nextui-org/react";
const Record = () => {
  return (
    <div className="flex w-[20%]">
      <Skeleton className="w-1/5 rounded-full">
        <div className="h-8 w-1/5 rounded-full bg-default-200"></div>
      </Skeleton>
      <div className="w-4/5 space-y-3 pl-4">
        <Skeleton className="w-4/5 rounded-lg">
          <div className="h-4 w-4/5 rounded-lg bg-default-200"></div>
        </Skeleton>
        <Skeleton className="w-2/5 rounded-lg">
          <div className="h-4 w-2/5 rounded-lg bg-default-200"></div>
        </Skeleton>
      </div>
    </div>
  );
};

const MetricCard = () => {
  return (
    <Card className="w-full space-y-5 p-4 py-8" radius="lg">
      <Skeleton className="rounded-lg w-1/5">
        <div className="h-4 w-2/5 rounded-lg bg-default-300"></div>
      </Skeleton>
      <div className="flex items-center">
        <Record />
        <Record />
        <Record />
        <Record />
      </div>
    </Card>
  );
};

export default MetricCard;
