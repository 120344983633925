import {
  Button,
  Input,
  Switch,
  Textarea,
  CardBody,
  Card,
  DatePicker,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Slider,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { updateFormSchemaWithRealtion } from "../../controllers/APIController";
import { getFormulaData } from "../../utils/helper";

const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    } else if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  return data;
};
const CreateNewEntryButton = ({ name, metricName, onClickHandler }) => {
  const clickHandler = () => {
    onClickHandler(metricName, name);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
      }}
      onClick={clickHandler}
    >
      <span>Create a New Entry </span>
      <span>+</span>
    </div>
  );
};
const DateFormator = (value) => {
  const date = new Date(value);
  const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

const PreviewForm = ({ data }) => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);

  const navigate = useNavigate();
  const { _orgId } = useParams();

  // const { pathname } = useLocation();

  const requiredArr = CollectionInfo?.JsonSchema?.required;

  const changeHandler = async (title, value, type) => {
    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();
        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};
    formSchema.forEach((row) => {
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];
      // You can add more validation rules based on the field type

      if (fieldType === "text" && fieldName === "Name" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "textarea" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "password" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "dropdown" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "enumeration" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType == "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "file") {
        if (fieldValue === "") {
          errors[fieldName] = `Upload a file `;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "richtext") {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required `;
          isValid = false;
        }
      }
    });
    setValidationErrors(errors);
    return isValid;
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      return;
    }
    const formulaData = getFormulaData(data?.JsonSchema?.formula, formdata);

    setFormData({ ...formdata, ...formulaData });
  };

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${_orgId}/${metric}/${relatedToName}/create`);

    window.location.reload();
  };

  useEffect(() => {
    const responseData = data?.JsonSchema?.properties;
    let responseArr = Object.keys(responseData).map((key) => [
      key,
      responseData[key],
    ]);

    responseArr.sort((a, b) => {
      return a[1].id - b[1].id;
    });
    setCollectionInfo(data);
    setformSchema(responseArr);
    setLinks(data?.Links?.Links);
    setFormData(getInitailState(responseArr));
  }, [data]);

  const formatJSON = (jsonObj) => {
    return JSON.stringify(jsonObj, null, 2).replace(
      /"([^"]+)":/g,
      '<span style="color: #cc6570;">"$1"</span>:'
    );
  };

  return (
    <div className="w-full">
      <Card shadow="none" className="border border-divider">
        <CardBody>
          <p className="text-2xl font-medium text-center my-2">
            {CollectionInfo?.Name}
          </p>
          <div className="m-2 my-4">
            {formSchema &&
              formSchema.map((row, index) => {
                const fieldError = validationErrors[row[0]];
                const isRequired = row[1]?.required || false;

                if (
                  row[1].type.toLocaleLowerCase() == "string" ||
                  row[1].type.toLocaleLowerCase() == "text"
                ) {
                  return (
                    <Input
                      type="text"
                      className="mb-16"
                      isRequired={isRequired}
                      label={row[1].title}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "password") {
                  return (
                    <Input
                      type="password"
                      className="mb-16"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "integer") {
                  return (
                    <Input
                      type="number"
                      className="mb-16"
                      isRequired={isRequired}
                      label={row[1].title}
                      placeholder="0"
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], +event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "dropdown") {
                  return (
                    <div className="flex flex-col gap-2 text-sm">
                      <p>{row[1].title}</p>
                      <Dropdown>
                        <DropdownTrigger
                          isInvalid={fieldError && isRequired}
                          errorMessage={fieldError}
                        >
                          <Button className="mb-16" variant="faded">
                            <div className="w-full flex justify-between">
                              <div className="flex justify-start w-full items-start">
                                {formdata[row[0]] === ""
                                  ? ""
                                  : formdata[row[0]]}
                              </div>
                              <div className="flex justify-end w-full items-end">
                                <FaChevronDown />
                              </div>
                            </div>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Static Actions"
                          className="w-144"
                          selectionMode="single"
                          selectedKeys={"a"}
                          onSelectionChange={(event) => {
                            changeHandler(
                              row[0],
                              Array.from(event).join(", ").replaceAll("_", " "),
                              row[1].type
                            );
                          }}
                        >
                          {row[1]?.values?.map((item) => (
                            <DropdownItem key={item.name}>
                              {item.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "slider") {
                  return (
                    <Slider
                      label={row[1].title}
                      isRequired={requiredArr.includes(row[0])}
                      className="mb-16"
                      step={1}
                      maxValue={100}
                      minValue={0}
                      defaultValue={0}
                      onChange={(value) => {
                        changeHandler([row[0]], value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "boolean") {
                  return (
                    <div>
                      <p className="mb-2">{row[1].title}</p>
                      <Switch
                        defaultSelected={formdata[row[0]] === "true"}
                        color="primary"
                        className="mb-16"
                        label={row[1].title}
                        labelPlacement="outside"
                        onChange={(event) =>
                          changeHandler(
                            row[0],
                            event.target.checked.toString(),
                            row[1].type
                          )
                        }
                      >
                        {formdata[row[0]] === "true" ? "On" : "Off"}
                      </Switch>
                    </div>
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "textarea") {
                  return (
                    <Textarea
                      className="mb-16"
                      label={row[1].title}
                      key="1"
                      isRequired={requiredArr.includes(row[0])}
                      variant="faded"
                      labelPlacement="outside"
                      value={formdata[row[0]]}
                      placeholder=" "
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type.toLocaleLowerCase() === "date") {
                  return (
                    <>
                      <DatePicker
                        label={row[1].title}
                        labelPlacement="outside"
                        className="mb-16"
                        isRequired={requiredArr.includes(row[0])}
                        onChange={(value) => {
                          changeHandler(
                            row[0],
                            DateFormator(value),
                            row[1].type
                          );
                        }}
                        isInvalid={fieldError && isRequired}
                        errorMessage={fieldError}
                      />
                    </>
                  );
                }
                // if (row[1].type === "file") {
                //   return (
                //     <Input
                //       type="file"
                //       className="mb-16"
                //       // isRequired={requiredArr.includes(row[0])}
                //       label={row[1].title}
                //       placeholder=" "
                //       variant="faded"
                //       labelPlacement="outside"
                //       accept="image/*"
                //       // value={row[1].value}
                //       onChange={(event) => {
                //         console.log(event);
                //         changeHandler(
                //           row[0],
                //           event.target.files[0],
                //           row[1].type
                //         );
                //       }}
                //       // onValueChange={(event)=>{console.log(event)}}
                //       // isInvalid={fieldError && isRequired}
                //       // errorMessage={fieldError}
                //     />
                //   );
                // }
                if (row[1].type.toLocaleLowerCase() === "relation") {
                  updateFormSchemaWithRealtion(
                    formSchema,
                    index,
                    setformSchema
                  );
                  return (
                    <div className="flex flex-col gap-2 text-sm">
                      <p>
                        {row[1].title}{" "}
                        {requiredArr.includes(row[0]) && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </p>

                      <Dropdown>
                        <DropdownTrigger
                          invalid={fieldError && isRequired}
                          invalidText={fieldError}
                        >
                          <Button className="mb-16" variant="faded">
                            <div className="w-full flex justify-between">
                              <div className="flex justify-start w-full items-start">
                                {formdata[row[0]] === ""
                                  ? ""
                                  : formdata[row[0]]}
                              </div>
                              <div className="flex justify-end w-full items-end">
                                <FaChevronDown />
                              </div>
                            </div>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Static Actions"
                          className="w-144"
                          selectionMode="single"
                          selectedKeys={"a"}
                          onSelectionChange={(event) => {
                            changeHandler(
                              row[0],
                              Array.from(event).join(", ").replaceAll("_", " "),
                              row[1].type
                            );
                          }}
                        >
                          {row[1].values?.map((item) => (
                            <DropdownItem key={item.name}>
                              {item.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                }
              })}
          </div>
          <div className="flex m-2">
            <Button
              color="danger"
              size="md"
              className=""
              onClick={submitHandler}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PreviewForm;
