export const firstNavBarList = [
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/hqymfzvj.json"
        trigger="hover"
        state="morph-book"
        target="#overview"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Overview",
    path: `/`,
    id: "overview",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/ppyvfomi.json"
        trigger="hover"
        stroke="bold"
        state="morph-book"
        colors={`primary:"#121331",secondary:"#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Import Data",
    path: `/import-data`,
    id: "import-data",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/jkzgajyr.json"
        trigger="hover"
        target="#ReferenceData"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Reference Data",
    path: `/reference-data`,
    id: "ReferenceData",
  },

  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/nizfqlnk.json"
        trigger="hover"
        target="#Metrics"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "ESG Metrics",
    path: `/metrics`,
    id: "Metrics",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rbbnmpcf.json"
        trigger="hover"
        target="#evidences"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Evidences",
    path: `/evidences`,
    id: "evidences",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/whrxobsb.json"
        trigger="hover"
        target="#sustainability-activities"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Sustainability Activities",
    path: `/sustainability-activities`,
    id: "sustainability-activities",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/jfwzwlls.json"
        trigger="hover"
        target="#Report"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Report",
    path: `/report`,
    id: "Report",
  },
];

export const secondNavBarList = [
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/jkgunhbs.json"
        trigger="hover"
        target="#frameworks"
        state="morph"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Browse Frameworks",
    path: `/browse-framework`,
    id: "frameworks",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/zyzoecaw.json"
        trigger="hover"
        target="#frameworks"
        state="morph-book"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "ESG Policies",
    path: `/policies`,
    id: "frameworks",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/prjooket.json"
        trigger="hover"
        target="#Library"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Factor Library",
    path: `/factor-library`,
    id: "Library",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/prjooket.json"
        trigger="hover"
        target="#LLM"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Aduyt LLM",
    path: `/audyt-llm`,
    id: "audyt-llm",
  },
];
export const thirdNavbarList = [
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/kthelypq.json"
        trigger="hover"
        target="#usermanagement"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "User Management",
    path: `/user-management`,
    id: "usermanagement",
  },

  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rpgflpkp.json"
        trigger="hover"
        target="#playground"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Playground",
    path: `/playground`,
    id: "playground",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/pqirzoux.json"
        trigger="hover"
        target="#playground"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Survey Builder",
    path: `/survey-builder`,
    id: "playground",
  },
  {
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/lecprnjb.json"
        trigger="hover"
        target="#settings"
        state="morph-home-2"
        colors={`primary: "#000000"`}
        style={{ width: "16px", height: "16px" }}
      ></lord-icon>
    ),
    title: "Settings",
    path: `/settings`,
    id: "settings",
  },
];
