import { Tab, Tabs } from "@nextui-org/react";
import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import UISurveyBuilder from "./UISurveyBuilder";
import JsonSurveyBuilder from "./JsonSurveyBuilder";

export const SurveyBuilder = () => {
  const { _orgId: currOrg } = useParams();

  let tabs = [
    {
      id: "Using Form Builder UI",
      label: "Using UI Builder",
      content: <UISurveyBuilder />,
    },
    {
      id: "Using JSON",
      label: "Using JSON",
      content: <JsonSurveyBuilder />,
    },
  ];

  return (
    <BreadCrumbsPage>
      <div className="w-full">
        <div className="text-3xl font-medium">Create a Survey</div>
        <div className="my-8">
          Create a survey and build that would support your ESG Reporting
        </div>
        <Tabs aria-label="Dynamic tabs" items={tabs} variant="light">
          {(item) => (
            <Tab key={item.id} title={item.label}>
              {item.content}
            </Tab>
          )}
        </Tabs>
      </div>
    </BreadCrumbsPage>
  );
};
