import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Image, Input, Link } from "@nextui-org/react"

import { FilterUser } from '../../controllers/APIController';
import { passwordlessCode } from '../../controllers/SupertokenController';

const PasswordlessSignIn = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [ListOfUsers, setListOfUsers] = useState(null);
    const [verificationError, setVerificationError] = useState('');
    const [validateTest, setValidateTest] = useState(false);
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [ValidationConditions, setValidationConditions] = useState(false);

    const handleOnNavigate = () => {
        navigate('/login');
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        FilterUser(email)
            .then((data) => {
                setListOfUsers(data);
                console.log("data", data);
            })
            .catch((err) => {
                console.log("error", err);
            });
    }, [email]);

    const validation = () => {
        let isValid = true;

        if (!email) {
            setVerificationError("Email is required");
            isValid = false;
        } else if (!EmailRegex.test(email)) {
            setVerificationError("Enter a valid email format");
            isValid = false;
        } else if (ListOfUsers === false) {
            setVerificationError("Email does not exist. Create an account.");
            isValid = false;
        } else {
            setVerificationError(null);
        }
        return isValid;
    };

    const navigateOTP = () => {
        // const isValid = validation();
        // if (isValid) {
            // setValidateTest(false);
            // const OTPBody = await passwordlessCode(email);
            // sessionStorage.setItem('email', email);
            // sessionStorage.setItem('deviceId', OTPBody.deviceId);
            // sessionStorage.setItem('preAuthSessionId', OTPBody.preAuthSessionId);
            navigate('/verify-signin');
        // } else {
        //     setValidateTest(true);
        // }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="max-w-md w-full p-6 justify-center  ">
                <Image
                    width={50}
                    alt="NextUI hero Image"
                    src="Assets/logo.svg"
                    className="mb-5 flex "
                />
                <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                    Sign in to Audyt
                </h1>
                <div className='mb-6 mt-16'>
                    <Input
                        radius="sm"
                        className='border-slate-6'
                        // isInvalid={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) : ""}
                        // errorMessage={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? LoginError.emailError : ''}
                        key={"outside"}
                        type="email"
                        label="Email"
                        variant={"faded"}
                        // onChange={(e) => setLoginDetails({ ...email, email: e.target.value })}
                        labelPlacement={"outside"}
                        placeholder="Enter your email"
                    />
                </div>
                <div onClick={navigateOTP}>

                    <Button id='CreateAccount' radius="sm" className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}>

                        Receive your verification code
                        <lord-icon
                            src="https://cdn.lordicon.com/vduvxizq.json"
                            trigger="hover"
                            target="#CreateAccount"
                            colors="primary:#ffffff"

                            style={{
                                width: "18px",
                                height: "20px"

                            }}
                        />
                    </Button>

                </div>

                <div className='flex items-center mt-4 gap-2'>
                    <lord-icon
                        src="https://cdn.lordicon.com/yxczfiyc.json"
                        trigger="hover"
                        target="#CreateAccount"
                        colors="primary:#b4b4b4"

                        style={{
                            width: "14px",
                            height: "14px"

                        }}
                    />
                    <span className="text-small text-default-400">We recommend using your work email</span>


                </div>

                <p className='text-small text-default-400 mt-8'>
                    By signing in, you agree to our {" "}
                    <Link href="#" size='sm'>Terms of Service </Link>
                    {" "}and {" "}
                    <Link href="#" size='sm'>Privacy Policy </Link>.
                </p>
            </div>
        </div>
    );
};

export default PasswordlessSignIn;