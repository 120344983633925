import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React from "react";

const DangerModal = ({
  onSubmitHandler,
  id,
  modalHeading,
  isOpen,
  onOpen,
  onOpenChange,
}) => {
  const submitHandler = async (onClose) => {
    await onSubmitHandler(id);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Metric Data
              </ModalHeader>
              <ModalBody>
                <p>{modalHeading}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Delete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default DangerModal;
