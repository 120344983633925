import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const list = [
  {
    id: 1,
    name: "Domain",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rvmukzut.json"
        trigger="hover"
        style={{ width: "50px", height: "50px" }}
      ></lord-icon>
    ),
    url: "/policies-builder/domain",
  },
  {
    id: 2,
    name: "Policy",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rvmukzut.json"
        trigger="hover"
        style={{ width: "50px", height: "50px" }}
      ></lord-icon>
    ),
    url: "/policies-builder/policy",
  },
  {
    id: 3,
    name: "Procedure",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rvmukzut.json"
        trigger="hover"
        style={{ width: "50px", height: "50px" }}
      ></lord-icon>
    ),
    url: "/policies-builder/procedure",
  },
];

const CreatePolicyButtton = () => {
  const navigator = useNavigate();
  const {_orgId} = useParams();
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button color="danger">Create Policy</Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="px-1 py-2 w-80">
          <ul>
            {list?.map((item) => (
              <li
                key={item.id}
                className="flex items-center gap-2 my-2 cursor-pointer hover:opacity-80"
                onClick={() => navigator(`/${_orgId}${item.url}`)}
              >
                {item.icon}
                <div className="flex flex-col">
                  <div className="text-lg font-medium">{item.name}</div>
                  <div className="text-gray font-medium">
                    {item.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CreatePolicyButtton;
