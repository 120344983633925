import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AllLibrary from "./AllLibrary";
import EmissionLibrary from "./EmissionLibrary";
import EstimationLibrary from "./EstimationLibrary";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";


const Library = () => {
  const navigator = useNavigate();
 
  const { _orgId: currOrg } = useParams();
 

  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Factor Library</div>

        <div className="mt-8">
          <Tabs variant="light">
            <Tab key="Factor Libraries" title="All Libraries">
              <AllLibrary />
            </Tab>
            <Tab key="Emission" title="Emission Libraries">
              <p className="mt-4">
                An emission factor is a coefficient that enables conversion of
                activity data into emissions. It is the average emission rate of
                a given source, relative to the units of an activity or process.
                When you create an emission factor, you can select the unit from
                which you want to convert the data into the unit that you want
                to use to measure your emissions.
              </p>
              <div className="mt-8">
                <EmissionLibrary />
              </div>
            </Tab>

            <Tab key="Estimation" title="Estimations Libraries">
              <EstimationLibrary />
            </Tab>
          </Tabs>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default Library;
