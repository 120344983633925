import { useEffect, useState } from "react";
import { getOnedata } from "../controllers/APIController";

// Get the all organizations list of current User Logged into Application
export default function useGetAllOrganization() {
  const [OrgList, setOrgList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState(null);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;

  useEffect(() => {
    getOnedata("users", UserId)
      .then((data) => {
        console.log(data,UserId);
        const list = [];
        data?.OrganizationLists?.forEach((element) => {
          list.push(element?.URL);
        });
        console.log(list);
        setOrgList(list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErr(err);
      });
  }, [UserId]);

  

  return { OrgList, isLoading, err };
}
