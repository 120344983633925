import {
  Button,
  Card,
  CardBody,
  Input,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import { Controlled as CodeMirror } from "react-codemirror2";
import React, { useEffect, useState } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import { isJsonString } from "../../utils/helper";
import { JSonResponseInfoContent } from "../../utils/constant";
import PreviewForm from "../FormBuilder/PreviewForm";
import {
  UpdateOneData,
  UpdateSingleCollectionData,
  getDataWithFilterUrl,
  getSingleCollectionData,
} from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import MetricTagInput from "../FormBuilder/InputField/MetricTagInput";

const initialFormData = {
  metricName: "",
  className: "",
  description: "",
};

const MetricBuilderValidation = (formData, jsonData, linkData, requiredArr) => {
  const err = {};
  let isValid = true;

  if (!isJsonString(jsonData)) {
    err["JsonSchema"] = "Invalid JsonSchema";
    isValid = false;
  }
  if (!isJsonString(linkData)) {
    err["linkData"] = "Invalid Link Data";
    isValid = false;
  }

  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const JSonResponseInfoContentComp = () => {
  return (
    <pre className="text-xs font-semibold ">
      {JSON.stringify(JSonResponseInfoContent, null, 2)}
    </pre>
  );
};

const EditJsonSurveyBuilder = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");
  const [linkData, setLinkData] = useState("");

  const requiredArr = ["metricName", "jsonSchema"];
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagsValidation, setTagsValidation] = useState("");

  const navigate = useNavigate();
  const { _id, _orgId } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };
  const handleLinkChange = (editor, data, value) => {
    setLinkData(value);
  };

  const showPreviewHandler = () => {
    setShowPreview(false);

    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr
    );


    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const publishData = {
      Name: formData.metricName,
      ClassName: formData.className,
      Description: formData.description,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      TagList: tags,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };

  const submitHandler = async () => {
    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr
    );


    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }

    const publishData = {
      Name: formData.metricName,
      ClassName: formData.className,
      Description: formData.description,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      TagList: tags,
    };


    let result = await UpdateOneData("metrics", _id, publishData);

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigate(`/${_orgId}/survey-builder`);
    // window.location.reload();
  };

  function jsonHint(editor) {
    const jsonWords = ["{}", "name", "version", "author"]; // Add your JSON keywords here
    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);

    // Filter JSON words that match the current token
    const matches = jsonWords.filter((word) => word.startsWith(token.string));

    // Return hint object with filtered matches
    return {
      list: matches,
      from: { line: cursor.line, ch: token.start },
      to: { line: cursor.line, ch: token.end },
    };
  }

  // Handler for inputRead event to trigger autocomplete
  const handleInputRead = (editor, data, value) => {
    const cursor = editor.getCursor();
    const line = editor.getLine(cursor.line);
    const lastChar = line.charAt(cursor.ch - 1);

    // Check if the last character typed is a letter or a digit
    if (/[a-zA-Z0-9]/.test(lastChar)) {
      editor.execCommand("autocomplete");
    }
  };

  useEffect(() => {
    const filteredUrl = `filters[id][$eqi]=${_id}`;
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data[0];


        setFormData({
          metricName: response?.attributes?.Name || "",
          categoryName: response?.attributes?.Category || "",
          className: response?.attributes?.ClassName || "",
          description: response?.attributes?.Description || "",
          frameWork: response?.attributes?.Framework?.id.join(",") || "",
        });

        setJsonData(JSON.stringify(response?.attributes?.JsonSchema, null, 2));
        setLinkData(JSON.stringify(response?.attributes?.Links, null, 2));

        setTags(response?.attributes?.TagList || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          value={formData["metricName"] || ""}
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          value={formData["className"] || ""}
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          value={formData["description"] || ""}
          onChange={changeHandler}
        />

        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />
        
        {/* JsonSchema Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Json Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["JsonSchema"] && validationErrors["JsonSchema"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={jsonData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleJsonChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Link JSon Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Link Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["linkData"] && validationErrors["linkData"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={linkData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleLinkChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>
        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button color="danger" size="sm" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default EditJsonSurveyBuilder;

/* 

---Check the Validation
---creating the payload and push to strapi


*/
