import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import {
  Button,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
} from "@nextui-org/react";
import SearchIcon from "../../Images/Search";
import {
  getDataWithFilterUrl,
  PublishData,
} from "../../controllers/APIController";
import LoadingPage from "../../components/Loader/LoadingPage";
import { useNavigate, useParams } from "react-router-dom";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";

const CreateFramework = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();
  const { _orgId } = useParams();

  const [frameWorkName, setFrameWorktName] = useState("");
  const [frameWorkDescription, setFrameWorkDescription] = useState("");
  const [controlMetrics, setControlMetrics] = useState(new Set([]));
  const [sectionText, setSectionText] = useState("");
  const [subSectionText, setSubSectionText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [page, setPage] = React.useState(1);
  const [metricData, setMetricData] = useState([]);
  const [countResponse, setCountResponse] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filterDataList, setFilterDataList] = useState([]);

  const { _orgId: currOrg } = useParams();
  const { OrgList, isLoading: isLoadingOrgList, err } = useGetAllOrganization();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const navigateHandler = (event) => {
    event.stopPropagation();
  };
  const searchHandler = (event) => {
    const text = event.target.value;
    const list = metricData.filter((item) => {
      return item?.attributes?.Name?.toLowerCase()?.includes(
        text?.toLowerCase()
      );
    });
    setSearchText(text);
    setFilterDataList(list);
  };

  const submitHandler = async () => {
    if (frameWorkName.trim() === "") {
      setErrorMessage("Framework Name is required");
      return;
    }

    let List =
      controlMetrics === "all"
        ? filterDataList.map((item) => toString(item?.id))
        : Array.from(controlMetrics);
    const publishData = {
      Name: frameWorkName,
      Description: frameWorkDescription,
      Section: sectionText,
      SubSection: subSectionText,
      ControlMetrics: { id: List },
      organizations: OrgInfo,
    };

    let result = await PublishData({ data: publishData }, "frameworks");
    navigator(`/${_orgId}/browse-framework`);
    // window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", orgFilterUrl)
      .then(async (data) => {
        let list = {};
        const dataList = data?.data || [];
        setMetricData(dataList);
        setFilterDataList(dataList);
        setIsLoading(false);

        const result = dataList.map(async (item) => {
          const url =
            orgFilterUrl +
            "&filters[Metric_Id][$eqi]=" +
            item?.attributes?.Name;
          const response = await getDataWithFilterUrl("responses", url);

          list[item?.attributes?.Name] = response?.data?.length || 0;
          return response;
        });
        await Promise.all(result);

        setCountResponse(list);
        // setMetricData(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const rowsPerPage = 15;
  const pages = Math.ceil(filterDataList?.length / rowsPerPage);

  useEffect(() => {
    if (!isLoadingOrgList && !OrgList?.includes(currOrg)) {
      navigator("*");
    }
  }, [OrgList]);

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <div>
          <p className="text-3xl font-medium">Create a new Framework</p>
          <div className="mt-8">
            Create your own framework and use it to generate your own reports.
            You can choose your metrics from our playground and use the ones are
            more relevant to you.
          </div>
          <div className="my-8 flex items-center justify-end">
            <Button color="danger" onClick={submitHandler}>
              Create Framework
            </Button>
          </div>

          <div className="flex">
            <div className="w-full">
              <div className="w-1/2">
                <Input
                  radius="sm"
                  className="border-slate-6 mb-8"
                  isRequired
                  key="Framework Name"
                  type="text"
                  label="Framework Name"
                  variant="faded"
                  value={frameWorkName}
                  labelPlacement="outside"
                  placeholder="Enter a name for your new reporting framework"
                  onChange={(e) => setFrameWorktName(e.target.value)}
                  isInvalid={errorMessage !== ""}
                  errorMessage={errorMessage}
                />

                <Textarea
                  radius="sm"
                  className="border-slate-6 mb-12"
                  key="Description"
                  type="text"
                  label="Description"
                  value={frameWorkDescription}
                  variant="faded"
                  labelPlacement="outside"
                  placeholder="Give a short description to your framework"
                  onChange={(e) => setFrameWorkDescription(e.target.value)}
                />

                <Input
                  className="border-slate-6 mb-12"
                  type="text"
                  isRequired
                  label="Section"
                  variant="faded"
                  value={sectionText}
                  labelPlacement="outside"
                  placeholder="Enter the Sections for your reporting framework"
                  onChange={(e) => setSectionText(e.target.value)}
                />

                <Input
                  className="border-slate-6 my-8"
                  isRequired
                  type="text"
                  label="Sub-Section"
                  variant="faded"
                  value={subSectionText}
                  labelPlacement="outside"
                  placeholder="Enter the sub-sections"
                  onChange={(e) => setSubSectionText(e.target.value)}
                />
              </div>

              <div className="mt-8">
                <div>Add control metrics</div>
                <div className="w-full">
                  <Input
                    classNames=""
                    placeholder="Search control metrics"
                    className="mt-4 w-full"
                    startContent={
                      <SearchIcon
                        className={`text-large text- pointer-events-none flex-shrink-0 `}
                      />
                    }
                    value={searchText}
                    onChange={searchHandler}
                  />

                  <div className="mt-8">
                    <Table
                      aria-label="Controlled table metrics"
                      removeWrapper
                      selectionMode="multiple"
                      isHeaderSticky
                      bottomContent={
                        <div className="flex items-center justify-center mt-4">
                          <Pagination
                            isCompact
                            showControls
                            showShadow
                            color="secondary"
                            page={page}
                            total={pages}
                            onChange={(page) => setPage(page)}
                          />
                        </div>
                      }
                      selectedKeys={controlMetrics}
                      onSelectionChange={setControlMetrics}
                    >
                      <TableHeader>
                        <TableColumn>Metric Name</TableColumn>
                        <TableColumn>Category</TableColumn>
                        <TableColumn>Total Responses Recorded</TableColumn>
                        <TableColumn></TableColumn>
                      </TableHeader>
                      <TableBody>
                        {filterDataList?.map((item, index) => {
                          const lowerPageLimit = (page - 1) * rowsPerPage;
                          const upperPageLimit = page * rowsPerPage;
                          if (index < lowerPageLimit || index >= upperPageLimit)
                            return null;
                          return (
                            <TableRow key={item.id}>
                              <TableCell>{item?.attributes?.Name}</TableCell>
                              <TableCell>
                                {item?.attributes?.Category}
                              </TableCell>
                              <TableCell>
                                {countResponse?.[item?.attributes?.Name] ||
                                  "--"}
                              </TableCell>
                              <TableCell>
                                <div
                                  className="text-blue-600 font-semibold cursor-pointer"
                                  onClick={navigateHandler}
                                >
                                  View
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default CreateFramework;
