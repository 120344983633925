import { getDataWithFilterUrl } from "../controllers/APIController";

//Should Have "Quantity" and "GreenHouse gas" property in formaData to calculate the Fugitive Value
export const getFugitive = async (quantity, gas_name, data) => {
  let result = null;
  console.log(quantity, gas_name, data);
  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
      gas_name.toLowerCase()
    ) {
      result = element;
    }
  });
  console.log(result);
  if (result === null) return 0;

  const gwp =
    +result?.attributes?.JsonSchema?.["GWP (Global Warming Potential) factor"];

  console.log(gwp, quantity);
  const value = quantity * gwp;

  return value;
};

// Should have field [ Quantity, Greenhouse gas ]
export const FugitiveCalculator = async function (formdata) {
  const quantity = formdata["Quantity"];
  const gas_name = formdata["Greenhouse gas"];

  try {
    const filteredUrl = "filters[Metric_Id][$eqi]=Greenhouse Gas";
    console.log(filteredUrl);
    const response = await getDataWithFilterUrl("responses", filteredUrl);

    const data = response?.data || [];
    console.log(data, gas_name, quantity);
    const val = await getFugitive(quantity, gas_name, data);

    return val;
  } catch (error) {
    console.log("error is here in Fugitve Calculator", error);
  }
};

/*
formData  --> quantity, Fuel Type,
Emission Factor[Fuel Type] ->  We take CO2,NO,O2 values
*/

// Should have field [ Fuel(Quantity), Fuel Type ]

const GasList = ["CO2", "CH4", "NF3", "SF6", "PFCS", "HFCS", "N2O", "CO2E"];
export const StationaryCombustionCalculator = async function (formdata) {
  console.log(formdata);
  const quantity = formdata["Quantity"];
  const fuel_type = formdata["Fuel Type"];

  const filteredUrl = "filters[Metric_Id][$eqi]=Emisson Factor";
  const response = await getDataWithFilterUrl("responses", filteredUrl);
  const data = response?.data;

  let result = {};
  console.log(data, formdata);

  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
      fuel_type.toLowerCase()
    ) {
      result = element;
    }
  });

  let totalEF = 0;
  console.log(result);

  const GasfilteredUrl = "filters[Metric_Id][$eqi]=Greenhouse Gas";
  const allGasListResponse = await getDataWithFilterUrl(
    "responses",
    GasfilteredUrl
  );

  const EFResponse = GasList?.map(async (name) => {
    const val = await getFugitive(
      +result?.attributes?.JsonSchema?.[name] || 1,
      name,
      allGasListResponse?.data || []
    );
    console.log(data, val);
    totalEF += val;
    return val;
  });

  await Promise.all(EFResponse);

  const value = quantity * totalEF;
  console.log(quantity, totalEF);
  return value;
};

// Should have field [ Vehicle Type,Distance, Fuel(Quantity), Fuel Type ]
export const MobileCombustionCalculator = async function (formdata) {
  const vehicle = formdata["Vehicle Type"];

  const filteredUrl = "filters[Metric_Id][$eqi]=Vehicle Type";
  const response = await getDataWithFilterUrl("responses", filteredUrl);
  const data = response?.data;

  let result = {};
  console.log(data, formdata);

  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
      vehicle.toLowerCase()
    ) {
      result = element;
    }
  });

  console.log(result);

  const isOnRoad = result?.attributes?.JsonSchema?.["Is on-road"];
  console.log(isOnRoad);

  if (isOnRoad?.toLowerCase() === "yes") {
    const dataobj = {
      Quantity: formdata["Distance"],
      "Fuel Type": formdata["Fuel Type"],
    };
    const value = await StationaryCombustionCalculator(dataobj);

    return value;
  } else if (isOnRoad.toLowerCase() === "no") {
    const dataobj = {
      Quantity: formdata["Quantity"],
      "Fuel Type": formdata["Fuel Type"],
    };
    const value = await StationaryCombustionCalculator(dataobj);
    return value;
  } else return 0;
};

export const getFormulaData = async (formules, formData) => {
  const response = {};
  console.log(formules, formData);

  const processFormules = async () => {
    try {
      const results = await Promise.all(
        formules.map(async (item) => {
          let func = new Function("return (" + item?.functionDef + ")");
          console.log(func);
          let value1;
          try {
            value1 = await functionCaller(func, formData);
            response[item.title] = value1;
            console.log(response);
          } catch (error) {
            console.error(
              `Error calling function for item ${item.title}:`,
              error
            );
            value1 = "null";
            response[item.title] = "null";
          }
          return {
            title: item.title,
            value: value1 === undefined ? "null" : value1,
          };
        })
      );
    } catch (error) {
      console.error("Error processing formules:", error);
    }
  };
  const result = await processFormules();

  console.log(response);
  return response;
};

const obj = {
  FugitiveCalculator: FugitiveCalculator,
  getFugitive: getFugitive,
  StationaryCombustionCalculator: StationaryCombustionCalculator,
  MobileCombustionCalculator: MobileCombustionCalculator,
};

function getParameterNames(func) {
  const fnStr = func.toString().replace(/\n/g, "");
  const result = /\(\s*([^)]*)\s*\)/.exec(fnStr);
  return result ? result[1].split(",").map((param) => param.trim()) : [];
}

export const functionCaller = async (func, formdata) => {
  const getParamMeterList = getParameterNames(func());
  console.log(getParamMeterList, func());

  const restParams = [];

  for (let i = 1; i < getParamMeterList.length; i++) {
    restParams.push(obj[getParamMeterList[i]]);
  }
  console.log(restParams);
  const result = await func()(formdata, ...restParams);

  console.log(result);
  return result;
};
