import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, Lock, Activity, Flash, Server, TagUser, Scale } from "./Icons.jsx";

const icons = {
  chevron: <ChevronDown fill="currentColor" size={16} />,
  scale: <Scale className="text-warning" fill="currentColor" size={30} />,
  lock: <Lock className="text-success" fill="currentColor" size={30} />,
  activity: <Activity className="text-secondary" fill="currentColor" size={30} />,
  flash: <Flash className="text-primary" fill="currentColor" size={30} />,
  server: <Server className="text-success" fill="currentColor" size={30} />,
  user: <TagUser className="text-danger" fill="currentColor" size={30} />,
};
const list = [
  {
    id: 1,
    name: "Create a new organization",
    description: "",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    ),
  },
];

const CreateOrgButtton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <Button isIconOnly variant="bordered" color="danger">+</Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="Link Actions" className='w-[340px]'>
          <DropdownItem key="Create" href='/org-list/create' variant='flat' description="Create an organization to begin your ESG reporting"
            startContent={icons.flash}>
            Create a new organization
          </DropdownItem>
          <DropdownItem key="Join" href='/join-space' variant='flat' description="Join an existing organization to interact with their ESG metrics"
            startContent={icons.lock}>
            Join an existing organization
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

    </>

  );
};

export default CreateOrgButtton;
