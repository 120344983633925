import React from "react";
import MetricCard from "./MetricCard";

const MetricSkeletonContainer = () => {
  return (
    <div className="flex flex-col gap-8 mt-8">
      <MetricCard />
      <MetricCard />
      <MetricCard />
      <MetricCard />
    </div>
  );
};

export default MetricSkeletonContainer;
