// DropDown Data Schema for AssignedToUser  in  Playground
export const AssignedToUserSchemaObj = {
  id: "assignedToUser",
  title: "Assigned To User",
  placeholder: "Select Assigned To User",
  values: [],
};

// DropDown Data Schema for AssignedToUser  in  Playground
export const ApproverUserSchemaObj = {
  id: "approverUser",
  title: "Approver Users",
  placeholder: "Select Approver User",
  values: [],
};

// DropDown Data Schema for AssignedToUser  in  Playground
export const ReviewerUserSchemaObj = {
  id: "reviewerUser",
  title: "Reviewer User",
  placeholder: "Select Reviewer User",
  values: [],
};

export const sectorSchema = {
  id: 4,
  name: "Drop Down",
  type: "dropdown",
  title: "Choose Industry Sector",
  placeholder: "Enter Industry Sector",
  values: [],
  required: true,
  ismultiselect: false,
};
