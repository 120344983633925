import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  TableCell,
  Tooltip,
} from "@nextui-org/react";
import CryptoJS from "crypto-js";

import {
  getDataWithFilterUrl,
  getAlldata,
  StringLimit,
  getFilterDataWithPagination,
  UpdateOneData,
  PublishData,
  InviteUser,
} from "../controllers/APIController";
import SearchIcon from "../Images/Search";

const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;

export const RequiredFieldSign = () => {
  return <p style={{ color: "red" }}>*</p>;
};

export const CreateNewEntryButton = ({ name, metricName, onClickHandler }) => {
  const clickHandler = () => {
    console.log(metricName, name);
    onClickHandler(metricName, name);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
      }}
      onClick={clickHandler}
    >
      <span>Create a New {name} </span>
      <span>+</span>
    </div>
  );
};

export const getUniqueClassName = (arr) => {
  const uniqueValues = [];
  const map = new Map();
  console.log(arr);
  if (arr === null || arr === undefined || arr.length === 0)
    return uniqueValues;
  for (const item of arr) {
    if (!map.has(item?.attributes?.ClassName)) {
      map.set(item?.attributes?.ClassName, true); // set any value to Map
      uniqueValues.push(item?.attributes?.ClassName);
    }
  }
  return uniqueValues;
};

//  Check that given input String is valid Json Object or Not
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// Set intial state for form data based on given array
export const getInitailState = (arr) => {
  const data = {};
  console.log("Arrr->>>>>>>", arr);

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  console.log(data);
  return data;
};

export function syntaxHighlight(json) {
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

//  Function to copy in clipboard
export const handleCopyClick = (value) => {
  // Copy the value to the clipboard
  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log("Text copied to clipboard:", value);
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
};

// "YYYY-MM-DD"
export const DateFormator = (value) => {
  const date = new Date(value);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

//"DD-MM_YYYY"
export const DateFormatorDD = (value) => {
  const date = new Date(value);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${day}-${month}-${year}`; // 04-12-2023
  return formattedDate;
};
export const ChangeDateDDToYYYY = (value) => {
  console.log(value);
  if (value === undefined || value === "null") return null;
  const [day, month, year] = value.split("-");
  const newDateString = `${year}-${month}-${day}`;
  console.log(newDateString);
  return newDateString;
};

export async function FetchAnsweredCount(responseData) {
  let answeredCount = 0;
  let lastUpdatedAt = null;
  const promises = responseData.map(async (element) => {
    const id = element?.attributes?.Name;
    const responseUrl =
      "filters[Metric_Id][$eqi]=" + id + "&sort[0]=updatedAt:desc";
    return getFilterDataWithPagination("responses", responseUrl, 1, 1)
      .then((data) => {
        if (data.data.length > 0) {
          answeredCount = answeredCount + 1;
          lastUpdatedAt = data.data[0].attributes?.updatedAt;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  try {
    await Promise.all(promises);
    // console.log("All data fetched", answeredCount);
    return { answeredCount, lastUpdatedAt };
  } catch (err) {
    console.log("An error occurred", err);
  }

  // return answeredCount;
}
export async function fetchMetricInfo(metricNameList, OrgInfo, frameworkUrl) {
  const result = {};
  const promisesResponse = metricNameList.map(async (name) => {
    const filteredUrl = `filters[Organization][id][$eqi]=${+OrgInfo}&filters[Category][$eqi]=${name}${frameworkUrl}`;

    return getDataWithFilterUrl("metrics", filteredUrl)
      .then(async (data) => {
        const responseData = data.data;
        let totalCount = responseData.length;
        let InCompleteCount = 0,
          CompletedCount = 0;
        let lastUpdatedAt = "";

        responseData?.map(async (item) => {
          const lastUpdatedReponse = item?.attributes?.lastUpdatedReponse || "";
          const currCount = item?.attributes?.currentCounter;
          let requiredCount = item?.attributes?.requiredCounter;

          currCount >= requiredCount ? CompletedCount++ : InCompleteCount++;
          console.log(item, lastUpdatedReponse, lastUpdatedAt);

          if (lastUpdatedReponse !== "" && lastUpdatedAt === "") {
            lastUpdatedAt = new Date(lastUpdatedReponse);
          }
          if (
            lastUpdatedReponse !== "" &&
            new Date(lastUpdatedReponse) > new Date(lastUpdatedAt)
          ) {
            lastUpdatedAt = lastUpdatedReponse;
          }
        });
        result[name] = {
          totalCount: totalCount,
          InCompleteCount: InCompleteCount,
          CompletedCount: CompletedCount,
          lastUpdatedAt: lastUpdatedAt,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  });

  try {
    await Promise.all(promisesResponse);
    return result;
  } catch (err) {
    console.log("An error occurred", err);
  }
}

// formatDate in like 22 April,2020
export const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  const options = { day: "numeric", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export const generateAPIKey = () => {
  const length = 32;
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
};

export const getFormulaData = async (formules, formData) => {
  const response = {};
  console.log(formules, formData);

  const processFormules = async () => {
    try {
      const results = await Promise.all(
        formules.map(async (item) => {
          let func = new Function("return (" + item?.fun + ")");
          let value1;
          try {
            value1 = await functionCaller(func, formData);
            response[item.title] = value1;
            console.log(response);
          } catch (error) {
            console.error(
              `Error calling function for item ${item.title}:`,
              error
            );
            value1 = "null";
            response[item.title] = "null";
          }
          return {
            title: item.title,
            value: value1 === undefined ? "null" : value1,
          };
        })
      );
    } catch (error) {
      console.error("Error processing formules:", error);
    }
  };
  const result = await processFormules();

  console.log(response);
  return response;
};

export const functionCaller = async (func, formdata) => {
  const result = await func()(formdata, getAlldata);

  console.log(result);
  return result;
};

// Delete Table Item Dropdown Menu for Admins Only
export const DeleteTableItem = ({
  userRole,
  deleteHandler,
  item,
  toolTiptext = "Delete Metric",
  DropDownText,
}) => {
  return (
    <div
      className={`flex gap-4 items-center cursor-pointer ${
        userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <Dropdown>
        <DropdownTrigger>
          <div className="cursor-pointer">
            <Tooltip size="sm" content={toolTiptext}>
              <lord-icon
                src="https://cdn.lordicon.com/wpyrrmcq.json"
                trigger="hover"
                colors={`primary #000000`}
                style={{
                  width: "20px",
                  height: "20px",
                }}
              ></lord-icon>
            </Tooltip>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Static Actions"
          selectionMode="single"
          onSelectionChange={(event) => {
            deleteHandler(item?.id);
          }}
        >
          <DropdownItem key="delete" color="danger" variant="flat">
            {DropDownText || `Delete ${item?.attributes?.Name}`}?
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
// Delete Table Item Dropdown Menu for Admins Only
export const EditTableItem = ({
  userRole,
  editHandler,
  item,
  toolTiptext = "Edit Metric",
  DropDownText,
}) => {
  return (
    <div
      className={`flex gap-4 items-center cursor-pointer ${
        userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <div className="cursor-pointer" onClick={editHandler}>
        <Tooltip size="sm" content={toolTiptext}>
          <lord-icon
            src="https://cdn.lordicon.com/wuvorxbv.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#121331,secondary:#000000"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};
// Search Input Component

export const SearchInput = ({ searchText, setSearchText, placeholderText }) => {
  return (
    <Input
      type="text"
      startContent={
        <SearchIcon
          className={`text-large text- pointer-events-none flex-shrink-0 `}
        />
      }
      placeholder={placeholderText}
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
    />
  );
};

// File Table Cell Component
export const FileTableCell = (srcLink) => {
  console.log(srcLink);
  return (
    <TableCell>
      <lord-icon
        src="https://cdn.lordicon.com/rbbnmpcf.json"
        trigger="hover"
        colors={`primary:#000000`}
        style={{
          width: "20px",
          height: "20px",
        }}
        className="cursor-grab"
        // onClick={(event) => {
        //   event.stopPropagation();
        //   window.open(srcLink);
        // }}
      ></lord-icon>
    </TableCell>
  );
};

export const EmptyTableCell = () => {
  return <TableCell>--</TableCell>;
};

export const GenericTableCell = (value) => {
  return (
    <TableCell>
      {typeof value === "string" ? StringLimit(value, 80) : value}
    </TableCell>
  );
};

export const DeleteTableItemWithOpenModal = (
  id,
  isActive,
  setDeleteMetricId,
  onOpen
) => {
  return (
    <TableCell>
      <div className="flex gap-4 text-center">
        <div
          className={`cursor-pointer ${
            isActive === false ? "pointer-events-none opacity-50" : ""
          }`}
          onClick={(event) => {
            event.stopPropagation();
            setDeleteMetricId(id);
            onOpen();
          }}
        >
          <Tooltip size="sm" content="Delete Item">
            <lord-icon
              src="https://cdn.lordicon.com/wpyrrmcq.json"
              trigger="hover"
              colors={`primary #000000`}
              style={{ width: "20px", height: "20px" }}
            ></lord-icon>
          </Tooltip>
        </div>
      </div>
    </TableCell>
  );
};

export const UserCell = (email, id, url) => {
  const avatarUrl = url ? url : "https://images.unsplash.com/broken";
  return (
    <TableCell>
      <Tooltip content={email || "No User"}>
        <Avatar
          radius="sm"
          // size="sm"
          showFallback
          className="h-7 w-7 cursor-pointer"
          color={Colors[id % numColors]}
          name={email?.toUpperCase().slice(0, 1)}
          src={avatarUrl}
        />
      </Tooltip>
    </TableCell>
  );
};

// Get the User role in current Organization from API
export const getUserRoleFromOrg = async (orgId, userId) => {
  console.log(orgId, userId);
  const orgResponse = await getDataWithFilterUrl(
    "organizations",
    `filters[id][$eqi]=${orgId}`
  );
  const OrgInfo = orgResponse?.data[0];

  if (!OrgInfo) return "Not a User";
  let userRole = "Authenticated";

  const admiList = OrgInfo?.attributes?.admin_list?.data;
  const reviewerList = OrgInfo?.attributes?.reviewer_list?.data;
  const approverList = OrgInfo?.attributes?.approver_list?.data;

  admiList?.forEach((element) => {
    if (element.id === userId) {
      userRole = "Admin";
      return userRole;
    }
  });

  reviewerList?.forEach((element) => {
    if (element.id === userId) {
      userRole = "Reviewer";
      return userRole;
    }
  });
  approverList?.forEach((element) => {
    if (element.id === userId) {
      userRole = "Approver";
      return userRole;
    }
  });
  return userRole;
};

// Get the User role in current Organization from Local Storage
export const getUserRoleFromLocal = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  if (!userData) return "null";
  return userData?.user?.userRole;
};

// Add the given email list to Invitation Collection and send email notification as well for the given Organization
export const addUsersToInvitation = async (list, orgId, UserId) => {
  console.log(list, orgId);

  const result = list.map(async (email) => {
    const uploadData = {
      email: email,
      Status: "Pending",
      organization: [orgId],
      sendBy: [UserId],
    };

    const response = await PublishData({ data: uploadData }, "invitations");
    return response;
  });

  await Promise.all(result);

  const invitations = (list || []).map(async (email) => {
    const payload = {
      to: email,
      subject: "Invitation from Aduyt Base.",
      html: "<html><body><p>Hello </p></body></html>",
    };
    const result = await InviteUser(payload);
    return result;
  });

  await Promise.all(invitations);

  return result;
};

export const addUsersToOrganization = async (list, orgId) => {
  console.log(list, orgId);

  const userIdList = [];

  const result = list.map(async (email) => {
    const userResponse = await getDataWithFilterUrl(
      "users",
      `filters[email][$eqi]=${email}`
    );
    if (userResponse[0]?.id) userIdList.push(userResponse[0]?.id);

    return userResponse;
  });

  await Promise.all(result);

  const uploadData = {
    user_lists: {
      connect: [...userIdList],
    },
  };
  const updatedOrgResponse = await UpdateOneData(
    "organizations",
    orgId,
    uploadData
  );

  return updatedOrgResponse;
};

// get user list of current organization members
export const getAllUserOfOrgnization = async () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const response = await getDataWithFilterUrl(
    "organizations",
    `filters[id][$eqi]=${OrgInfo}`
  );
  let userList = response?.data[0]?.attributes?.user_lists?.data?.map(
    (item) => {
      return {
        id: item.id,
        email: item.attributes?.email,
        username: item.attributes?.username,
      };
    }
  );
  console.log(userList);

  return userList;
};

// get All Reviewer list of current organization members
export const getAllReviewerOfOrgnization = async () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const response = await getDataWithFilterUrl(
    "organizations",
    `filters[id][$eqi]=${OrgInfo}`
  );
  let userList = response?.data[0]?.attributes?.reviewer_list?.data?.map(
    (item) => {
      return {
        id: item.id,
        email: item.attributes?.email,
        username: item.attributes?.username,
      };
    }
  );
  console.log(userList);

  return userList;
};

// get All Approver list of current organization members
export const getAllApproverOfOrgnization = async () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const response = await getDataWithFilterUrl(
    "organizations",
    `filters[id][$eqi]=${OrgInfo}`
  );
  let userList = response?.data[0]?.attributes?.approver_list?.data?.map(
    (item) => {
      return {
        id: item.id,
        email: item.attributes?.email,
        username: item.attributes?.username,
      };
    }
  );
  console.log(userList);

  return userList;
};

// get all metrics in which the given user in assigned as Reviewer/Approver based on input

export const getMetricsAssignedToUser = async (
  userId,
  FieldName = "ReviewerToMetric"
) => {
  const userData = await getDataWithFilterUrl(
    "users",
    `filters[id][$eqi]=${userId}`
  );
  return userData[0][FieldName];
};

// // Add Evidence item
// export const addEvidence = async (EvidenceUrl, ReferenceUrl, OrgId) => {

//   const uploadData = {
//     EvidenceUrl,
//     ReferenceUrl,
//     organization: OrgId,
//   };

//   const response = await PublishData({ data: uploadData }, "evidences");

//   return response;
// };

const duumyMetrics = [
  {
    id: 212,
    attributes: {
      Name: " Products and Services sold by the Company",
      Category: "Framework",
      JsonSchema: {
        type: "object",
        title: "Sustainability Item Category",
        required: [],
        properties: {
          Remarks: {
            type: "string",
            title: "3. Remarks",
          },
          "NIC Code": {
            type: "integer",
            title:
              "1. Number of locations where plants and/or operations/offices of the Company are situated locally",
          },
          "Main activity": {
            type: "string",
            title:
              "2. Number of locations where plants and/or operations/offices of the Company are situated internationally",
          },
        },
        description: "",
      },
      ClassName: "General Disclosures",
      Description: "",
      Links: {},
      Framework: {
        id: [],
      },
      Frameworks: "BRSR",
      createdAt: "2024-05-12T12:04:08.741Z",
      updatedAt: "2024-06-26T11:55:56.287Z",
      publishedAt: "2024-05-13T06:11:53.886Z",
      WorkFlow: null,
      Frequency: null,
      TagList: null,
      Organization: {
        data: null,
      },
      AssignedTo: {
        data: [],
      },
      ReviewerTo: {
        data: [],
      },
      ApproverTo: {
        data: [],
      },
    },
  },
];
const duumyResponses = [
  {
    id: 212,
    attributes: {
      Metric_Id: "Fuel type",
      JsonSchema: {
        Name: "Gas Works Gas",
        Description: "",
      },
      createdAt: "2024-06-09T06:41:20.024Z",
      updatedAt: "2024-06-09T06:41:20.024Z",
      publishedAt: "2024-06-09T06:41:20.022Z",
      Status: null,
      Comments: null,
      users_permissions_user: {
        data: {
          id: 51,
          attributes: {
            username: "sreevatsankomundur@gmail.com",
            email: "sreevatsankomundur@gmail.com",
            provider: "local",
            confirmed: true,
            blocked: false,
            FirstName: "Sreevatsan",
            LastName: "Sridhar",
            Password2:
              "VTJGc2RHVmtYMTl0YmpKclVJZWdYaHZ6c2c1MVp3K0VxVldlRGtYTkwvZz0=",
            createdAt: "2024-05-12T12:38:47.137Z",
            updatedAt: "2024-06-26T04:58:18.840Z",
            LastOrg: null,
            LastOrgId: null,
            userRole: null,
          },
        },
      },
      Organization: {
        data: null,
      },
    },
  },
];
export const AddedDefalutMetricsToNewOrg = async (orgId, defaultOrgId) => {
  const filterUrl = "filters[Organization][id][$eqi]=" + defaultOrgId;
  const defalutMetricsReponse = await getDataWithFilterUrl(
    "metrics",
    filterUrl
  );
  const defalutMetrics = defalutMetricsReponse?.data;

  // console.log(defalutMetrics);

  const resultResponses = defalutMetrics?.map(async (item) => {
    const uploadData = {
      ...item?.attributes,
      Organization: {
        connect: [orgId],
      },
    };
    const result = await PublishData({ data: uploadData }, "metrics");
    return result;
  });
  await Promise.all(resultResponses);
};

export const AddedDefalutReponsesToNewOrg = async (
  orgId,
  userId,
  defaultOrgId
) => {
  const filterUrl = "filters[Organization][id][$eqi]=" + defaultOrgId;
  const defalutResResponse = await getDataWithFilterUrl("responses", filterUrl);

  const defalutResResponseList = defalutResResponse?.data;

  const resultResponses = defalutResResponseList?.map(async (item) => {
    const uploadData = item?.attributes;
    uploadData.Organization = {
      connect: [orgId],
    };
    uploadData.users_permissions_user = {
      connect: [userId],
    };
    const result = await PublishData({ data: uploadData }, "responses");
    return result;
  });
  await Promise.all(resultResponses);
};

/*
First we fetch the framework which has a orgName is deafult-
then for each framework we see the corresponding metrics id for new org 
then create a new framwork for new org.
*/

export const AddedDefalutFrameWorkToNewOrg = async (
  newOrgId,
  frameWorkIdList
) => {
  const frameWorkList = [];
  const frameWorkResponse = frameWorkIdList?.map(async (id) => {
    const result = await getDataWithFilterUrl(
      "frameworks",
      `filters[id][$eqi]=${+id}`
    );
    frameWorkList.push(result?.data[0]);
  });
  await Promise.all(frameWorkResponse);

  console.log(frameWorkList);

  const newFrameworkList = [];

  const result = frameWorkList?.map(async (item) => {
    const metricIdList = item?.attributes?.ControlMetrics?.id;
    const newMetricIdList = [];

    const metricIdResponses = metricIdList?.map(async (id) => {
      const metricInDefault = await getDataWithFilterUrl(
        "metrics",
        `filters[id][$eqi]=${+id}`
      );
      const metricName =
        metricInDefault?.data[0]?.attributes?.Name?.toLowerCase();
      const metricInNewOrg = await getDataWithFilterUrl(
        "metrics",
        `filters[Organization][id][$eqi]=${newOrgId}&filters[Name][$eqi]=${metricName}`
      );
      newMetricIdList.push(metricInNewOrg?.data[0]?.id);
      return metricInNewOrg;
    });

    await Promise.all(metricIdResponses);
    const newFrameWorkObj = {
      ...item?.attributes,
      ControlMetrics: {
        id: newMetricIdList,
      },
      Organization: {
        connect: [newOrgId],
      },
    };
    newFrameworkList.push(newFrameWorkObj);
    return metricIdResponses;
  });

  await Promise.all(result);

  const newFrameWorkResult = newFrameworkList?.map(async (item) => {
    const result = await PublishData({ data: item }, "frameworks");
    return result;
  });
  await Promise.all(newFrameWorkResult);

  return newFrameWorkResult;
};

export const CreatedNewFinancialToOrg = async (data, orgId) => {
  const uploadData = {
    Name: data?.["Financial Name"],
    StartDate: data?.["Start Date"],
    EndDate: data?.["End Date"],
    Organization: {
      connect: [orgId],
    },
  };
  const result = await PublishData({ data: uploadData }, "financial-years");
  return result;
};

// Updating the Metric Current Coutner during creating the new response.

export const increasedMetricCurrentCount = async (
  metricId,
  createdAt = new Date()
) => {
  const metricResponse = await getDataWithFilterUrl(
    "metrics",
    `filters[id][$eqi]=${+metricId}`
  );
  const metricDetails = metricResponse?.data[0];
  console.log(metricDetails);

  if (!metricDetails) {
    throw new Error("Invalid Metric ID: " + metricId);
  }
  const currentCount = metricDetails?.attributes?.currentCounter;
  const updatedCount = +currentCount + 1;

  const updatedData = {
    currentCounter: updatedCount,
    lastUpdatedReponse: createdAt,
  };
  console.log(currentCount, updatedCount);

  const result = await UpdateOneData("metrics", metricId, updatedData);
  return result;
};

// Updating the Metric Current Coutner during deleting the new response.

export const decreasedMetricCurrentCount = async (
  metricId,
  createdAt = new Date()
) => {
  const metricResponse = await getDataWithFilterUrl(
    "metrics",
    `filters[id][$eqi]=${+metricId}`
  );
  const metricDetails = metricResponse?.data[0];
  console.log(metricDetails);

  if (!metricDetails) {
    throw new Error("Invalid Metric ID: " + metricId);
  }
  const currentCount = metricDetails?.attributes?.currentCounter;
  const updatedCount = +currentCount - 1;

  const updatedData = {
    currentCounter: updatedCount,
    lastUpdatedReponse: createdAt,
  };
  console.log(currentCount, updatedCount);
  const result = await UpdateOneData("metrics", metricId, updatedData);
  return result;
};

// Updating the Metric Current Coutner during deleting the new response.

export const updatedMetriclastUpdateResponse = async (
  metricId,
  createdAt = new Date()
) => {
  const metricResponse = await getDataWithFilterUrl(
    "metrics",
    `filters[id][$eqi]=${+metricId}`
  );
  const metricDetails = metricResponse?.data[0];
  console.log(metricDetails);

  if (!metricDetails) {
    throw new Error("Invalid Metric ID: " + metricId);
  }

  const updatedData = {
    lastUpdatedReponse: createdAt,
  };

  const result = await UpdateOneData("metrics", metricId, updatedData);
  return result;
};

// Get Status,CurrentCount,RequiredCount of Metric Responses for an organization
export const getMetricStatus = async (metricID) => {
  const url = `filters[id][$eqi]=${metricID}`;

  const result = await getFilterDataWithPagination("metrics", url);
  console.log(result);

  const lastUpdatedAt = result?.data[0]?.attributes?.lastUpdatedReponse;
  const currCount = result?.data[0]?.attributes?.currentCounter;
  let requiredCount = result?.data[0]?.attributes?.currentCounter;

  const status = currCount >= requiredCount ? "Completed" : "Incompleted";

  return {
    Status: status,
    RequiredCount: requiredCount,
    CurrentCount: currCount,
    lastUpdatedAt: lastUpdatedAt,
  };
};

export const hasCommonElement = (arr1, arr2) => {
  const set1 = new Set(arr1);

  for (let element of arr2) {
    if (set1.has(+element)) {
      return true; // Common element found
    }
  }

  return false; // No common elements
};

export const getSchemaForOrg = async (responseData, orgId) => {
  console.log(orgId, responseData);
  if (!orgId || !responseData) return;

  const frameworkUrl = `filters[organizations][id][$eqi]=${+orgId}`;
  let frameworkResponse = await getDataWithFilterUrl(
    "frameworks",
    frameworkUrl
  );

  frameworkResponse = frameworkResponse?.data;

  const frameWorkIdList = frameworkResponse?.map((item) => {
    return item?.id;
  });

  let responseArr = [];

  Object.keys(responseData)?.forEach((key) => {
    const fieldFramework = responseData[key]?.framework || [];
    console.log(fieldFramework, responseData[key]);
    if (hasCommonElement(frameWorkIdList, fieldFramework)) {
      responseArr.push([key, responseData[key]]);
    }
  });

  responseArr?.sort((a, b) => {
    return a[1]?.id - b[1]?.id;
  });

  return responseArr || [];
};
export const getSchemaForFramework = async (
  responseData,
  orgId,
  frameworkName
) => {
  const frameworkUrl = `filters[Name][$eqi]=${frameworkName}`;
  let frameworkResponse = await getDataWithFilterUrl(
    "frameworks",
    frameworkUrl
  );

  frameworkResponse = frameworkResponse?.data;

  const frameWorkIdList = frameworkResponse?.map((item) => {
    return item?.id;
  });
  let responseArr = [];

  Object.keys(responseData)?.forEach((key) => {
    const fieldFramework = responseData[key]?.framework || [];
    console.log(fieldFramework, responseData[key]);

    if (hasCommonElement(frameWorkIdList, fieldFramework)) {
      responseArr.push([key, responseData[key]]);
    }
  });

  responseArr?.sort((a, b) => {
    return a[1]?.id - b[1]?.id;
  });

  return responseArr || [];
};
export const getFrameworkUrl = (list = []) => {
  let url = "";
  list?.forEach((id, index) => {
    url = url + `&filters[$or][${index}][Framework][$containsi]=${id}`;
  });
  return url;
};

export const encryptedText = (text) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = text;
  const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
  const AESHash = encrypted.toString();
  const url = btoa(AESHash);
  return url;
};

export const decryptedText = (encryptedText) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = atob(encryptedText).toString();

  const text = CryptoJS.AES.decrypt(inputString, secretKey).toString(
    CryptoJS.enc.Utf8
  );

  return text;
};

export const getFieldMoreInfoContent = (item, frameworkName = null) => {
  const data = {};
  console.log(frameworkName);

  if (Object.prototype.toString.call(item) !== "[object Object]") return data;

  Object.keys(item?.["guidance"] || {})?.forEach((element) => {
    if (
      frameworkName === null ||
      element?.toLowerCase() === frameworkName?.toLowerCase()
    ) {
      if (Object.prototype.toString.call(data[element]) === "[object Object]") {
        data[element] = {
          ...data[element],
          guidance: item["guidance"][element],
        };
      } else {
        data[element] = { guidance: item["guidance"][element] };
      }
    }
  });

  Object.keys(item?.["overview"] || {}).forEach((element) => {
    if (
      frameworkName === null ||
      element?.toLowerCase() === frameworkName?.toLowerCase()
    ) {
      if (Object.prototype.toString.call(data[element]) === "[object Object]") {
        data[element] = {
          ...data[element],
          overview: item["overview"][element],
        };
      } else {
        data[element] = { guidence: item["overview"][element] };
      }
    }
  });

  Object.keys(item?.["reference"] || {}).forEach((element) => {
    if (
      frameworkName === null ||
      element?.toLowerCase() === frameworkName?.toLowerCase()
    ) {
      if (Object.prototype.toString.call(data[element]) === "[object Object]") {
        data[element] = {
          ...data[element],
          reference: item["reference"][element],
        };
      } else {
        data[element] = { guidence: item["reference"][element] };
      }
    }
  });

  console.log(data);

  return data;
};

export const generateOtp = () => {
  const otp = Math.floor(100000 + Math.random() * 900000);

  return otp.toString();
};


// const getImageForDomain = (domainName) => {
//   switch (domainName.toLowerCase()) {
//     case "environment":
//       return environmentImage;
//     case "governance":
//       return governanceImage;
//     case "social":
//       return socialImage;
//     case "survey":
//       return surveyImage;
//     case "general":
//       return generalImage;
//     default:
//       return generalImage; // Fallback image
//   }
// };
