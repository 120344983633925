import { getAlldata } from "../../controllers/APIController";

const func = async () => {
  const data = await getAlldata("sectors");
  const list = data?.data;
  const valuesList = list?.map((item) => {
    return { key: item?.toLowerCase(), name: item };
  });
  return valuesList;
};
export const OnboardingOrgDataSchema = {
  Name: {
    id: 1,
    name: "Text Input",
    type: "text",
    title: "Name",
    required: true,
    char_limit: "",
    placeholder: "Enter your organization name",
  },
  URL: {
    id: 2,
    name: "Text Input",
    type: "url",
    title: "URL",
    required: true,
    placeholder: " ",
    isDisable: true,
    startContent: (
      <div className="pointer-events-none flex items-center">
        <span className="text-default-400 text-small">
          {`${process.env.REACT_APP_IP_ADDRESS}/`}
        </span>
      </div>
    ),
  },
  "Choose Your Region": {
    id: 3,
    name: "Drop Down",
    type: "dropdown",
    title: "Choose Your Region",
    placeholder: "Enter your Region",
    values: [
      { key: "us-east", name: "us-east" },
      { key: "us-west", name: "us-west" },
      { key: "us-north", name: "us-north" },
      { key: "us-south", name: "us-south" },
    ],
    required: true,
    ismultiselect: false,
  },
  "Choose Industry Sector": {
    id: 4,
    name: "Drop Down",
    type: "dropdown",
    title: "Choose Industry Sector",
    placeholder: "Enter Industry Sector",
    values: [],
    required: true,
    ismultiselect: false,
  },
  "Financial Name": {
    id: 1,
    name: "Text Input",
    type: "text",
    title: "Financial Name",
    required: true,
    char_limit: "",
    placeholder: "Name of Your Financial Year",
  },
  "Start Date": {
    id: 5,
    name: "Date",
    type: "date",
    title: "Start Date",
    required: true,
  },
  "End Date": {
    id: 6,
    name: "Date",
    type: "date",
    title: "End Date",
    required: true,
  },
};

export const intialOrgData = {
  Name: "",
  URL: "",
  "Choose Your Region": "",
  "Select FrameWork": [],
  tags: [],
  "Choose Industry Sector": "",
  "Financial Name": "",
  "Start Date": "",
  "End Date": "",
};
