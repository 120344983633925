import { Input } from "@nextui-org/react";
import React from "react";

const FrameworkInputFieldAccodian = ({
  id,
  data,
  setData,
  frameWorkDetails,
}) => {
  return (
    <>
      <Input
        type="text"
        className="my-12"
        variant="faded"
        label={`Reference for ${frameWorkDetails[+id]}`}
        labelPlacement="outside"
        placeholder=" "
        onChange={(event) =>
          setData({
            ...data,
            referenceList: {
              ...data?.referenceList,
              [frameWorkDetails[+id]]: event.target.value,
            },
          })
        }
      />
      <Input
        type="text"
        className="my-12"
        variant="faded"
        label={`Overview for ${frameWorkDetails[+id]}`}
        labelPlacement="outside"
        placeholder=" "
        onChange={(event) => {
          setData({
            ...data,
            overviewList: {
              ...data?.overviewList,
              [frameWorkDetails[+id]]: event.target.value,
            },
          });
        }}
      />
      <Input
        type="text"
        className="my-12"
        variant="faded"
        label={`Guidance for ${frameWorkDetails[+id]}`}
        labelPlacement="outside"
        placeholder=" "
        onChange={(event) =>
          setData({
            ...data,
            guidanceList: {
              ...data?.guidanceList,
              [frameWorkDetails[+id]]: event.target.value,
            },
          })
        }
      />
    </>
  );
};

export default FrameworkInputFieldAccodian;
