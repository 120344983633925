import { Image } from "@nextui-org/react";
import React from "react";

const RequestPage = () => {
  
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex flex-col w-[25%]">
        <Image width={80} alt="NextUI hero Image" src="/Assets/logo.svg" />
        <p className="my-4 text-xl font-bold">
          Account creation request has been sent.
        </p>
        <p className="text-sm font-medium">
          Your account creation request has been sent to our admins, we
          will get back to you shortly.
        </p>
      </div>
    </div>
  );
};

export default RequestPage;
