import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DeleteOnedata,
  StringLimit,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
} from "../../controllers/APIController";
import DangerModal from "../Metrics/DangerModal";
import LoadingPage from "../../components/Loader/LoadingPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";

const EstimationLibrary = () => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  let description = CollectionInfo?.attributes?.Description;

  const [metricsList, setmetricsList] = useState([]);
  const [reRenderPage, setReRenderPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteMetricId, setDeleteMetricId] = useState(-1);
  const navigate = useNavigate();
  const {_orgId} = useParams();

  const { pathname } = useLocation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const paramList = ["", "factor library", "library"];
  let HeadingTitle = capitalize(separateDasFromUrl(paramList[2]));

  let filteredUrl = "filters[Metric_Id][$eqi]=" + HeadingTitle;

  console.log(metricsList);

  let headers =
    metricsList.length !== 0
      ? Object.keys(metricsList[0]?.attributes?.JsonSchema)
      : [];

  // if (headers.length !== 0) {
  //   headers.push("Actions");
  // }
  const customizeCompleteList = [...headers];

  const [customizeItems, setCustomizeItems] = useState(new Set([...headers]));

  const coulmnDef = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });

  const customizeHeaderList = [...coulmnDef];
  console.log(customizeHeaderList);
  console.log(headers);

  const naviageHandler = () => {
    navigate(pathname + "/create");
  };

  const EditHandler = (id) => {
    navigate(`/${_orgId}/factor-library/${id}`);
  };

  const dangerModalHandler = (id) => {
    setDeleteMetricId(id);
    setOpenModal(true);
  };

  const deleteHandler = async (id) => {
    console.log(id);
    const result = await DeleteOnedata("responses", id);
    setReRenderPage((pre) => 1 - pre);
  };

  useEffect(() => {
    console.log(filteredUrl);
    getDataWithFilterUrl("responses", filteredUrl)
      .then((data) => {
        const emissionFactorLibraryData = data.data.filter(
          (item) =>
            item.attributes.JsonSchema["Library Type"] ===
            "Estimation Factor Library"
        );

        console.log("filtered data:", emissionFactorLibraryData);

        setmetricsList(emissionFactorLibraryData);
        const headers =
          data?.data?.length !== 0
            ? Object.keys(data.data[0]?.attributes?.JsonSchema)
            : [];

        setCustomizeItems(new Set([...headers]));
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRenderPage]);

  useEffect(() => {
    const url = "filters[Name][$eqi]=" + HeadingTitle;
    getDataWithFilterUrl("metrics", url)
      .then((data) => {
        console.log(data);
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        console.log(responseData);
        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("metricList", metricsList);
  const ItemPerPage = 20;
  const totalPage = Math.ceil(metricsList.length / ItemPerPage);

  return (
    <>
      {
        <DangerModal
          onCloseHanler={setOpenModal}
          onSubmitHandler={deleteHandler}
          id={deleteMetricId}
          modalHeading={"Are you sure you want to delete this Entry?"}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
        />
      }
      <div className="flex justify-end">
        <Button
          color="primary"
          onClick={() => {
            navigate(`${pathname}/create`);
          }}
        >
          + Create a Factor Library
        </Button>
      </div>
      <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton/>}>
        {metricsList.length > 0 ? (
          <>
            {metricsList.length > 0 && (
              <div className="flex justify-end my-4 gap-2">
                {/* <Dropdown className="w-max">
              <DropdownTrigger>
                <Button
                  size="sm"
                  variant="light"
                  className="w-max bg-warmGray-300"
                >
                  Sort
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                variant="flat"
                selectionMode="single"
                defaultSelectedKeys={new Set([sortedBy])}
                selectedKeys={new Set([sortedBy])}
                onSelectionChange={(event) => {
                  setSortedBy(Array.from(event).join(", ").replaceAll("_", " "));
                }}
              >
                {sortingItemList.map((item) => (
                  <DropdownItem key={item.key}>{item.name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown> */}
                <Dropdown className="w-max">
                  <DropdownTrigger>
                    <Button
                      size="sm"
                      variant="light"
                      className="w-max bg-warmGray-300"
                    >
                      Customize
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Multiple selection"
                    variant="flat"
                    closeOnSelect={false}
                    selectionMode="multiple"
                    disallowEmptySelection
                    selectedKeys={customizeItems}
                    onSelectionChange={setCustomizeItems}
                  >
                    {customizeCompleteList.map((name) => (
                      <DropdownItem key={name}>{name}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className="overflow-x-auto pb-8">
              {metricsList.length > 0 && (
                <Table removeWrapper className="mt-8">
                  <TableHeader>
                    {customizeHeaderList.map((header) => (
                      <TableColumn key={header.id}>{header.label}</TableColumn>
                    ))}
                    <TableColumn key={headers.length}>Actions</TableColumn>
                  </TableHeader>

                  <TableBody>
                    {metricsList.length !== 0 &&
                      metricsList.map((item, index) => {
                        const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                        const upperPageLimit = currentPage * ItemPerPage;
                        if (index < lowerPageLimit || index >= upperPageLimit)
                          return;
                        return (
                          <TableRow key={item.id}>
                            {customizeHeaderList.map(({ label: key }) => {
                              console.log(item?.attributes?.JsonSchema);

                              if (
                                item?.attributes?.JsonSchema[key] === "null"
                              ) {
                                return <TableCell>-</TableCell>;
                              }
                              if (
                                typeof item?.attributes?.JsonSchema[key] ===
                                "object"
                              ) {
                                console.log(item?.attributes?.JsonSchema[key]);
                                return (
                                  <TableCell>
                                    <Image
                                      href="#"
                                      style={{ cursor: "pointer" }}
                                      size={26}
                                      onClick={() => {
                                        window.open(
                                          process.env
                                            .REACT_APP_STRAPI_IP_ADDRESS +
                                            item?.attributes?.JsonSchema[key]
                                              .url
                                        );
                                      }}
                                    />
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell>
                                  {typeof item?.attributes?.JsonSchema[key] ===
                                  "string"
                                    ? StringLimit(
                                        item?.attributes?.JsonSchema[key],
                                        80
                                      )
                                    : item?.attributes?.JsonSchema[key]}
                                </TableCell>
                              );
                            })}

                            <TableCell>
                              <div className="flex gap-4 text-center">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => EditHandler(item.id)}
                                >
                                  <Tooltip size="sm" content="Edit Item">
                                    <lord-icon
                                      src="https://cdn.lordicon.com/lzgmgrnn.json"
                                      trigger="hover"
                                      colors={`primary #000000`}
                                      style={{ width: "20px", height: "20px" }}
                                    ></lord-icon>
                                  </Tooltip>
                                </div>

                                <lord-icon
                                  src="https://cdn.lordicon.com/jzjtyyqx.json"
                                  trigger="hover"
                                  target="#Connectors"
                                  state="morph-home-2"
                                  colors={`primary: "#000000"`}
                                  style={{ width: "16px", height: "16px" }}
                                ></lord-icon>

                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setDeleteMetricId(item.id);
                                    onOpen();
                                  }}
                                >
                                  <Tooltip size="sm" content="Delete Item">
                                    <lord-icon
                                      src="https://cdn.lordicon.com/wpyrrmcq.json"
                                      trigger="hover"
                                      colors={`primary #000000`}
                                      style={{ width: "20px", height: "20px" }}
                                    ></lord-icon>
                                  </Tooltip>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </div>
            {metricsList.length > 0 && (
              <div className="w-full mt-8 flex items-center justify-center">
                <Pagination
                showControls
                  total={totalPage}
                  color="secondary"
                  page={currentPage}
                  onChange={setCurrentPage}
                />
              </div>
            )}
          </>
        ) : (
          <NullData />
        )}
      </LoadingPage>
    </>
  );
};

export default EstimationLibrary;
