import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { useParams } from "react-router-dom";

import { Button, Card, Image } from "@nextui-org/react";
import MarkdownWithInputs from "../../components/Policies/MarkdownwithInputs"; // Adjust path as necessary
import {
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
} from "../../controllers/APIController";
import { editIcon } from "../../utils/constant";
import TurndownService from "turndown";
import useGetFrequencyCounter from "../../hooks/useGetFrequencyCounter";
import LoadingPage from "../../components/Loader/LoadingPage";
import SingleControlMetricCard from "../../components/Policies/SingleControlMetricCard";
import { getImageForDomain } from "../../constant/Policies/policyConstant";

const getAllProcedureFields = (text) => {
  let data = {};
  const handlebarRegex = /{{([^}]+)}}/g;
  let lastIndex = 0;
  let match;
  while ((match = handlebarRegex.exec(text)) !== null) {
    const x = match[1].trim();
    data = { ...data, [x]: "" };
    lastIndex = handlebarRegex.lastIndex;
  }
  return data;
};

const Procedures = () => {
  const { FrequencyCounterList } = useGetFrequencyCounter();
  const turndownService = new TurndownService();

  const { _procedure, _orgId: currOrg, _policy } = useParams();
  const procedureName = _procedure ? _procedure.replace(/-/g, " ") : "";

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const HeadingTitle = capitalize(separateDasFromUrl(procedureName));
  const procedurefilteredUrl =
    orgFilterUrl + "&filters[Name][$eqi]=" + HeadingTitle;
  const [loader, setLoader] = useState(true);
  const [procedureData, setProcedureData] = useState({}); // Initialize procedure data state with null
  const [controllMatricList, setControllMatricList] = useState([]);
  const [procedure, setProcedure] = useState(null); // Initialize procedure state with null

  const [errorMessage, setErrorMessage] = useState({});
  const validation = () => {
    let flag = false;
    const err = {};
    for (let key in procedureData) {
      if (procedureData[key] === "") {
        flag = true;
        err[key] = `Filled this field!!`;
      }
    }
    if (flag) setErrorMessage(err);
    return flag;
  };

  const submitHandler = async () => {
    if (validation()) return;

    const updatedProcedureData = {
      Response: { ...procedureData },
    };

    const result = await UpdateOneData(
      "procedures",
      procedure?.id,
      updatedProcedureData
    );

    window.location.reload();
  };
  const resetProcedureData = async () => {
    const procedureOriginId = procedure?.attributes?.OriginId;
    const filteredUrl = `filters[id][$eqi]=${procedureOriginId}`;
    const response = await getDataWithFilterUrl("procedures", filteredUrl);
    const originProcedureInfo = response?.data[0]?.attributes;

    const data = {
      ProcedureFramework:originProcedureInfo?.ProcedureFramework,
      Response:null
    };
    const result = await UpdateOneData("procedures", procedure?.id, data);

    window.location.reload();
  };
  useEffect(() => {
    getDataWithFilterUrl("procedures", procedurefilteredUrl)
      .then((data) => {
        // Check if data.data exists and has length > 0 before setting procedure
        if (data.data && data.data.length > 0) {
          setProcedure(data.data[0]);

          const procedureResponse =
            data.data[0]?.attributes?.Response ||
            getAllProcedureFields(data.data[0]?.attributes?.ProcedureFramework);

          setProcedureData(procedureResponse);

          setControllMatricList(
            data?.data[0]?.attributes?.ControlMetrics?.controlMetrics
          );
          setLoader(false);
        } else {
          setProcedure(null); // Set to null if no procedure data found
          setLoader(false);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setProcedure(null); // Handle error by setting procedure to null
      });
  }, [procedurefilteredUrl]);

  const markDownData = turndownService.turndown(
    procedure?.attributes?.ProcedureFramework || ""
  );

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={loader}>
        <div>
          {procedure && (
            <div className="flex justify-between">
              <p className="text-3xl font-medium flex gap-4 items-center">
                <div>
                  <Image
                    alt="policy logo"
                    height={40}
                    radius="sm"
                    src={getImageForDomain(procedure.attributes.DomainName)}
                    width={40}
                  />
                </div>
                {HeadingTitle}
              </p>
              <Button color="danger" onClick={submitHandler}>
                Update Procedure
              </Button>
            </div>
          )}

          {procedure && (
            <div>
              <p className="text-xl font-medium mt-8">Objectives</p>
              <div className="mt-4">{procedure.attributes.Objective}</div>
            </div>
          )}
          <div className="flex items-start justify-between gap-4 mt-8">
            <div className="flex justify-between w-[65%]">
              {procedure && (
                <Card
                  className="w-full p-4 border border-divider"
                  shadow="none"
                >
                  <div>
                    <div className="flex justify-end gap-2">
                      <Button
                        color="danger"
                        variant="ghost"
                        onClick={1}
                        // startContent={}
                        className="flex items-center justify-center"
                      >
                        {editIcon}
                        <span>Edit</span>
                      </Button>
                      <Button
                        color="secondary"
                        variant="ghost"
                        onClick={resetProcedureData}
                        // startContent={}
                        className="flex items-center justify-center"
                      >
                        <span>Reset</span>
                      </Button>
                    </div>
                    <p className="text-xl font-medium mt-8">
                      Procedure Framework
                    </p>
                    <div className="mt-4">
                      <MarkdownWithInputs
                        markdownContent={
                          procedure.attributes.ProcedureFramework
                        }
                        procedureData={procedureData}
                        onChangeProcedureData={setProcedureData}
                        errorMessage={errorMessage}
                      />
                    </div>
                  </div>
                </Card>
              )}
            </div>

            {controllMatricList.length > 0 && (
              <div className="flex flex-col gap-4 w-[30%] max-h-[500px] overflow-auto hide-scollbar">
                <p className="font-medium text-xl text-center">
                  Control Metrics
                </p>
                {controllMatricList?.map((item) => (
                  <SingleControlMetricCard
                    id={+item}
                    FrequencyCounterList={FrequencyCounterList}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default Procedures;
