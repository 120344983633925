import { Card, CardBody, CardHeader, Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { getImageForDomain } from "../../constant/Policies/policyConstant";
import { StringLimit } from "../../controllers/APIController";

const PolicyCard = ({ currOrg, filteredPolicy, domain }) => {
    const navigate = useNavigate();
  
    const handleOnNavigate = (path) => {
      const formattedPath = path.replace(/\s+/g, "-");
      navigate(`/${currOrg}/policies/${formattedPath}`);
    };
  
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          handleOnNavigate(filteredPolicy.attributes.Name);
        }}
      >
        <Card
          key={filteredPolicy.id}
          className="h-[250px] p-2 border border-divider"
          shadow="none"
        >
          <CardHeader className="flex gap-3">
            <Image
              alt="policy logo"
              height={40}
              radius="sm"
              src={getImageForDomain(domain.attributes.Name)}
              width={40}
            />
            <div className="flex flex-col">
              <p className="text-md">{filteredPolicy.attributes.Name}</p>
              <p className="text-small text-default-500">
                {domain.attributes.Name} Policy
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <div>{StringLimit(filteredPolicy?.attributes?.Description, 150)}</div>
          </CardBody>
        </Card>
      </div>
    );
  };

  export default PolicyCard