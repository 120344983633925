import React from "react";
import {
 
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Slider,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import {
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  getItemForRelation,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import {  useNavigate, useParams } from "react-router-dom";
import { CreateNewEntryButton } from "../../utils/helper";
import { parseDate } from "@internationalized/date";
import { DateFormator } from "../../utils/helper";
/* 
->I need to fetch the form Schema from Metric
->I need to fetch Form Data from Response
UpdateOneData(collectionName,id,data)
*/

const getFormulaData = (formules, formData) => {
  const response = {};
  console.log(formules, formData);
  formules?.map((item) => {
    const func = new Function("return (" + item?.fun + ")");
    const value = func()(formData);
    console.log(value);
    response[item.title] = value === undefined ? "null" : value;
  });
  return response;
};
async function fetchRealtionData(formSchema, index, setFormSchema) {
  if (formSchema[index][1].values === undefined) {
    let newFormSchema = [...formSchema];

    newFormSchema[index][1].values = await getItemForRelation(
      formSchema[index][1].relatedTo
    );
    setFormSchema(newFormSchema);
  }
}

const EditLibraryForm = () => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [metricInfo, setMetricInfo] = useState({});
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([]);

  const navigate = useNavigate();
  const { _responseId,_orgId } = useParams();
  console.log(_responseId);
  let paramsList = ["/", "factor library", "library", _responseId];
  const ResponseId = +paramsList[3];

  let HeadingTitle = capitalize(separateDasFromUrl(paramsList[2]));
  const requiredArr = metricInfo?.attributes?.JsonSchema?.required;
  const metricFilteredUrl =
    "filters[Category][$eqi]=" +
    paramsList[1].toLocaleLowerCase().replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    paramsList[2].replaceAll("-", " ");

  const responseMetricUrl = "filters[id][$eqi]=" + ResponseId;

  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};

    formSchema.forEach((row) => {
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];

      // You can add more validation rules based on the field type

      if (fieldType === "text" && fieldName === "Name" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "dropdown" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "enumeration" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType == "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "media") {
        if (fieldValue === "") {
          errors[fieldName] = `Upload a file `;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "richtext") {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required `;
          isValid = false;
        }
      }
    });
    console.log(errors);
    setValidationErrors(errors);
    return isValid;
  };
  const changeHandler = async (title, value, type) => {
    console.log(title, value);

    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);
      // console.log("field[0]", field);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
        console.log(data);
        console.log(data[0].id, data[0].url);
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();

    if (!isValid) {
      // setErrorMessage("Something went wrong!!");
      return;
    }

    const formulaData = getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const publishData = {
      Metric_Id: paramsList[2].replaceAll("-", " "),
      JsonSchema: { ...formdata, ...formulaData },
    };

    let result = await UpdateOneData("responses", ResponseId, publishData);

    console.log(result);

    navigate(`/${_orgId}/factor-library`);
  };

  useEffect(() => {
    // Firsrt We fetch the Schema Data from Metric Collection and then We fetch the data from Response collection for Particular Id

    getDataWithFilterUrl("metrics", metricFilteredUrl)
      .then((data) => {
        setMetricInfo(data.data[0]);
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);

        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setDescription(data.data[0]?.attributes?.Description);
        setLinks(data?.data[0]?.attributes?.Links?.Links);
        getDataWithFilterUrl("responses", responseMetricUrl).then(
          (responseData) => {
            // console.log(responseData.data[0].attributes.JsonSchema);
            const reponseFormData =
              responseData?.data[0]?.attributes?.JsonSchema;

            Object.keys(reponseFormData).forEach((key) => {
              if (reponseFormData[key] === "null") {
                reponseFormData[key] = "";
              }
            });
            setFormData(reponseFormData);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${_orgId}/${metric}/${relatedToName}/create`);

    window.location.reload();
  };

  console.log("FormData", formdata, formSchema, metricInfo);
  return (
    <div>
      <div className="flex justify-end my-4">
        <Button color="danger" variant="ghost" onClick={submitHandler}>
          Update
        </Button>
      </div>
      <div className="flex gap-[160px]">
        <div className="w-full flex flex-wrap gap-x-12 gap-y-0">
          {formSchema &&
            formSchema.map((row, index) => {
              const fieldError = validationErrors[row[0]];
              const isRequired = requiredArr.includes(row[0]);
              if (row[1].type == "string" || row[1].type == "text") {
                return (
                  <Input
                    type="text"
                    className="mb-16 w-[45%]"
                    isRequired={requiredArr.includes(row[0])}
                    label={row[1].title}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={formdata[row[0]] || ""}
                    onChange={(event) => {
                      changeHandler(row[0], event.target.value, row[1].type);
                    }}
                    isInvalid={fieldError && isRequired}
                    errorMessage={fieldError}
                  />
                );
              }
              if (row[1].type === "password") {
                return (
                  <Input
                    type="password"
                    className="mb-16 w-[45%]"
                    isRequired={requiredArr.includes(row[0])}
                    label={row[1].title}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={formdata[row[0]] || ""}
                    onChange={(event) => {
                      changeHandler(row[0], event.target.value, row[1].type);
                    }}
                    isInvalid={fieldError && isRequired}
                    errorMessage={fieldError}
                  />
                );
              }
              if (row[1].type === "integer") {
                return (
                  <Input
                    type="number"
                    className="mb-16 w-[45%]"
                    isRequired={requiredArr.includes(row[0])}
                    label={row[1].title}
                    placeholder="0"
                    variant="faded"
                    labelPlacement="outside"
                    value={formdata[row[0]] || 0}
                    onChange={(event) => {
                      changeHandler(row[0], +event.target.value, row[1].type);
                    }}
                    isInvalid={fieldError && isRequired}
                    errorMessage={fieldError}
                  />
                );
              }
              if (row[1].type === "boolean") {
                console.log(typeof formdata[row[0]]);
                const value = formdata[row[0]] === "true" ? true : false;
                return (
                  <div>
                    <p className="mb-2 w-[45%]">{row[1].title}</p>
                    <Switch
                      defaultSelected={formdata[row[0]] === "true"}
                      color="primary"
                      className="mb-16 w-[45%]"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      labelPlacement="outside"
                      onChange={(event) =>
                        changeHandler(
                          row[0],
                          event.target.checked.toString(),
                          row[1].type
                        )
                      }
                    >
                      {formdata[row[0]] === "true" ? "On" : "Off"}
                    </Switch>
                  </div>
                );
              }
              if (row[1].type === "textarea") {
                return (
                  <Textarea
                    className="mb-16 w-[45%]"
                    label={row[1].title}
                    key="1"
                    isRequired={requiredArr.includes(row[0])}
                    variant="faded"
                    labelPlacement="outside"
                    value={formdata[row[0]]}
                    placeholder=" "
                    onChange={(event) => {
                      changeHandler(row[0], event.target.value, row[1].type);
                    }}
                    isInvalid={fieldError && isRequired}
                    errorMessage={fieldError}
                  />
                );
              }
              //   if (row[1].type === "file") {
              //     return (
              //       <div
              //         className="input-container"
              //         style={{ margin: "24px 0px" }}
              //       >
              //         <FileUploader
              //           labelTitle={
              //             <>
              //               {row[1].title}
              //               {requiredArr.includes(row[0]) && (
              //                 <span style={{ color: "red" }}>*</span>
              //               )}
              //             </>
              //           }
              //           // labelDescription="Max file size is 500mb. Only .jpg files are supported."
              //           buttonLabel="Add file"
              //           buttonKind="primary"
              //           size="md"
              //           filenameStatus="edit"
              //           accept={[".jpg", ".png"]}
              //           multiple={true}
              //           disabled={false}
              //           iconDescription="Delete file"
              //           onChange={(event) => {
              //             console.log(event);
              //             changeHandler(
              //               row[0],
              //               event.target.files[0],
              //               row[1].type
              //             );
              //           }}
              //           // value={formdata[row[0]].url}
              //           name=""
              //           className="input-container"
              //           invalid={fieldError && isRequired}
              //           invalidText={fieldError}
              //         />
              //       </div>
              //     );
              //   }
              if (row[1].type === "slider") {
                return (
                  <Slider
                    label={row[1].title}
                    isRequired={requiredArr.includes(row[0])}
                    className="mb-16 w-[45%]"
                    step={1}
                    maxValue={100}
                    minValue={0}
                    defaultValue={formdata[row[0]] || 0}
                    onChange={(value) => {
                      changeHandler([row[0]], value, row[1].type);
                    }}
                    isInvalid={fieldError && isRequired}
                    errorMessage={fieldError}
                  />
                );
              }
              if (row[1].type === "dropdown") {
                return (
                  <div className="flex flex-col gap-2 text-sm w-[45%]">
                    <p>{row[1].title}</p>
                    <Dropdown>
                      <DropdownTrigger
                        isInvalid={fieldError && isRequired}
                        errorMessage={fieldError}
                      >
                        <Button className="mb-16" variant="faded">
                          <div className="w-full flex justify-between">
                            <div className="flex justify-start w-full items-start">
                              {formdata[row[0]] === "" ? "" : formdata[row[0]]}
                            </div>
                            <div className="flex justify-end w-full items-end">
                              <FaChevronDown />
                            </div>
                          </div>
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Static Actions"
                        className="w-144 custom-dropdown-menu"
                        selectionMode="single"
                        //   selectedKeys={}
                        onSelectionChange={(event) => {
                          changeHandler(
                            row[0],
                            Array.from(event).join(", ").replaceAll("_", " "),
                            row[1].type
                          );
                        }}
                      >
                        {row[1]?.values?.map((item) => (
                          <DropdownItem key={item.name}>
                            {item.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                );
              }
              if (row[1].type === "date") {
                console.log(formdata[row[0]]);
                return (
                  <>
                    <DatePicker
                      label={row[1].title}
                      labelPlacement="outside"
                      className="mb-16 w-[45%]"
                      value={
                        formdata[row[0]] !== undefined &&
                        formdata[row[0]] !== ""
                          ? parseDate(formdata[row[0]])
                          : null
                      }
                      isRequired={requiredArr.includes(row[0])}
                      onChange={(value) => {
                        changeHandler(row[0], DateFormator(value), row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  </>
                );
              }
              if (row[1].type === "relation") {
                console.log(row, formSchema, index);
                updateFormSchemaWithRealtion(formSchema, index, setformSchema);
                console.log(formSchema);
                let newRawValue =
                  row[1].values === undefined
                    ? [
                        {
                          id: "button",
                          name: (
                            <CreateNewEntryButton
                              name={row[1].relatedto}
                              metricName={row[1].metricname}
                              onClickHandler={clickHandler}
                            />
                          ),
                        },
                      ]
                    : [
                        {
                          id: "button",
                          name: (
                            <CreateNewEntryButton
                              name={row[1].relatedto}
                              metricName={row[1].metricname}
                              onClickHandler={clickHandler}
                            />
                          ),
                        },
                        ...row[1]?.values,
                      ];
                console.log(newRawValue);
                return (
                  <div className="flex flex-col gap-2 text-sm w-[45%]">
                    <p>{`${row[1].title} ${
                      requiredArr.includes(row[0]) ? " *" : ""
                    }`}</p>
                    <Dropdown>
                      <DropdownTrigger
                        invalid={fieldError && isRequired}
                        invalidText={fieldError}
                      >
                        <Button className="w-full mb-16" variant="faded">
                          <div className="w-full flex justify-between">
                            <div className="flex justify-start w-full items-start">
                              {formdata[row[0]] === "" ? "" : formdata[row[0]]}
                            </div>
                            <div className="flex justify-end w-full items-end">
                              <FaChevronDown />
                            </div>
                          </div>
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Static Actions"
                        className="w-144 custom-dropdown-menu"
                        selectionMode="single"
                        selectedKeys={"a"}
                        onSelectionChange={(event) => {
                          changeHandler(
                            row[0],
                            Array.from(event).join(", ").replaceAll("_", " "),
                            row[1].type
                          );
                        }}
                      >
                        {newRawValue.map((item) => (
                          <DropdownItem key={item.name}>
                            {item.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default EditLibraryForm;
