export const AddTemplateSchema = {
  Name: {
    id: 1,
    name: "Text Input",
    type: "text",
    title: "Name",
    required: true,
    char_limit: "",
    placeholder: "Your Template Name",
  },
  Description: {
    id: 2,
    name: "Text Area",
    type: "textarea",
    title: "Description",
    required: true,
    placeholder: "More About The Template",
    char_limit: 0,
  },
  "Attached Template": {
    id: 3,
    name: "File Upload",
    type: "file",
    title: "Attached Template",
    required: true,
    acceptfieldtype: [
      "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
};

export const intialtemplateData = {
  Name: "",
  Description: "",
  "Attached Template": "",
};
