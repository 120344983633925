import React from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  PublishData,
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { Accordion, AccordionItem, Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import {
  getFieldMoreInfoContent,
  getInitailState,
  getSchemaForFramework,
  getSchemaForOrg,
  handleCopyClick,
  increasedMetricCurrentCount,
} from "../../utils/helper";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import FileEvidenceInput from "../FormBuilder/InputField/FileEvidenceInput";
import useGetOrgFramework from "../../hooks/useGetOrgFramework";
import LoadingPage from "../../components/Loader/LoadingPage";

const ViewFrameworkMetric = () => {
  const { _id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const frameworkName = searchParams.get("framework");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const [isLoading, setIsLoading] = useState(true);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);

  const { orgFrameworkDetails, isLoading: isOrgFrameworkLoading } =
    useGetOrgFramework(OrgInfo);
  const navigate = useNavigate();

  const { _orgId: currOrg } = useParams();

  const isFormulaVisiable =
    CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  let HeadingTitle = capitalize(
    CollectionInfo?.attributes?.NameList?.[frameworkName] || "Unknown"
  );

  let description = CollectionInfo?.attributes?.Description;
  let overviewText =
    CollectionInfo?.attributes?.Overview?.[frameworkName] || "--";
  let guidanceText =
    CollectionInfo?.attributes?.Guidance?.[frameworkName] || "--";

  const changeHandler = async (title, value, type) => {
    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const curlMessage = `curl --location '${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send' \n
  --header 'Content-Type: application/json' \n
  --header 'apikey: <Enter Your API Key>' \n
  --data '{
      "metric_id": "${CollectionInfo?.id}",
      "response": <Enter Your JSON Response>
      
  }'`;

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.toLowerCase().replaceAll(" ", "-");
    let relatedToName = relatedTo.toLowerCase().replaceAll(" ", "-");

    navigate(`/${currOrg}/metrics/${metric}/${relatedToName}/create`);
    window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", `filters[id][$eqi]=${_id}`)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });

        // getSchemaForOrg(responseData, OrgInfo).then((data) => {
        //   setformSchema(data);
        // });
        getSchemaForFramework(responseData, OrgInfo, frameworkName).then(
          (data) => {
            setformSchema(data);
          }
        );
        setCollectionInfo(data.data[0]);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        setFormData(getInitailState(responseArr));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(CollectionInfo, orgFrameworkDetails);
  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading || isOrgFrameworkLoading}>
        <>
          <div className="metric-header">
            <p className="text-3xl font-medium">{HeadingTitle}</p>
            <p className="my-4">
              {description ||
                `Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Ab distinctio, nobis voluptas illum quo et, quaerat aut, minus
            excepturi id aliquam nisi fuga?`}
            </p>

            <div className="flex flex-col gap-1 text-sm">
              <p className="flex items-center gap-2 font-medium">
                <span>Overview</span>{" "}
              </p>
              <p className="text-gray">{overviewText}</p>
            </div>
            <div className="flex flex-col gap-1 text-sm my-4">
              <p className="flex items-center gap-2 font-medium">
                <span>Guidance</span>{" "}
              </p>
              <p className="text-gray">{guidanceText}</p>
            </div>
          </div>

          <div className="flex gap-[60px] my-8">
            <div className="w-[50%]">
              {formSchema &&
                formSchema.map((row, index) => {
                  const moreInfo = getFieldMoreInfoContent(
                    row[1],
                    frameworkName || ""
                  );
                  console.log(moreInfo);
                  if (Object.keys(moreInfo).length === 0) return null;
                  if (row[1].type === "string" || row[1].type === "text") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileEvidenceInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="w-[45%]">
              <div className="flex flex-col relative w-full">
                <div className={`w-full ${links?.length === 0 && "hidden"}`}>
                  <div className="text-lg font-semibold">
                    Helpful Background Reading:
                  </div>
                  <div>
                    <div className="link-list my-2 text-blue-600 font-semibold">
                      {links?.map((link, index) => (
                        <div className="links">
                          <Link
                            key={index}
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Accordion size="lg" isflust={true}>
                    <AccordionItem
                      title="Webhook API"
                      className="curl-accordion-item"
                    >
                      <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        Integrate our form apis to your workflow
                      </p>
                      <div className="relative rounded-xl bg-black my-2">
                        <pre className="p-2 text-[#899fef]">{curlMessage}</pre>
                        <MdOutlineContentCopy
                          className="absolute top-0 right-0 m-4 text-white cursor-pointer"
                          onClick={() => handleCopyClick(curlMessage)}
                        />
                      </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              {isFormulaVisiable && (
                <div className="w-[40%] bg-grey-400 rounded-sm p-2 h-max">
                  Test Calculation
                </div>
              )}
            </div>
          </div>
        </>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default ViewFrameworkMetric;
