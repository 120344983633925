import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import {
  faCloud,
  faBolt,
  faCircleCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";


export default function Connectors() {

  const { _orgId: currOrg } = useParams();
 

  
  return (
    <div>
      <div className="text-3xl font-medium">Connectors</div>
      <div className="mt-8">
        Data connectors can help you bring external sources directly into your
        knowledge base. Each connector can be located in your collection
        folders. You can also have multiple connectors to one direction.
      </div>
      <div className="w-full text-center flex justify-end items-end p-8 left-0">
        <Link to="/connectors/new-connector">
          <Button color="danger">+ Add a connector</Button>
        </Link>
      </div>

      <Table removeWrapper aria-label="Example static collection table">
        <TableHeader>
          <TableColumn>APP</TableColumn>
          <TableColumn>Crawling</TableColumn>
          <TableColumn>Indexing Status</TableColumn>
        </TableHeader>
        
        <TableBody >
          <TableRow key="1">
            <TableCell className="gap-2">
              <FontAwesomeIcon icon={faCloud} /> Asana
            </TableCell>
            <TableCell>
              <FontAwesomeIcon icon={faBolt} /> Enabled
            </TableCell>
            <TableCell>
              <FontAwesomeIcon icon={faCircleCheck} /> Active
            </TableCell>
          </TableRow>
          <TableRow key="2">
            <TableCell>
              <FontAwesomeIcon icon={faDropbox} /> Dropbox
            </TableCell>
            <TableCell>
              <FontAwesomeIcon icon={faBolt} /> Enabled
            </TableCell>
            <TableCell>
              <FontAwesomeIcon icon={faSpinner} /> Job in progress
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
