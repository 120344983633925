import React from "react";
import { Card, CardBody, CardHeader, Divider, Image } from "@nextui-org/react";
import { capitalize } from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";

const OrgCard = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div
      className="cursor-pointer"
      onClick={() => navigate(`/${title}/dashboard`)}
    >
      <Card className="w-[400px] h-[150px] border border-divider" shadow="none">
        <CardHeader className="flex gap-3">
          <Image
            alt="nextui logo"
            height={40}
            radius="sm"
            src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
            width={40}
          />
          <div className="flex flex-col">
            <p className="text-md">{capitalize(title) || "Audyt- Org"}</p>
            <p className="text-small text-default-500">
              {process.env.REACT_APP_IP_ADDRESS}/{title}
            </p>
          </div>
        </CardHeader>
        <Divider />
        <CardBody>Visit ESG analysis of {capitalize(title)}</CardBody>
      </Card>
    </div>
  );
};

export default OrgCard;
