import React from "react";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import ComingSoon from "../../components/ComingSoon";

const Report = () => {
  const navigate = useNavigate();

  return (
    <BreadCrumbsPage>
      <h2 className="text-3xl font-medium">Report</h2>

      {/* <>
        <h2 className="text-3xl font-medium">Report</h2>
        <div className="mt-8">
          Make your metrics into live ESG Reports by creating and publishing them.
        </div>
        <div className="flex bg-slate-50 p-4 mt-8">
          <div
            className="  w-96 border-gray-300 p-4"
            onClick={() => navigate("/Framework1")}
          >
            <h4 className="text-lg font-medium">Sustainability Annual</h4>
            <p className="text-sm mt-2">Report 2023</p>
            <p className="text-sm mt-2">Framework Included</p>
            <p className="text-sm mt-2">TCFD, SASB, GRI, BRSR</p>
            <div className="flex justify-end mt-4 space-x-4">
              <Button size="small" color="primary">
                Download
              </Button>
              <Button kind="secondary" size="small">
                Status
              </Button>
            </div>
          </div>
          <div
            className="  w-96    border-gray-300 p-4"
            onClick={() => navigate("/Framework2")}
          >
            <h4 className="text-lg font-medium">Started at: 29 March</h4>
            <p className="text-sm mt-2">Complete by: 30 May 2024</p>
            <FontAwesomeIcon
              icon={faUserPlus}
              className="mt-4 text-gray-600"
              size="lg"
            />
          </div>
          <div onClick={() => navigate("/Framework3")}>
            <Chart />
          </div>
        </div>
      </> */}

      <ComingSoon />
    </BreadCrumbsPage>
  );
};

export default Report;
