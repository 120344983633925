import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "../controllers/APIController";

const formatBreadcrumbItem = (item) => {
  return capitalize(item.replace(/-/g, " "));
};

const BreadCrumbsPage = ({ children }) => {
  const navigator = useNavigate();
  const { pathname } = useLocation();
  let BreadCrumbList = pathname.split("/");
  const BreadCrumbLength = BreadCrumbList.length;
  console.log(BreadCrumbList);
  // Ensure the first breadcrumb item is "Audyt"
  BreadCrumbList[0] = "Audyt";

  // If the path is exactly "/", add "Dashboard" as the second breadcrumb item
  if (pathname === "/") {
    BreadCrumbList = ["Audyt", "Dashboard"];
  } else {
    for (let i = 1; i < BreadCrumbList.length; i++) {
      BreadCrumbList[i] = formatBreadcrumbItem(BreadCrumbList[i]);
    }
  }

  const navigateHandler = (index) => {
    if (index === 0) {
      navigator("/dashboard");
      return;
    } else if (index === BreadCrumbLength - 1) return;
    let url =
      "/" +
      BreadCrumbList.slice(1, index + 1)
        .join("/")
        .replace(/ /g, "-")
        .toLowerCase();
    navigator(url);
  };

  console.log(BreadCrumbList);
  
  return (
    <div className="hide-scollbar">
      <div>
        <Breadcrumbs size="lg" className="mb-4">
          {BreadCrumbList.map((item, index) => {
            if (index === 0) return null;
            return (
              <BreadcrumbItem
                key={index}
                onClick={() => navigateHandler(index)}
              >
                {item}
              </BreadcrumbItem>
            );
          })}
        </Breadcrumbs>
      </div>
      {children}
    </div>
  );
};

export default BreadCrumbsPage;
