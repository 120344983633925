import React, { useEffect, useState } from "react";
import {
  GetAllMedia,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import {
  Table,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableColumn,
  Button,
  useDisclosure,
  Image,
} from "@nextui-org/react";
import DownloadIcon from "../../Images/Icons/download.svg";
import { useNavigate, useParams } from "react-router-dom";

import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import LoadingPage from "../../components/Loader/LoadingPage";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
import AddEvidenceModal from "./AddEvidenceModal";

const Evidence = () => {
  const [reRenderPage, setReRenderPage] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [evidenceData, setEvidenceData] = useState([]);
  const [filterEvidenceData, setFilterEvidenceData] = useState([]);
  const [page, setPage] = useState(1);

  const [mediaData, setMediaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const Navigate = useNavigate();
  const { _orgId: currOrg } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  useEffect(() => {
    GetAllMedia()
      .then((data) => {
        console.log("Media Data:", data); // Check the structure of data
        setMediaData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching media data:", err);
        // Optionally, you can set mediaData to an empty array or handle the error in another way
      });
  }, []);

  console.log(mediaData);
  const headers = ["Sl. No.", "Name", "Document Type", "Size", "Date", ""];

  const downloadHandler = (url) => {
    window.open(url);
  };

  useEffect(() => {
    getDataWithFilterUrl("evidences", orgFilterUrl)
      .then(async (data) => {
        console.log(data);

        const dataList = data?.data || [];
        setEvidenceData(dataList);
        setFilterEvidenceData(dataList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRenderPage]);

  console.log(filterEvidenceData);

  return (
    <BreadCrumbsPage>
      <>
        <AddEvidenceModal
          onSubmitHandler={1}
          id={1}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          setReRenderPage={setReRenderPage}
        />
        <div className="Container-container">
          <div className="text-3xl font-medium">Evidences</div>
          <div className="mt-8 flex justify-between items-center">
            View your evidences that backs your ESG reports.
            <Button
              color="danger"
              startContent={
                <lord-icon
                  src="https://cdn.lordicon.com/smwmetfi.json"
                  trigger="hover"
                  colors="primary:#ffffff"
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
              }
              onClick={() => onOpen()}
            >
              Add an evidence file
            </Button>
          </div>

          <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
            {mediaData && mediaData?.length > 0 ? (
              <div className="mt-16">
                <Table
                  removeWrapper
                  aria-label="Example static collection table"
                >
                  <TableHeader>
                    <TableColumn>Name</TableColumn>
                    <TableColumn>Description</TableColumn>
                    <TableColumn>Reference To</TableColumn>
                    <TableColumn>Upload Date</TableColumn>
                    <TableColumn>Actions</TableColumn>
                  </TableHeader>
                  <TableBody
                    emptyContent={
                      <NullData title={`No Evidence found.`} />
                    }
                  >
                    {filterEvidenceData &&
                      Array.isArray(mediaData) &&
                      filterEvidenceData.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <div className="flex gap-2">
                              <lord-icon
                                src="https://cdn.lordicon.com/rbbnmpcf.json"
                                trigger="hover"
                                colors={`primary:#000000`}
                                style={{ width: "20px", height: "20px" }}
                              ></lord-icon>
                              {item?.attributes?.Name}
                            </div>
                          </TableCell>
                          <TableCell>{item?.attributes?.Description}</TableCell>
                          <TableCell>
                            {item?.attributes?.ReferenceList?.data?.length > 0
                              ? item?.attributes?.ReferenceList?.data?.length
                              : "---"}
                          </TableCell>
                          <TableCell>
                            {new Date(
                              item?.attributes?.createdAt
                            ).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <div
                              className="flex items-center gap-2 cursor-pointer"
                              onClick={() =>
                                downloadHandler(item?.attributes?.EvidenceUrl)
                              }
                            >
                              <Image
                                width={20}
                                alt="NextUI hero Image"
                                src={DownloadIcon}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <NullData />
            )}
          </LoadingPage>
        </div>
      </>
    </BreadCrumbsPage>
  );
};

export default Evidence;
