import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import MarkdownEditor from "../Dashboard/MarkdownEditor";
import { UpdateOneData ,getDataWithFilterUrl} from "../../controllers/APIController";

const EditPolicyFrameWorkModal = ({
  onSubmitHandler,
  id,
  modalHeading,
  isOpen,
  onOpen,
  onOpenChange,
  onReRender
}) => {
  const [markdownContent, setMarkDownContent] = useState("");
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]="+OrgInfo;

  const submitHandler = async (onClose) => {
    // await onSubmitHandler(id);
    const publishData = {
      PolicyFramework: markdownContent,
    };
    let result = await UpdateOneData("policies", id, publishData);

    onReRender(pre=>!pre);
    onClose();
  };

  useEffect(() => {
    const filterUrl = orgFilterUrl+"&filters[id][$eqi]=" + id;
    getDataWithFilterUrl("policies", filterUrl)
      .then((data) => {
        setMarkDownContent(data?.data[0]?.attributes?.PolicyFramework);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="3xl"
        className=""
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Policy FrameWork
              </ModalHeader>
              <ModalBody classNames="">
                <div className="">
                  <MarkdownEditor
                    descriptionContent={markdownContent}
                    setDescriptionContent={setMarkDownContent}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditPolicyFrameWorkModal;
