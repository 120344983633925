import { Input } from "@nextui-org/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownWithInputs = ({
  markdownContent,
  procedureData,
  onChangeProcedureData,
  errorMessage,
}) => {
  const handlebarRegex = /{{([^}]+)}}/g;

  const renderMarkdownWithInputs = (
    text,
    procedureData,
    onChangeProcedureData
  ) => {
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = handlebarRegex.exec(text)) !== null) {
      // Push the text before the handlebar
      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }
      // Push the handlebar content as an input field with custom width
      const x = match[1].trim();

      //   onChangeProcedureData({ ...procedureData, [x]: "" });

      if (!match[1]) return;

      parts.push(
        <Input
          key={match.index}
          size="sm"
          type="text"
          variant="underlined"
          name={match[1].trim()}
          placeholder={match[1].trim()}
          className="w-[175px] inline-block mx-2"
          value={match[1]?.trim() && procedureData[match[1]?.trim()]}
          onChange={(event) => {
            onChangeProcedureData({
              ...procedureData,
              [x]: event.target.value,
            });
          }}
          isInvalid={errorMessage[x] !== undefined && errorMessage[x] !== ""}
          errorMessage={errorMessage[x]}
        />
      );

      lastIndex = handlebarRegex.lastIndex;
    }

    // Push any remaining text after the last handlebar
    if (lastIndex < text?.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts.map((part, index) => {
      if (typeof part === "string") {
        return (
          <ReactMarkdown
            key={index}
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({ node, children }) => (
                <p className="inline leading-8 mt-2">{children}</p>
              ),
              ul: ({ node, children }) => (
                <ul className="list-disc list-inside leading-8 mt-2">
                  {children}
                </ul>
              ),
              ol: ({ node, children }) => (
                <ol className="list-decimal list-inside leading-8 mt-2">
                  {children}
                </ol>
              ),
              li: ({ node, children }) => (
                <li className="inline leading-8 mt-2">{children}</li>
              ),
              h1: ({ node, children }) => (
                <h1 className="text-2xl font-bold leading-8 mt-4 mb-2">
                  {children}
                </h1>
              ),
              h2: ({ node, children }) => (
                <h2 className="text-xl font-bold leading-8 mt-4 mb-2">
                  {children}
                </h2>
              ),
              h3: ({ node, children }) => (
                <h3 className="text-lg font-bold leading-8 mt-4 mb-2">
                  {children}
                </h3>
              ),
              h4: ({ node, children }) => (
                <h4 className="text-base font-bold leading-8 mt-4 mb-2">
                  {children}
                </h4>
              ),
              h5: ({ node, children }) => (
                <h5 className="text-sm font-bold leading-8 mt-4 mb-2">
                  {children}
                </h5>
              ),
              h6: ({ node, children }) => (
                <h6 className="text-xs font-bold leading-8 mt-4 mb-2">
                  {children}
                </h6>
              ),
              blockquote: ({ node, children }) => (
                <blockquote className="border-l-4 border-gray-400 pl-4 italic">
                  {children}
                </blockquote>
              ),
              a: ({ node, children, ...props }) => (
                <a className="text-blue-500 underline" {...props}>
                  {children}
                </a>
              ),
              table: ({ node, children }) => (
                <table className="w-full text-left border-collapse">
                  {children}
                </table>
              ),
              thead: ({ node, children }) => (
                <thead className="bg-gray-200">{children}</thead>
              ),
              tbody: ({ node, children }) => <tbody>{children}</tbody>,
              tr: ({ node, children }) => (
                <tr className="border-b">{children}</tr>
              ),
              th: ({ node, children }) => (
                <th className="py-2 px-4 font-semibold">{children}</th>
              ),
              td: ({ node, children }) => (
                <td className="py-2 px-4">{children}</td>
              ),
              strong: ({ node, children }) => (
                <strong className="font-semibold">{children}</strong>
              ),
              em: ({ node, children }) => (
                <em className="italic">{children}</em>
              ),
              code: ({ node, children }) => (
                <code className="bg-gray-100 p-1 rounded">{children}</code>
              ),
              pre: ({ node, children }) => (
                <pre className="bg-gray-100 p-4 rounded overflow-auto">
                  {children}
                </pre>
              ),
              hr: ({ node }) => (
                <hr className="border-t border-gray-300 my-4" />
              ),
              br: ({ node }) => <br />,
            }}
          >
            {part}
          </ReactMarkdown>
        );
      }
      return part;
    });
  };

  return (
    <div className="inline-block">
      {renderMarkdownWithInputs(
        markdownContent,
        procedureData,
        onChangeProcedureData
      )}
    </div>
  );
};

export default MarkdownWithInputs;
