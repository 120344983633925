import React, { useEffect, useState } from "react";
import { Button, Input, Textarea } from "@nextui-org/react";
import {
  getAlldata,
  getDataWithFilterUrl,
  PublishData,
} from "../../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbsPage from "../../../components/BreadCrumbsPage";
import LoadingPage from "../../../components/Loader/LoadingPage";
import { DropDownInput } from "../../FormBuilder/InputField/InputField";
import useGetAllOrganization from "../../../hooks/useGetAllOrganization";

const DomainSchemaData = {
  id: 1719210950654,
  name: "Drop Down",
  type: "dropdown",
  title: "Choose Domain",
  values: [
    {
      id: 1719210950654,
      name: "Domain 1",
    },
  ],
  required: true,
  ismultiselect: false,
};

const PolicyCreate = () => {
  const [policyName, setPolicyName] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [DomainName, setDomainName] = useState("");

  const [errorMessage, setErrorMessage] = useState({});
  const [DomainList, setDomainList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();

  const { _orgId: currOrg } = useParams();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const ValidationHandler = () => {
    const newError = {};
    let isValid = true;
    if (policyName.trim() === "") {
      newError.policyName = "Policy Name is required";
      isValid = false;
    }
    if (DomainName.trim() === "") {
      newError[DomainSchemaData.title] = "Domain is required";
      isValid = false;
    }
    setErrorMessage(newError);

    return isValid;
  };
  const handleSubmit = async () => {
    if (!ValidationHandler()) {
      return;
    }
    const publishData = {
      Name: policyName,
      Description: descriptionText,
      Domain: DomainName,
      Organization: OrgInfo,
    };
    const result = await PublishData({ data: publishData }, "policies");
    navigator(`/${currOrg}/policies-builder`);
    return result;
  };

  useEffect(() => {
    getDataWithFilterUrl("domains")
      .then((data) => {
        let list = data.data;
        list = list
          .sort((a, b) => {
            const nameA = a?.attributes?.Name?.trim().toLowerCase() || "";
            const nameB = b?.attributes?.Name?.trim().toLowerCase() || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          ?.map((item) => {
            return {
              id: item.id,
              name: item?.attributes?.Name,
            };
          });

        setDomainList(list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  DomainSchemaData.values = DomainList || [];

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <div>
          <p className="text-3xl font-medium">Policy</p>
          <div className="my-8">
            Create or edit ESG Policies that would support your ESG Reporting.
          </div>
          <div className={`flex justify-end cursor-pointer`}>
            <Button color="danger" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          <div className="my-16 w-[50%]">
            <Input
              type="text"
              className="mb-8"
              isRequired
              label="Policy"
              placeholder={"Enter Policy Name"}
              variant="faded"
              labelPlacement="outside"
              value={policyName}
              onChange={(event) => setPolicyName(event.target.value)}
              isInvalid={
                errorMessage["policyName"] && errorMessage["policyName"] !== ""
              }
              errorMessage={errorMessage["policyName"]}
            />
            <Textarea
              className="mb-8"
              label="Description"
              placeholder={"Add about the policy here"}
              variant="faded"
              labelPlacement="outside"
              value={descriptionText}
              onChange={(event) => setDescriptionText(event.target.value)}
            />

            <DropDownInput
              data={DomainSchemaData}
              value={DomainName}
              onChangeHandler={(title, value, type) => setDomainName(value)}
              validationErrors={errorMessage}
            />
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default PolicyCreate;
