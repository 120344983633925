import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
  Textarea,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import { CategoryList, FieldList } from "../../utils/constant";
import { FaChevronDown } from "react-icons/fa";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import {
  fileTypes,
  FrameworkSelectfieldSchema,
  initalValidationError,
  intialFormData,
} from "../../constant/FormBuilder/modal.constant";
import FrameworkInputFieldAccodian from "../../components/Formbuilder/FrameworkInputFieldAccodian";
import { MultiSelectInput } from "./InputField/InputField";

const AddModal = ({
  onSubmitHandler,
  id,
  isOpen,
  onOpenChange,
  fieldType,
  frameWorkList,
  frameWorkDetails,
  category,
}) => {
  const [data, setData] = useState(intialFormData);
  const [relatedToList, setRelatedToList] = useState([]);
  const [frameWorkData, setFrameWorkData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    ...initalValidationError,
  });

  const validationHandler = () => {
    let isValid = true;
    const newValidationError = { ...initalValidationError };
    if (data?.title === "") {
      newValidationError["title"] = "Title can't be empty";
      isValid = false;
    }
    if (data?.relatedTo === "" && fieldType === "relation") {
      newValidationError["relatedTo"] = "relatedTo can't be empty";
      isValid = false;
    }
    if (data?.valuesList === "" && fieldType === "dropdown") {
      newValidationError["valueList"] = "valuesList can't be empty";
      isValid = false;
    }
    if (data?.metricName === "" && fieldType === "relation") {
      newValidationError["metricName"] = "metricName can't be empty";
      isValid = false;
    }
    if (data?.linkUrl === "" && fieldType === "link") {
      newValidationError["linkUrl"] = "Url can't be empty";
      isValid = false;
    }
    if (
      category?.toLowerCase() !== "reference data" &&
      frameWorkData?.length === 0
    ) {
      newValidationError["Framework"] = "select at least one framework";
      isValid = false;
    }
    setValidationErrors({ ...newValidationError });

    return isValid;
  };
  const submitHandler = (onClose) => {
    const isValid = validationHandler();
    if (isValid === false) return;
    const fieldInfo = {
      id: id,
      Title: data?.title,
      Required: data?.required,
      Reference: data?.referenceList,
      Overview: data?.overviewList,
      Guidance: data?.guidanceList,
      framework: frameWorkData,
    };
    if (fieldType === "text" || fieldType === "textarea") {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        name: FieldList[fieldType].title,
        char_limit: +data?.charLimit,
      });
    } else if (fieldType === "integer" || fieldType === "slider") {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        name: FieldList[fieldType].title,
        min_val: +data?.minValue,
        max_val: +data?.maxValue,
      });
    } else if (fieldType === "slider") {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        name: FieldList[fieldType].title,
        min_val: +data?.minValue,
        max_val: +data?.maxValue,
        step_val: data?.stepValue,
      });
    } else if (fieldType === "relation") {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        relatedTo: data?.relatedTo,
        metricName: data?.metricName,
        name: FieldList[fieldType].title,
        isMultiSelect: data?.isMultiSelect,
      });
    } else if (fieldType === "dropdown") {
      const values = data?.valuesList.split(",").map((item, index) => {
        return {
          id: index,
          name: item.trim(),
        };
      });

      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        values: values,
        name: FieldList[fieldType].title,
        isMultiSelect: data?.isMultiSelect,
      });
    } else if (fieldType === "link") {
      onSubmitHandler({
        ...fieldInfo,
        link: data?.linkUrl,
        Type: "link",
        name: "link",
      });
    } else if (fieldType === "file") {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        name: FieldList[fieldType].title,
        acceptFieldType: data?.acceptFieldType,
      });
    } else {
      onSubmitHandler({
        ...fieldInfo,
        Type: fieldType,
        name: FieldList[fieldType].title,
      });
    }

    onClose();
  };

  const handleKeyDown = (event, onClose) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of form submission on Enter key press
      submitHandler(onClose);
    }
  };

  useEffect(() => {
    if (data?.metricName === "") return;

    const filteredUrl = "filters[Category][$eqi]=" + data?.metricName.trim();

    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data;

        const list = response.map((item) => {
          return {
            id: item.id,
            name: item?.attributes?.Name,
          };
        });
        list.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setRelatedToList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data?.metricName]);

  useEffect(() => {
    setValidationErrors({ ...initalValidationError });
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="3xl"
        // scrollBehavior="inside"
        // className="custom-scrollbar"
        
      >
        <ModalContent>
          {(onClose) => (
            <div
              onKeyDown={(event) => handleKeyDown(event, onClose)}
              tabIndex="0"
              className="outline-none"
            >
              <ModalHeader className="flex flex-col gap-1">
                Add a {fieldType} Field
              </ModalHeader>
              <ModalBody>
                <div className="overflow-y-scroll max-h-[400px] hide-scollbar">
                  <Input
                    type="text"
                    className="mb-8"
                    isRequired
                    label={fieldType === "link" ? "Name" : "Title"}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={data?.title || ""}
                    onChange={(event) => {
                      setData({ ...data, title: event.target.value });
                    }}
                    isInvalid={validationErrors["title"] !== ""}
                    errorMessage={validationErrors["title"]}
                  />
                  {/* These input field for text and textarea Input Type  */}
                  {(fieldType === "text" || fieldType === "textarea") && (
                    <>
                      <Input
                        type="number"
                        className="mb-8"
                        label="Char Limit"
                        placeholder=" "
                        variant="faded"
                        labelPlacement="outside"
                        value={data?.charLimit}
                        onChange={(event) => {
                          setData({ ...data, charLimit: event.target.value });
                        }}
                      />
                    </>
                  )}
                  {/* These input field for Integer and Slider Input Type  */}
                  {(fieldType === "integer" || fieldType === "slider") && (
                    <>
                      <Input
                        type="number"
                        className="mb-8"
                        label="Min Value"
                        placeholder={data?.minValue}
                        variant="faded"
                        labelPlacement="outside"
                        value={data?.minValue}
                        onChange={(event) => {
                          setData({ ...data, minValue: event.target.value });
                        }}
                      />
                      <Input
                        type="number"
                        className="mb-8"
                        label="Max Value"
                        placeholder={data?.maxValue}
                        variant="faded"
                        labelPlacement="outside"
                        value={data?.maxValue}
                        onChange={(event) => {
                          setData({ ...data, maxValue: event.target.value });
                        }}
                      />
                    </>
                  )}
                  {/* These input field for Slider Input Type  */}
                  {fieldType === "slider" && (
                    <Input
                      type="text"
                      className="mb-8"
                      label="Step Value"
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={data?.stepValue}
                      onChange={(event) => {
                        setData({ ...data, stepValue: event.target.value });
                      }}
                    />
                  )}
                  {/* These input field for Relation Input Type  */}
                  {fieldType === "relation" && (
                    <>
                      <div className="flex flex-col gap-2 text-sm w-full">
                        <p
                          className={`${
                            validationErrors["metricName"] && "text-[#f76497]"
                          }`}
                        >
                          Category Name <span style={{ color: "red" }}>*</span>
                        </p>
                        {validationErrors["metricName"] && (
                          <p className="text-[#f76497] mt-0">
                            {validationErrors["metricName"]}
                          </p>
                        )}
                        <Dropdown>
                          <DropdownTrigger className="w-full">
                            <Button className="mb-8" variant="faded">
                              <div className="w-full flex justify-between">
                                <div className="flex justify-start w-full items-start">
                                  {data?.metricName}
                                </div>
                                <div className="flex justify-end w-full items-end">
                                  <FaChevronDown />
                                </div>
                              </div>
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Static Actions"
                            className="w-144 custom-dropdown-menu"
                            selectionMode="single"
                            name="categoryName"
                            onSelectionChange={(event) => {
                              setData({
                                ...data,
                                metricName: Array.from(event)
                                  .join(", ")
                                  .replaceAll("_", " "),
                                relatedTo: "",
                              });
                            }}
                          >
                            {CategoryList?.map((item) => (
                              <DropdownItem key={item.name}>
                                {item.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      {/* RelatedTo field DropDown  */}

                      <div className="flex flex-col gap-2 text-sm w-full">
                        <Select
                          label="Related To"
                          placeholder="Choose single/multiple field"
                          variant="faded"
                          selectionMode="multiple"
                          labelPlacement="outside"
                          className="my-8"
                          isRequired
                          // selectedKeys={relatedTo.join(',')}
                          onSelectionChange={(event) =>
                            setData({
                              ...data,
                              relatedTo: Array.from(event)
                                .join(",")
                                .replaceAll("_", " "),
                            })
                          }
                        >
                          {relatedToList?.map((item) => (
                            <SelectItem
                              key={item.name}
                              value={item.name}
                              className="py-2"
                            >
                              {item.name}
                            </SelectItem>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}

                  {/* These fields for specific DropDown Field  */}

                  {fieldType === "dropdown" && (
                    <>
                      <Textarea
                        className="mb-8"
                        label={"Enter Values Option by Space-Separate"}
                        key="1"
                        variant="faded"
                        labelPlacement="outside"
                        isRequired
                        value={data?.valuesList}
                        placeholder="value1, values2, values3"
                        onChange={(event) =>
                          setData({ ...data, valuesList: event.target.value })
                        }
                        isInvalid={validationErrors["valueList"] !== ""}
                        errorMessage={validationErrors["valueList"]}
                      />
                    </>
                  )}

                  {/* These fields for specific File Field  */}

                  {fieldType === "file" && (
                    <div className="flex flex-col gap-2 text-sm w-full">
                      <Select
                        label="Files Accepted"
                        placeholder=" "
                        variant="faded"
                        selectionMode="multiple"
                        labelPlacement="outside"
                        className="my-8"
                        isRequired
                        // selectedKeys={relatedTo.join(',')}
                        onSelectionChange={(event) => {
                          setData({
                            ...data,
                            acceptFieldType: Array.from(event),
                          });
                        }}
                      >
                        {fileTypes?.map((item) => (
                          <SelectItem
                            key={item.key}
                            value={item.name}
                            className="py-2"
                          >
                            {item.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  {/* These fields for specific Link Field  */}

                  {fieldType === "link" && (
                    <Input
                      type="text"
                      className="mb-8"
                      isRequired
                      label="URL"
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={data?.linkUrl || ""}
                      onChange={(event) => {
                        setData({ ...data, linkUrl: event.target.value });
                      }}
                      isInvalid={validationErrors["linkUrl"] !== ""}
                      errorMessage={validationErrors["linkUrl"]}
                      // onKeyDown={(event) => handleKeyDown(event, onClose)}
                    />
                  )}

                  {/* Framework Field */}
                  {category?.toLowerCase() !== "reference data" && (
                    <>
                      <div className="my-12">
                        <MultiSelectInput
                          data={FrameworkSelectfieldSchema}
                          value={frameWorkData}
                          valuesList={frameWorkList}
                          onChangeHandler={(title, value, type) =>
                            setFrameWorkData(value)
                          }
                          validationErrors={validationErrors}
                        />
                      </div>
                      {frameWorkData?.map((id) => (
                        <FrameworkInputFieldAccodian
                          id={+id}
                          data={data}
                          setData={setData}
                          frameWorkDetails={frameWorkDetails}
                        />
                      ))}
                    </>
                  )}

                  {fieldType !== "link" && (
                    <div className="flex items-center">
                      <div className="flex-1">
                        <p className="mb-2">{"Make Required"}</p>
                        <Switch
                          defaultSelected={data?.required}
                          color="primary"
                          className="mb-4"
                          label="Make Required"
                          labelPlacement="outside"
                          onChange={(event) =>
                            setData({ ...data, required: !data?.required })
                          }
                        >
                          {data?.required ? "On" : "Off"}
                        </Switch>
                      </div>
                      {(fieldType === "dropdown" ||
                        fieldType === "relation") && (
                        <div className="flex-1">
                          <p className="mb-2">{"Multi-Select"}</p>
                          <Switch
                            defaultSelected={data?.isMultiSelect}
                            color="primary"
                            className="mb-4"
                            // label="Make Required"
                            labelPlacement="outside"
                            onChange={(event) =>
                              setData({
                                ...data,
                                isMultiSelect: !data?.isMultiSelect,
                              })
                            }
                          >
                            {data?.isMultiSelect ? "Yes" : "No"}
                          </Switch>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => submitHandler(onClose)}
                  onKeyDown={(event) => handleKeyDown(event, onClose)}
                >
                  Add
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default AddModal;
