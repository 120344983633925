import React, { useEffect, useState } from "react";
import { Button, Input, Textarea } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {getAlldata, UpdateSingleCollectionData } from "../../controllers/APIController";

const Organization = () => {
  const Navigate = useNavigate();
  const [ValidationConditions, setValidationConditions] = useState(false);
  
  const [formData, setFormData] = useState({
    Name: "",
    Address: "",
    City: "",
    Postal: "",
  });
  const [FormDataError, setFormDataError] = useState({
    NameError: "",
    AddressError: "",
    CityError: "",
    PostalError: "",
  });

  const CollectionName = "tenant";

  useEffect(() => {
    getAlldata(CollectionName)
      .then((data) => {
        if (data.data !== null) {
          setFormData(data.data.attributes);
        }
      })
      .catch((err) => console.log("error", err));
  }, [CollectionName]);

  const Validation = () => {
    let isValid = true;

    if (!formData.Name) {
      isValid = false;
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        NameError: "Organization name required.",
      }));
    } else {
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        NameError: null,
      }));
    }

    if (!formData.Address) {
      isValid = false;
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        AddressError: "Address is required.",
      }));
    } else {
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        AddressError: null,
      }));
    }

    if (!formData.City) {
      isValid = false;
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        CityError: "City is required.",
      }));
    } else {
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        CityError: null,
      }));
    }

    if (!formData.Postal) {
      isValid = false;
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        PostalError: "Postal code is required.",
      }));
    } else {
      setFormDataError((prevValidations) => ({
        ...prevValidations,
        PostalError: null,
      }));
    }

    return isValid;
  };

  const SaveChangesHandler = async () => {
    const isValid = Validation();

    if (isValid) {
      setValidationConditions(false);
      const response = await UpdateSingleCollectionData("tenant", formData);
      // Handle success or redirect as needed
    } else {
      setValidationConditions(true);
    }
  };

  return (
    <div>
      <p className="mt-8">
        Modify and manage your organisation information, approval process and
        reporting standards
      </p>
      {/* <p className="my-8">
        Modify and manage your organisation information, approval process and
        reporting standards
      </p> */}
      <p className="font-semibold my-8">Organisation Information</p>

      <div className="flex justify-between ">
        <div className="">
          <p>Name of your Organisation</p>
          <Input
            labelText="Name of your Organisation"
            className="w-64 mt-4"
            invalid={ValidationConditions && !formData.Name}
            invalidText={FormDataError.NameError}
            value={formData.Name}
            onChange={(e) => setFormData({ ...formData, Name: e.target.value })}
          />
        </div>
        <div className="">
          <p>Address</p>
          <Textarea
            labelText="Address"
            className=" w-96 mt-4"
            invalid={ValidationConditions && !formData.Address}
            invalidText={FormDataError.AddressError}
            value={formData.Address}
            onChange={(e) =>
              setFormData({ ...formData, Address: e.target.value })
            }
          />
        </div>

        <div className="">
          <p>City</p>
          <Input
            labelText="City"
            className="mt-4 w-64 "
            invalid={ValidationConditions && !formData.City}
            invalidText={FormDataError.CityError}
            value={formData.City}
            onChange={(e) => setFormData({ ...formData, City: e.target.value })}
          />
          <p className=" mt-10">Postal code</p>
          <Input
            labelText="Postal Code"
            value={formData.Postal}
            className="w-64"
            invalid={ValidationConditions && !formData.Postal}
            invalidText={FormDataError.PostalError}
            onChange={(e) =>
              setFormData({ ...formData, Postal: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex justify-center mt-20 ">
        <Button
          className=" bg-blue-500 text-white"
          onClick={SaveChangesHandler}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default Organization;
