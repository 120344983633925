import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreateLibraryForm from "./CreateLibraryForm";
import CreateFactorMapping from "./CreateFactorMapping";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

const CreateLibrary = () => {
  const navigator = useNavigate();
  const { pathname } = useLocation();

  const { _orgId: currOrg } = useParams();
 
  
  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Create Library</div>
        <div className="mt-8">
          <Tabs variant="light">
            <Tab key="general" title="General">
              <CreateLibraryForm />
            </Tab>
            <Tab key="factor mapping" title="Factors mapping">
              <CreateFactorMapping />
            </Tab>
          </Tabs>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default CreateLibrary;
