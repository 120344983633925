import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";
import React from "react";

import DrawerBody from "./DrawerBody";
import DrawerHeader from "./DrawerHeader";

const UserDrawer = ({ User,OrgInfo, isOpen, onOpenChange }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="absolute top-0 right-0 !m-0 h-screen z-100"
        backdrop="transparent"
        hideCloseButton={true}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col items-center ">
                <DrawerHeader User={User} onOpenChange={onOpenChange}/>
                <Divider className="mt-2" />
              </ModalHeader>
              <ModalBody className="gap-0">
                <DrawerBody User={User} OrgInfo={OrgInfo}/>
              </ModalBody>
              {/* Your buttons for submitting or canceling feedback can go here */}
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserDrawer;
