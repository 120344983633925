import { Tooltip } from "@nextui-org/react";
import React from "react";

const InfoToolTip = ({ data, w = "24px" }) => {
  return (
    <Tooltip
      content={data}
      placement="right"
      className="flex items-center cursor-pointer"
    >
      <lord-icon
        src="https://cdn.lordicon.com/yxczfiyc.json"
        style={{ width: w, height: w, cursor: "pointer" }}
        trigger="hover"
        colors="primary:#8492a6"
      ></lord-icon>
    </Tooltip>
  );
};

export default InfoToolTip;
