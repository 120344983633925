import { useLocation, useNavigate } from "react-router-dom";
import {
  PublishData,
  UpdateSingleCollectionData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
} from "../../controllers/APIController";
import {
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Input,
  Slider,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { DateFormator } from "../../utils/helper";
import LoadingPage from "../../components/Loader/LoadingPage";

const getInitailState = (arr) => {
  const data = {};
  console.log("Arrr->>>>>>>", arr);

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    } else if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  console.log(data);
  return data;
};

const OnBoardingForm = () => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let paramsList = pathname.split("/");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;

  console.log("UserID-----------", UserId);

  let HeadingTitle = capitalize(separateDasFromUrl(paramsList[2]));
  let description = CollectionInfo?.attributes?.Description;
  console.log(description);
  const requiredArr = CollectionInfo?.attributes?.JsonSchema?.required;

  const filteredUrl =
    "filters[Category][$eqi]=" +
    "onboarding" +
    "&filters[Name][$eqi]=" +
    "onboarding";
  const changeHandler = async (title, value, type) => {
    console.log(title, value);

    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  console.log("formdata------->", formdata);

  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};
    console.log(requiredArr, formSchema);
    formSchema.forEach((row) => {
      console.log(row);
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];
      console.log(formdata, fieldValue, fieldType, isRequired, fieldName);
      // You can add more validation rules based on the field type

      if ((fieldType === "text" || fieldType === "textarea") && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
          console.log(errors);
        }
      }
      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType === "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }
      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }
      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }
    });

    console.log(errors);
    setValidationErrors(errors);
    return isValid;
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      setErrorMessage("Something went wrong!!");
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    console.log(formdata);

    const publishData = {
      isFilled: true,
      JsonSchema: { ...formdata },
    };
    console.log(publishData);
    let result = await UpdateSingleCollectionData("onboarding", publishData);

    console.log(result);
    navigate("/");
  };

  console.log(formdata && formdata, CollectionInfo);

  useEffect(() => {
    console.log(filteredUrl);
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        console.log(data);
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        console.log(responseData);
        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        console.log(getInitailState(responseArr));
        setFormData(getInitailState(responseArr));
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(formdata);
  console.log("requiredArr", CollectionInfo, formSchema, validationErrors);
  console.log("Link", links);

  return (
    <div className="p-20 max-w-3xl m-auto">
      <div className="flex justify-center p-10">
        <Image width={100} alt="NextUI hero Image" src="/Assets/logo.svg" />
      </div>

      <LoadingPage isLoading={isLoading}>
        <div className="flex gap-[160px]">
          <div className="w-full">
            {formSchema &&
              formSchema.map((row, index) => {
                const fieldError = validationErrors[row[0]];
                const isRequired = requiredArr.includes(row[0]);

                console.log(fieldError, isRequired);
                if (row[1].type === "string" || row[1].type === "text") {
                  console.log(row[1]?.char_limit);
                  return (
                    <Input
                      type="text"
                      className="mb-16"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      maxLength={row[1]?.char_limit || 100}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "password") {
                  return (
                    <Input
                      type="password"
                      className="mb-16"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "integer") {
                  return (
                    <Input
                      type="number"
                      className="mb-16"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      min={row[1]?.min_val}
                      max={row[1]?.max_val}
                      placeholder="0"
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], +event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "dropdown") {
                  console.log(
                    row[1].ismultiselect ? "multiple" : "single",
                    new Set([formdata[row[0]]])
                  );
                  return (
                    <div className="flex flex-col gap-2 text-sm w-full">
                      <p>
                        {row[1].title}
                        {requiredArr.includes(row[0]) && (
                          <span style={{ color: "#f76497" }}> *</span>
                        )}
                      </p>
                      <Dropdown>
                        <DropdownTrigger
                          className="w-full"
                          isInvalid={fieldError && isRequired}
                          errorMessage={fieldError}
                        >
                          <Button className="mb-16" variant="faded">
                            <div className="w-full flex justify-between">
                              <div className="flex justify-start w-full items-start">
                                {formdata[row[0]] === ""
                                  ? ""
                                  : formdata[row[0]]}
                              </div>
                              <div className="flex justify-end w-full items-end">
                                <FaChevronDown />
                              </div>
                            </div>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Static Actions"
                          className="w-144 custom-dropdown-menu"
                          selectionMode={
                            row[1]?.ismultiselect ? "multiple" : "single"
                          }
                          closeOnSelect={row[1].ismultiselect ? false : true}
                          onSelectionChange={(event) => {
                            console.log(row[1]?.ismultiselect);
                            changeHandler(
                              row[0],
                              Array.from(event).join(", ").replaceAll("_", " "),
                              row[1].type
                            );
                          }}
                        >
                          {row[1]?.values?.map((item) => (
                            <DropdownItem key={item.name}>
                              {item.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                }
                if (row[1].type === "slider") {
                  return (
                    <Slider
                      label={row[1].title}
                      isRequired={requiredArr.includes(row[0])}
                      className="mb-16"
                      maxValue={row[1]?.max_val || 100}
                      minValue={row[1]?.min_val || 0}
                      step={row[1]?.step_val || 1}
                      // defaultValue={0}
                      onChange={(value) => {
                        changeHandler([row[0]], value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "boolean") {
                  console.log(formdata);
                  return (
                    <div className="flex items-center justify-between gap-4 mb-16">
                      <p className="">{row[1].title}</p>
                      <Switch
                        defaultSelected={formdata[row[0]] === "true"}
                        color="primary"
                        className=""
                        // label={row[1].title}
                        labelPlacement="outside"
                        onChange={(event) =>
                          changeHandler(
                            row[0],
                            event.target.checked.toString(),
                            row[1].type
                          )
                        }
                      >
                        {/* {formdata[row[0]] === "true" ? "On" : "Off"} */}
                      </Switch>
                    </div>
                  );
                }
                if (row[1].type === "textarea") {
                  return (
                    <Textarea
                      className="mb-16"
                      label={row[1].title}
                      key="1"
                      isRequired={requiredArr.includes(row[0])}
                      variant="faded"
                      labelPlacement="outside"
                      value={formdata[row[0]]}
                      maxLength={row[1]?.char_limit || 100}
                      placeholder=" "
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "date") {
                  console.log(row[1].title);
                  return (
                    <>
                      <DatePicker
                        label={row[1].title}
                        labelPlacement="outside"
                        className="mb-16"
                        isRequired={requiredArr.includes(row[0])}
                        onChange={(value) => {
                          changeHandler(
                            row[0],
                            DateFormator(value),
                            row[1].type
                          );
                        }}
                        isInvalid={fieldError && isRequired}
                        errorMessage={fieldError}
                      />
                    </>
                  );
                }
              })}
          </div>
        </div>

        <div className="flex justify-end">
          <Button className="mt-10" color="danger" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </LoadingPage>
    </div>
  );
};

export default OnBoardingForm;
