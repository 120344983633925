import React, { useEffect, useState } from "react";
import { DropDownInput, TextInput } from "../FormBuilder/InputField/InputField";
import { OnboardingOrgDataSchema } from "../../constant/OnboardingOrganization/OnboardingOrganization";
import { getAlldata } from "../../controllers/APIController";
import { Button } from "@nextui-org/react";
import { ProgessColors } from "../../utils/constant";

const OrgBasicInfo = ({
  DataList,
  setDataList,
  TitleText,
  DescriptionText,
  currentPage,
  setCurrrentPage,
  totalStep,
  allUrlList,
}) => {
  let schemaData = OnboardingOrgDataSchema;

  const OrgUrlValue = DataList["Name"]?.toLowerCase()?.replaceAll(" ", "-");
  const [allSectorName, setAllSectorName] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});

  const ValidationHandler = () => {
    const orgNameRegex = /[^a-zA-Z0-9 _-]/;
    const OrgName = DataList?.Name;
    const OrgUrlValue = OrgName?.toLowerCase()?.replaceAll(" ", "-");
    const OrgRegion = DataList["Choose Your Region"];
    const OrgSector = DataList["Choose Industry Sector"];

    const err = {};
    let isValid = true;
    setValidationErrors({});

    if (OrgName?.trim() === "") {
      err["Name"] = "Name is required!";
      isValid = false;
    }
    if (OrgRegion?.trim() === "") {
      err["Choose Your Region"] = "Region is required!";
      isValid = false;
    }
    if (OrgSector?.trim() === "") {
      err["Choose Industry Sector"] = "Sector is required!";
      isValid = false;
    }
    if (allUrlList?.includes(OrgUrlValue)) {
      err["URL"] = "This organization already exists!";
      isValid = false;
    }

    if (orgNameRegex.test(OrgName)) {
      err["Name"] =
        "Organization Name don't contain specail character except space, _ , - ";
      isValid = false;
    }

    console.log(err);
    setValidationErrors(err);
    return isValid;
  };

  const handleNext = () => {
    if (ValidationHandler()) {
      setCurrrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    getAlldata("sectors")
      .then((data) => {
        const list = data?.data;
        console.log(data);

        const valuesList = list?.map((item) => {
          return {
            key: item?.attributes?.Name?.toLowerCase(),
            name: item?.attributes?.Name,
          };
        });
        console.log(valuesList);

        setAllSectorName(valuesList);
      })
      .catch((err) => console.log(err));
  }, []);

  
  return (
    <>
      <div className="flex justify-center items-center mt-8 font-semibold text-2xl">
        {TitleText || "Create an organization"}
      </div>
      <div className="w-full flex justify-center">
        <div className="mt-8  w-[650px]">
          {DescriptionText ||
            `Create an organization to embark on your ESG (Environmental, Social,
          and Governance) reporting journey. Begin evaluating and improving your
          sustainability and ethical practices today.`}
        </div>
      </div>
      <div className="flex flex-col mx-auto mt-8 min-h-[360px] w-[500px]">
        <TextInput
          data={schemaData["Name"]}
          value={DataList["Name"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) => {
            setDataList({ ...DataList, [title]: value });
          }}
          cssStyle="mb-4"
        />
        <TextInput
          data={schemaData["URL"]}
          value={OrgUrlValue}
          validationErrors={validationErrors}
          onChangeHandler={1}
          cssStyle="mb-4"
        />
        <DropDownInput
          data={schemaData["Choose Your Region"]}
          value={DataList["Choose Your Region"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setDataList({ ...DataList, [title]: value })
          }
          buttonCSS="mb-4"
        />

        <DropDownInput
          data={{
            ...schemaData["Choose Industry Sector"],
            values: allSectorName,
          }}
          value={DataList["Choose Industry Sector"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setDataList({ ...DataList, [title]: value })
          }
          buttonCSS="mb-4"
        />
      </div>

      <div className={`w-full flex items-center my-2 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default OrgBasicInfo;
