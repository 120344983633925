import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip,
  Avatar,
  useDisclosure,
} from "@nextui-org/react";

import {
  getAlldata,
  getDataWithFilterUrl,
  getFilterDataWithPagination,
} from "../../controllers/APIController";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import LoadingPage from "../../components/Loader/LoadingPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import InviteModal from "./InviteModal";
import UserDrawer from "./UserDrawer/UserDrawer";

const UserManagement = () => {
  const { _orgId: currOrg } = useParams();

  const [contributionCount, setContributionCount] = useState({});
  const [UserData, setUserData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [invitationList, setInvitationList] = useState([]);
  const [drawerUser, setDrawerUser] = useState(null);
  const navigator = useNavigate();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onOpenChange: onDrawerOpenChange,
  } = useDisclosure();
  const numColors = Colors.length;

  useEffect(() => {
    setLoader(true);
    getAlldata("users")
      .then((data) => {
        const orgUserList = data?.filter((user) => {
          const list = user?.OrganizationLists;
          let isMemberOfCurrOrg = false;
          list.forEach((element) => {
            if (element.id === OrgInfo) isMemberOfCurrOrg = true;
          });
          return isMemberOfCurrOrg;
        });
        setUserData(orgUserList);
        setLoader(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const list = {};
    const result = UserData?.map(async (item) => {
      const url =
        orgFilterUrl + "&filters[users_permissions_user][id][$eqi]=" + item?.id;
      const response = await getFilterDataWithPagination(
        "responses",
        url,
        1,
        1
      );
      list[item.id] = response?.meta?.pagination?.total || 0;
      return response;
    });

    Promise.all(result).then(() => {
      setContributionCount(list);
    });
  }, [UserData]);

  useEffect(() => {
    getDataWithFilterUrl(
      "invitations",
      `filters[organization][id][$eqi]=${OrgInfo}&filters[Status][$eqi]=Pending`
    ).then((data) => {
      const emailList = data?.data?.map((item) => item?.attributes.email);
      console.log(emailList);
      setInvitationList(emailList);
    });
  }, []);

  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Members</div>
        <div className="mt-4 leading-relaxed">
          Here are the users that are contributing to your space. You can invite
          other users to your space to contribute to your ESG reporting.
          <br></br>
          To view and manage account creation requests click{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => navigator(`/${currOrg}/invite-request`)}
          >
            here
          </span>
          .
        </div>
        <div className="flex mt-4 justify-end">
          <Button color="danger" onPress={onModalOpen}>
            Invite your team
          </Button>
        </div>
        <LoadingPage isLoading={Loader} alterElement={<TableSkeleton />}>
          {UserData.length > 0 ? (
            <div className="pt-8">
              <Table
                removeWrapper
                aria-label="Example static collection table"
                selectionMode="single"
              >
                <TableHeader>
                  <TableColumn>Name</TableColumn>
                  <TableColumn>Contributions</TableColumn>
                  <TableColumn>Status</TableColumn>
                </TableHeader>
                <TableBody>
                  {UserData && UserData.length > 0
                    ? UserData &&
                      UserData.map((user, index) => {
                        const colorIndex = user.id % numColors;

                        return (
                          <TableRow
                            key="1"
                            onClick={() => {
                              setDrawerUser(user);
                              onDrawerOpen();
                            }}
                            className="cursor-pointer"
                          >
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Avatar
                                  src={`${
                                    user.ProfilePicture &&
                                    user.ProfilePicture.data !== null
                                      ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.url}`
                                      : ""
                                  }`}
                                  color={Colors[colorIndex]}
                                  name={
                                    user &&
                                    user.email &&
                                    user.email.slice(0, 1).toUpperCase()
                                  }
                                  size="sm"
                                />
                                <div className="flex flex-col ">
                                  <p className="">
                                    {user.email && user.email.split("@")[0]}
                                  </p>
                                  <p className="text-slate-400">
                                    {user.username}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              {contributionCount[user?.id] === undefined
                                ? "--"
                                : contributionCount[user?.id]}
                            </TableCell>
                            <TableCell>
                              <Chip variant="flat" color="success">
                                Active
                              </Chip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </div>
          ) : (
            <NullData />
          )}
        </LoadingPage>

        <InviteModal
          UserData={UserData}
          isOpen={isModalOpen}
          onOpen={onModalOpen}
          onOpenChange={onModalOpenChange}
          invitationList={invitationList}
        />
        <UserDrawer
          OrgInfo={OrgInfo}
          User={drawerUser}
          isOpen={isDrawerOpen}
          onOpen={onDrawerOpen}
          onOpenChange={onDrawerOpenChange}
        />
      </div>
    </BreadCrumbsPage>
  );
};

export default UserManagement;
