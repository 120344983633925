import Signin from "./pages/Signin/Signin";
import GoogleRedirect from "./pages/Signin/GoogleRedirect";
import Navbar from "./components/Navbar/Navbar.jsx";
import GithubRedirect from "./pages/Signin/GithubRedirect";
import ResetPassword from "./pages/Signin/ResetPassword";
import Framework from "./pages/Frameworks/Frameworks.jsx";
import Onboarding from "./pages/Onboarding/Onboarding.jsx";
import NotFound from "./components/ErrorPage/NotFound.jsx";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile.jsx";
import Settings from "./pages/Settings/Settings.jsx";
import Connectors from "./pages/Connectors/Connectors.jsx";
import AudytLLM from "./pages/AudytLLM/AudytLLM.jsx";
import Analytics from "./pages/Analytics/Analytics.jsx";
import Evidences from "./pages/Evidences/Evidences.jsx";
import Library from "./pages/Library/Library.jsx";
import Metrics from "./pages/Metrics/Metrics.jsx";
import ReferenceData from "./pages/ReferenceData/ReferenceData.jsx";
import UserManagement from "./pages/UserManagement/UserManagement.jsx";
import Report from "./pages/Report/Report.jsx";
import { FormBuilder } from "./pages/FormBuilder/FormBuilder.jsx";
import PasswordlessSignIn from "./pages/Signin/PasswordlessSignIn.jsx";
import VerifyLogin from "./pages/Signin/VerifyLogin.jsx";
import Form from "./pages/Connectors/Form.jsx";
import ViewFramework from "./pages/Frameworks/ViewFramework.jsx";
import ThankYou from "./pages/Onboarding/ThankYou.jsx";
import SingleTypemetrics from "./pages/Metrics/SingleTypemetrics.jsx";
import CreateMetricForm from "./pages/Metrics/CreateMetricForm.jsx";
import EditMetricForm from "./pages/Metrics/EditMetricForm.jsx";
import Survey from "./pages/External/Survey.jsx";
import Done from "./pages/External/Done.jsx";
import Playground from "./pages/Playground/Playground.jsx";
import { EditFormBuilder } from "./pages/FormBuilder/EditFormBuilder.jsx";
import Policies from "./pages/Policies/Policies.jsx";
import CreateLibrary from "./pages/Library/CreateLibrary.jsx";
import EditLibrary from "./pages/Library/EditLibrary.jsx";
import PoliciesBuilder from "./pages/PoliciesBuilder/PoliciesBuilder.jsx";
import PoliciesCreateBuilder from "./pages/PoliciesBuilder/PoliciesCreateBuilder.jsx";
import PoliciesEditorBuilder from "./pages/PoliciesBuilder/PoliciesEditBuilder.jsx";
import PoliciesEditBuilder from "./pages/PoliciesBuilder/PoliciesEditBuilder.jsx";
import OnBoardingForm from "./pages/Onboarding/OnBoardingForm.jsx";
import SearchPage from "./pages/Search/SearchPage.jsx";
import RequestPage from "./components/ErrorPage/RequestPage.jsx";
import InviteRequest from "./pages/InviteRequest/InviteRequest.jsx";
import NotAccess from "./components/ErrorPage/NotAccess.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import SinglePolicy from "./pages/Policies/SinglePolicy.jsx";
import Procedures from "./pages/Policies/Procedures.jsx";
import DomainCreate from "./pages/PoliciesBuilder/Domain/DomainCreate.jsx";
import PolicyCreate from "./pages/PoliciesBuilder/Policy/PolicyCreate.jsx";
import ProcedureCreate from "./pages/PoliciesBuilder/Procedure/ProcedureCreate.jsx";
import CreateFramework from "./pages/Frameworks/CreateFramework.jsx";
import EditFramework from "./pages/Frameworks/EditFramework.jsx";
import MetricCategroy from "./pages/Metrics/MetricCategroy.jsx";
import SingleReferenceData from "./pages/ReferenceData/SingleReferenceData.jsx";
import CreateReferenceData from "./pages/ReferenceData/CreateReferenceData.jsx";
import EditReferenceData from "./pages/ReferenceData/EditReferenceData.jsx";
import Logs from "./pages/Logs/Logs.jsx";
import OnboardingOrganization from "./pages/OnboardingOrg/OnboardingOrganization.jsx";
import DataImport from "./pages/DataImport/DataImport.jsx";
import OrgList from "./pages/OrgList/OrgList.jsx";
import SurveyPlayground from "./pages/SuveryPlayground/SurveyPlayground.jsx";
import { EditSurveyBuilder } from "./pages/survey-builder/EditSurveyBuilder.jsx";
import { SurveyBuilder } from "./pages/survey-builder/SurveyBuilder.jsx";
import DefaultFrameworkView from "./components/DefaultFrameworkView.jsx";
import SetNewPassword from "./pages/Signin/SetNewPassword.jsx";
import ViewFrameworkMetric from "./pages/Frameworks/ViewFrameworkMetric.jsx";
import Signup from "./pages/Signin/Signup.jsx";
import OTP from "./pages/Signin/OTP.jsx";
import { Navigate } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage.jsx";
const ComponentList = {
  Signin: Signin,
  GoogleRedirect,
  Navbar,
  GithubRedirect,
  ResetPassword,
  Framework,
  Onboarding,
  NotFound,
  Dashboard,
  Profile,
  Settings,
  Connectors,
  AudytLLM,
  Analytics,
  Evidences,
  Library,
  Metrics,
  ReferenceData,
  UserManagement,
  Report,
  FormBuilder,
  PasswordlessSignIn,
  VerifyLogin,
  Form,
  ViewFramework,
  ThankYou,
  SingleTypemetrics,
  CreateMetricForm,
  EditMetricForm,
  Survey,
  Done,
  Playground,
  EditFormBuilder,
  Policies,
  CreateLibrary,
  EditLibrary,
  PoliciesBuilder,
  PoliciesCreateBuilder,
  PoliciesEditorBuilder,
  PoliciesEditBuilder,
  OnBoardingForm,
  SearchPage,
  RequestPage,
  InviteRequest,
  NotAccess,
  ProtectedRoute,
  SinglePolicy,
  Procedures,
  DomainCreate,
  PolicyCreate,
  ProcedureCreate,
  CreateFramework,
  EditFramework,
  MetricCategroy,
  SingleReferenceData,
  CreateReferenceData,
  EditReferenceData,
  Logs,
  OnboardingOrganization,
  DataImport,
  OrgList,
  SurveyPlayground,
  EditSurveyBuilder,
  SurveyBuilder,
  DefaultFrameworkView,
  SetNewPassword,
  ViewFrameworkMetric,
  Signup,
  OTP,
  Navigate,
  MainPage
};

const getComponent = (name) => {
  return ComponentList[name] || NotFound;
};

export default getComponent;
