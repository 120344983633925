import React, { useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "@nextui-org/react";
import { FaFileAlt, FaChevronDown } from "react-icons/fa";
import { Tooltip } from "@nextui-org/react";
import CodeMirror from "react-codemirror";
import { JSonResponseInfoContent } from "../../utils/constant";
import { useParams } from "react-router-dom";


function Form() {

  const initialFormData = {
    metricName: "",
    categoryName: "",
    className: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");
  const [frameWorkData, setFrameWorkData] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);

  const JSonResponseInfoContentComp = () => {
    return (
      <pre className="text-xs font-semibold ">
        {JSON.stringify(JSonResponseInfoContent, null, 2)}
      </pre>
    );
  };
  const { _orgId: currOrg } = useParams();
  

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };
  const handleFrameChange = (editor, data, value) => {
    setFrameWorkData(value);
  };

 

  return (
    <div className=" ">
      <div className="text-3xl font-medium">Create a new Connector</div>
      <div className="flex">
        <div className="">
          <p className="mt-5 mb-5">
            Add your applications to your knowledge base
          </p>
          <p className="mb-3">Select Applications</p>
          <div className="flex justify-between">
            <Dropdown>
              <DropdownTrigger>
                <Button className="w-96" variant="bordered">
                  <div className="w-full flex justify-between">
                    <div className="flex justify-start w-full items-start">
                      <FaFileAlt />
                    </div>
                    <div className="flex justify-end w-full items-end">
                      <FaChevronDown />
                    </div>
                  </div>
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions" className="w-96">
                <DropdownItem key="new">New file</DropdownItem>
                <DropdownItem key="copy">Copy link</DropdownItem>
                <DropdownItem key="edit">Edit file</DropdownItem>
                <DropdownItem
                  key="delete"
                  className="text-danger"
                  color="danger"
                >
                  Delete file
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <p className="mb-2 mt-4">Connect Collections</p>

          <Dropdown>
            <DropdownTrigger>
              <Button className="w-96" variant="bordered">
                <div className="w-full flex justify-between">
                  <div className="flex justify-end w-full items-end">
                    <FaChevronDown />
                  </div>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" className="w-96">
              <DropdownItem key="new">New file</DropdownItem>
              <DropdownItem key="copy">Copy link</DropdownItem>
              <DropdownItem key="edit">Edit file</DropdownItem>
              <DropdownItem key="delete" className="text-danger" color="danger">
                Delete file
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <hr className=" mb-5 mt-10 w-96 text-grey-500" />
          <p>Client ID</p>
          <Input
            isRequired
            type="email"
            defaultValue="abc@gmail.com"
            className="max-w-xs mt-4"
          />
          <p className="mt-4">Client Secret</p>
          <Input
            isRequired
            type="email"
            defaultValue="abc@gmail.com"
            className="max-w-xs mt-4"
          />
          <hr className=" mb-5 mt-10 w-96 text-grey-500" />
          <p className="text-lg font-medium">Fine tune your connector</p>
          <p className="mt-10">
            Fine tune your connector by providing haya some context and tags to
            your documents . This will ensure that there are only necessary
            documents coming inside your knowledge base.
          </p>
          <p className="mt-4">Tags</p>
          <Input
            isRequired
            type="email"
            defaultValue="abc@gmail.com"
            className="max-w-xs mt-4"
          />
          <div className="flex gap-10 mt-5">
            <Button className="bg-transparent font-semibold">Cancel</Button>
            <Button className=" bg-yellow font-semibold">
              Create Connector
            </Button>
          </div>
        </div>
        <div className="  ">
          <Button className="bg-red-500 text-white ml-52">
            Test Connector
          </Button>
          <p className="flex items-center gap-1 mt-5  text-gray text-sm mb-2">
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "10px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["JsonSchema"] && validationErrors["JsonSchema"]}
            </p>
          </p>

          <div className=" bg-purple">
            <CodeMirror
              value={jsonData}
              options={{
                mode: "javascript",
                theme: "material",
                lineNumbers: true,
                tabSize: 2,
                extraKeys: { "Ctrl-Space": "autocomplete" },
              }}
              onBeforeChange={handleJsonChange}

              //   onKeyDown={handleKeyDown} // Handle key down event
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
