import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { PoliciesCategoryList } from "../../constant/Policies/policyConstant";
import { useEffect } from "react";
import {
  UpdateOneData,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import MarkdownEditor from "../Dashboard/MarkdownEditor";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

const PoliciesEditBuilder = () => {
  const [isLoading, setisLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [metricValueList, setMetricValueList] = useState("");
  const [markDownContent, setMarkDownContent] = useState("");

  const { _responseId, _orgId: currOrg } = useParams();

  const [metricFormList, setMetricFormList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const navigate = useNavigate();
  const validationHandler = () => {
    let isValid = true;
    const errorMessage = {};
    if (policyName.trim() === "") {
      errorMessage["Policy Name"] = "Policy Name is required!";
      isValid = false;
    }
    if (categoryName.trim() === "") {
      errorMessage["Policy Category"] = "Policy Category is required!";
      isValid = false;
    }
    setValidationErrors(errorMessage);

    return isValid;
  };
  const submitHandler = async () => {
    const isValid = validationHandler();
    if (!isValid) {
      return;
    }

    const publishData = {
      PolicyName: policyName,
      CategoryName: categoryName,
      MetricList: metricValueList,
      MarkDownText: markDownContent,
      Description: policyDescription,
    };

    let result = await UpdateOneData("policies", _responseId, publishData);
    navigate(`/${currOrg}/policies-builder`);
  };
  //   const data = {};

  useEffect(() => {
    getDataWithFilterUrl("metrics")
      .then((data) => {
        let list = data.data;
        list.sort((a, b) => {
          if (
            a?.attributes?.Name.trim().toLowerCase() <
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return -1;
          }
          if (
            a?.attributes?.Name.trim().toLowerCase() >
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

        list = list.map((item) => {
          return {
            id: item.id,
            title: item?.attributes?.Name,
          };
        });
        setMetricFormList(list);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setisLoading(true);
    const filteredUrl = `${orgFilterUrl}&filters[id][$eqi]=${_responseId}`;
    getDataWithFilterUrl("policies", filteredUrl)
      .then((data) => {
        const response = data.data[0];
        setPolicyName(response?.attributes?.PolicyName);
        setCategoryName(response?.attributes?.CategoryName);
        setPolicyDescription(response?.attributes?.Description);
        setMarkDownContent(response?.attributes?.MarkDownText);
        setMetricValueList(response?.attributes?.MetricList);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <BreadCrumbsPage>
      <div>
        <p className="text-3xl font-medium">Edit Policy</p>
        <div className="my-16 w-[50%]">
          <Input
            type="text"
            className="mb-8"
            isRequired
            label={"Policy Name"}
            placeholder={" "}
            variant="faded"
            labelPlacement="outside"
            value={policyName}
            onChange={(event) => {
              setPolicyName(event.target.value);
            }}
            isInvalid={validationErrors["Policy Name"]}
            errorMessage={validationErrors["Policy Name"]}
          />

          {/* Category DropDown */}

          <div className="flex flex-col gap-2 text-sm w-full">
            <p>
              Policy Category <span style={{ color: "red" }}>*</span>
            </p>
            {validationErrors["Policy Category"] && (
              <p className="text-[#f76497]">
                {validationErrors["Policy Category"]}
              </p>
            )}
            <Dropdown>
              <DropdownTrigger
                isInvalid={validationErrors["Policy Category"]}
                errorMessage={
                  validationErrors["Policy Category"] &&
                  validationErrors["Policy Category"]
                }
                className="w-full"
              >
                <Button className="mb-8" variant="faded">
                  <div className="w-full flex justify-between">
                    <div className="flex justify-start w-full items-start">
                      {categoryName === "" ? "" : categoryName}
                    </div>
                    <div className="flex justify-end w-full items-end">
                      <FaChevronDown />
                    </div>
                  </div>
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                className="w-144"
                selectionMode="single"
                name="categoryName"
                onSelectionChange={(event) => {
                  setCategoryName(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                }}
              >
                {PoliciesCategoryList?.map((item) => (
                  <DropdownItem key={item.key}>{item.title}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          {/* Form-Metric Choosen Input */}

          <Select
            label="Select Metric"
            placeholder="Select any Metrics"
            variant="faded"
            selectionMode="multiple"
            labelPlacement="outside"
            className="my-8"
            onSelectionChange={(event) =>
              setMetricValueList(
                Array.from(event).join(",").replaceAll("_", " ")
              )
            }
            selectedKeys={new Set(metricValueList?.split(","))}
          >
            {metricFormList?.map((item) => (
              <SelectItem key={item.id} value={item.title} className="py-2">
                {item.title}
              </SelectItem>
            ))}
          </Select>

          <Textarea
            className="mb-8"
            label={"Description"}
            value={policyDescription}
            placeholder={" "}
            variant="faded"
            labelPlacement="outside"
            onChange={(event) => {
              setPolicyDescription(event.target.value);
            }}
          />

          {!isLoading && (
            <div>
              <p className="text-sm font-medium">Policy</p>
              <MarkdownEditor
                descriptionContent={markDownContent}
                setDescriptionContent={setMarkDownContent}
              />
            </div>
          )}
        </div>

        <div className="flex gap-4 my-4 w-full">
          <Button color="secondary" variant="light" size="sm" onClick={1}>
            Cancel
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={submitHandler}
            className={`cursor-pointer`}
          >
            Update
          </Button>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default PoliciesEditBuilder;
