// intial data for AddModal Component where we create the Input field for metrics
export const intialFormData = {
  title: "",
  minValue: 0,
  maxValue: 100,
  stepValue: "1",
  charLimit: "",
  isMultiSelect: false,
  required: false,
  relatedTo: "",
  metricName: "",
  valuesList: "",
  linkUrl: "",
  Framework: "",
  acceptFieldType: [],
  referenceList: {
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  },
  overviewList: {
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  },
  guidanceList: {
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  },
};

// values List of files upload
export const fileTypes = [
  { name: "All Images", key: "image/*" },
  { name: "All Audio", key: "audio/*" },
  { name: "All Videos", key: "video/*" },
  { name: "Only PDF", key: "application/pdf" },
  {
    name: "Only PPT",
    key: "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    name: "Only Excel",
    key: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
];

// initalValidationError for AddModal Component
export const initalValidationError = {
  title: "",
  relatedTo: "",
  metricName: "",
  valueList: "",
  linkUrl: "",
  Framework: "",
};

export const FrameworkSelectfieldSchema = {
  id: "framework-selectfield",
  required: true,
  title: "Framework",
  placeholder: "Select any FrameWorks",
  values: [],
};
