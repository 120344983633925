export const EvidenceNameSchema = {
  id: 2,
  name: "Text Input",
  type: "text",
  title: "Name",
  required: true,
  placeholder: "Enter the name",
  char_limit: "",
};

export const EvidenceDescriptionSchema = {
  id: 3,
  name: "Text Area",
  type: "textarea",
  title: "Description",
  placeholder: "Enter the description",
  required: false,
  char_limit: "",
};

export const fileInputSchema = {
  id: 1,
  name: "File Upload",
  type: "file",
  title: "Upload Evidence",
  required: true,
  acceptfieldtype: [
    "image/*",
    "audio/*",
    "video/*",
    "application/pdf",
    "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
};

export const initialData = {
  "Upload Evidence": "",
  Description: "",
  Name: "",
};
