import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NotAccess from "./ErrorPage/NotAccess";
import LoadingPage from "./Loader/LoadingPage";
import { getUserRoleFromLocal } from "../utils/helper";

const ProtectedRoute = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userRoleFromLocal = getUserRoleFromLocal();
    setIsLoading(false);
    setUserRole(userRoleFromLocal);
  }, []);

  
  if (isLoading) {
    return <LoadingPage isLoading={true} alterElement={""} />;
  }
  return userRole === "Admin" ? <Outlet /> : <NotAccess />;

};

export default ProtectedRoute;
