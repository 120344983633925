import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, Pagination, Tooltip } from "@nextui-org/react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
} from "@nextui-org/table";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import SearchIcon from "../../Images/Search";
import LoadingPage from "../../components/Loader/LoadingPage";
import NullData from "../../components/ErrorPage/NullData";
import { getDataWithFilterUrl } from "../../controllers/APIController";

const ViewFramework = () => {
  const [frameworkDetails, setFrameworkDetails] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState("");
  const [metricData, setMetricData] = useState([]);
  const [CountResponseData, setCountResponseData] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const id = params._pathId;
  const frameWorkname = params._pathId.replace("-", " ");

  const { _orgId: currOrg } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const HandleToNavigate = (data) => {
    // const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-')
    const URLPath =
      data &&
      data.name &&
      data.name
        .replace(/[\s_]+/g, "-")
        .replace(/-+/g, "-")
        .trim();

    const Navigator = URLPath.toLowerCase();
    const category = data?.category.toLowerCase().replaceAll(" ", "-");
    if (category === "reference-data" || category === "framework") {
      navigate(`/${currOrg}/${category}/${Navigator}`);
    } else navigate(`/${currOrg}/metrics/${category}/${Navigator}`);
    // window.location.reload();
  };

  const editNavigaton = () => {
    navigate(`${pathname}/edit`);
  };

  useEffect(() => {
  
    const url = `filters[organizations][id][$eqi]=${OrgInfo}&filters[Name][$eqi]=${frameWorkname}`;

    getDataWithFilterUrl("frameworks", url)
      .then((data) => {
        setFrameworkDetails(data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!frameworkDetails) {
      return;
    }
    const metricIDs = frameworkDetails?.attributes?.ControlMetrics?.id;
    let metricDetails = [];

    const result = metricIDs.map(async (id) => {
      const metricResponse = await getDataWithFilterUrl(
        "metrics",
        `filters[id][$eqi]=${id}`
      );
      metricDetails.push({
        id: metricResponse?.data[0]?.id,
        name: metricResponse?.data[0]?.attributes?.Name,
        category: metricResponse?.data[0]?.attributes?.Category,
      });
      return metricResponse;
    });

    Promise.all(result)
      .then(async (data) => {
        const list = {};
        setIsLoading(false);
        setMetricData(metricDetails);
        const Responseresult = metricDetails.map(async (item) => {
          const url = orgFilterUrl+"&filters[Metric_Id][$eqi]=" + item?.name;
          const response = await getDataWithFilterUrl("responses", url);
          list[item?.name] = response?.data?.length || 0;
          return response;
        });
        await Promise.all(Responseresult);
        setCountResponseData(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [frameworkDetails]);

  const filterDataList = metricData.filter((item) => {
    return item?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
  });
  const rowsPerPage = 15;
  const pages = Math.ceil(filterDataList?.length / rowsPerPage);

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <div className="section">
          <div className="flex items-center justify-between">
            <h1 className="text-4xl ">
              {frameworkDetails && frameworkDetails.attributes.Name}
            </h1>

            <Tooltip content="Edit the frame-work" className="cursor-pointer">
              <div className="cursor-pointer" onClick={editNavigaton}>
                <lord-icon
                  src="https://cdn.lordicon.com/lecprnjb.json"
                  state="morph-home-2"
                  colors={`primary: "#000000"`}
                  style={{ width: "25px", height: "25px" }}
                ></lord-icon>
              </div>
            </Tooltip>
          </div>
          <div className="my-8">
            {frameworkDetails && frameworkDetails.attributes.Description}
          </div>

          <div className="mt-8">
            <div className="font-medium">Control metrics</div>

            <div className="w-full">
              <Input
                classNames=""
                placeholder="Search control metrics"
                className="mt-4 w-full"
                startContent={
                  <SearchIcon
                    className={`text-large text- pointer-events-none flex-shrink-0 `}
                  />
                }
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />

              {filterDataList?.length > 0 ? (
                <div className="mt-8">
                  <Table
                    aria-label="Controlled table metrics"
                    removeWrapper
                    isHeaderSticky
                    bottomContent={
                      <div className="flex items-center justify-center mt-4">
                        <Pagination
                          isCompact
                          showShadow
                          showControls
                          color="secondary"
                          page={page}
                          total={pages}
                          onChange={(page) => setPage(page)}
                        />
                      </div>
                    }
                  >
                    <TableHeader>
                      <TableColumn>Metric Name</TableColumn>
                      <TableColumn>Category</TableColumn>
                      <TableColumn>Total Responses Recorded</TableColumn>
                      <TableColumn></TableColumn>
                    </TableHeader>
                    <TableBody>
                      {filterDataList?.map((item, index) => {
                        const lowerPageLimit = (page - 1) * rowsPerPage;
                        const upperPageLimit = page * rowsPerPage;
                        if (index < lowerPageLimit || index >= upperPageLimit)
                          return null;
                        return (
                          <TableRow key={item?.id}>
                            <TableCell>{item?.name}</TableCell>
                            <TableCell>{item?.category}</TableCell>
                            <TableCell>
                              {CountResponseData[item?.name] || "--"}
                            </TableCell>
                            <TableCell>
                              <div
                                className="text-blue-600 font-semibold cursor-pointer"
                                onClick={() => HandleToNavigate(item)}
                              >
                                View
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <NullData title="No Data Present!!" />
              )}
            </div>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default ViewFramework;
