import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PublishData,
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { Accordion, AccordionItem, Button, Chip, Snippet } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import {
  getFieldMoreInfoContent,
  getSchemaForOrg,
  handleCopyClick,
  increasedMetricCurrentCount,
} from "../../utils/helper";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { checkValidation } from "../../utils/checkValidation";
import { getFormulaData } from "../../utils/formulaCalulator";
import FileEvidenceInput from "../FormBuilder/InputField/FileEvidenceInput";
import InfoToolTip from "../../components/utils/InfoToolTip";
import MetricTooltip from "../../components/utils/MetricTooltip";
import useGetOrgFramework from "../../hooks/useGetOrgFramework";
import { metricColor } from "../../utils/constant";

const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else if (item[1].type === "file") data[item[0]] = [];
    else data[item[0]] = "";
  });
  return data;
};
const updateEvidenceRefrences = async (
  formdata,
  formschemaArray,
  referenceId
) => {
  const PromisesReponses = formschemaArray.map(async (row) => {
    if (row[1]?.type === "file") {
      const EvidenceIdList = formdata[row[0]];

      const result = EvidenceIdList?.map(async (id) => {
        const uploadData = {
          ReferenceList: {
            connect: [+referenceId],
          },
        };

        return await UpdateOneData("evidences", +id, uploadData);
      });
      return result;
    }
    return null;
  });

  await Promise.all(PromisesReponses);
};
const CreateMetricForm = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const UserId = UserLogged && UserLogged.user.id;

  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);

  const { orgFrameworkDetails } = useGetOrgFramework(OrgInfo);
  const navigate = useNavigate();

  const { _orgId: currOrg, _categoryId, _metricId } = useParams();

  const isFormulaVisiable =
    CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  let HeadingTitle = capitalize(separateDasFromUrl(_metricId));
  let description = CollectionInfo?.attributes?.Description;

  const requiredArr = CollectionInfo?.attributes?.JsonSchema?.required;

  const filteredUrl =
    orgFilterUrl +
    "&filters[Category][$eqi]=" +
    _categoryId.replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    _metricId.replaceAll("-", " ");

  const changeHandler = async (title, value, type) => {
    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const curlMessage = `curl --location '${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send' \n
  --header 'Content-Type: application/json' \n
  --header 'apikey: <Enter Your API Key>' \n
  --data '{
      "metric_id": "${CollectionInfo?.id}",
      "response": <Enter Your JSON Response>
      
  }'`;

  const submitHandler = async () => {
    const isValid = checkValidation(formSchema, formdata, setValidationErrors);
    if (!isValid) {
      return;
    }

    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const publishData = {
      Metric_Id: CollectionInfo.attributes.Name,
      Status:
        CollectionInfo?.attributes?.WorkFlow === "Default" ? "Done" : "Created",
      JsonSchema: { ...formdata, ...formulaData },
      Organization: OrgInfo,
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: UserId,
            position: {
              end: true,
            },
          },
        ],
      },
    };

    let result = await PublishData({ data: publishData }, "responses");

    console.log(result?.data?.attributes?.createdAt);
    await increasedMetricCurrentCount(
      CollectionInfo?.id,
      result?.data?.attributes?.createdAt
    );
    await updateEvidenceRefrences(formdata, formSchema, result?.data?.id);

    if (CollectionInfo?.attributes?.FrequencyValue === 0) {
      await UpdateOneData("metrics", CollectionInfo?.id, {
        lastResponseId: result?.data?.id,
      });
      navigate(`/${currOrg}/metrics/${_categoryId}`);
    } else navigate(`/${currOrg}/metrics/${_categoryId}/${_metricId}`);
  };

  const testCalculationHandler = async () => {
    const isValid = checkValidation(formSchema, formdata, setValidationErrors);
    if (!isValid) {
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );
  };

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.toLowerCase().replaceAll(" ", "-");
    let relatedToName = relatedTo.toLowerCase().replaceAll(" ", "-");

    navigate(`/${currOrg}/metrics/${metric}/${relatedToName}/create`);
    window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });

        getSchemaForOrg(responseData, OrgInfo).then((data) => {
          setformSchema(data);
        });

        setCollectionInfo(data.data[0]);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        setFormData(getInitailState(responseArr));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(CollectionInfo, orgFrameworkDetails);

  const Tags = CollectionInfo?.attributes?.TagList;

  return (
    <BreadCrumbsPage>
      <>
        <div className="metric-header">
          <p className="text-3xl font-medium">{HeadingTitle}</p>
          <div className="flex items-center gap-2 my-4">
            {Tags?.map((item, index) => (
              <Chip
                key={index}
                className=""
                size="sm"
                color={
                  metricColor[
                    CollectionInfo?.attributes?.Category?.toLowerCase()
                  ]
                }
                variant="flat"
              >
                {capitalize(item)}
              </Chip>
            ))}
          </div>
          <p className="my-4">
            {description ||
              `Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Ab distinctio, nobis voluptas illum quo et, quaerat aut, minus
            excepturi id aliquam nisi fuga?`}
          </p>

          <div className="flex flex-col gap-1 text-sm">
            <p className="flex items-center gap-2 font-medium">
              <span>Overview</span>{" "}
              <InfoToolTip
                data={
                  <MetricTooltip
                    data={CollectionInfo?.attributes?.Overview || {}}
                    orgFrameworkDetails={orgFrameworkDetails}
                    selectedFramework = {CollectionInfo?.attributes?.Framework?.id || []}
                  />
                }
                w="16px"
              />
            </p>
            <p className="text-gray">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo
              facilis neque reprehenderit ad autem corrupti, accusantium ea
              distinctio. Maiores tempora numquam dolores culpa officiis
              exercitationem! Voluptatum eveniet aspernatur expedita tenetur hic
              et. Eius hic doloribus vel. Deleniti reprehenderit cumque est?
            </p>
          </div>
          <div className="flex flex-col gap-1 text-sm my-4">
            <p className="flex items-center gap-2 font-medium">
              <span>Guidance</span>{" "}
              <InfoToolTip
                data={
                  <MetricTooltip
                    data={CollectionInfo?.attributes?.Guidance || {}}
                    orgFrameworkDetails={orgFrameworkDetails}
                    selectedFramework = {CollectionInfo?.attributes?.Framework?.id || []}
                  />
                }
                w="16px"
              />
            </p>
            <p className="text-gray">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo
              facilis neque reprehenderit ad autem corrupti, accusantium ea
              distinctio. Maiores tempora numquam dolores culpa officiis
              exercitationem! Voluptatum eveniet aspernatur expedita tenetur hic
              et. Eius hic doloribus vel. Deleniti reprehenderit cumque est?
            </p>
          </div>

          {/* <div>
            <span>Guidance : </span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
              reiciendis magnam in recusandae quae labore asperiores
              exercitationem consectetur, quam accusantium!
            </span>
          </div> */}
        </div>

        <div className="flex justify-end my-4 gap-4">
          {isFormulaVisiable && (
            <Button
              color="secondary"
              variant="ghost"
              size="md"
              onClick={testCalculationHandler}
            >
              Test Calculation
            </Button>
          )}
          <Button
            color="danger"
            variant="ghost"
            size="md"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </div>
        <div className="flex gap-[60px]">
          <div className="w-[50%]">
            {formSchema &&
              formSchema.map((row, index) => {
                const moreInfo = getFieldMoreInfoContent(row[1]);
                console.log(moreInfo, orgFrameworkDetails);
                if (row[1].type === "string" || row[1].type === "text") {
                  return (
                    <TextInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "password") {
                  return (
                    <PasswordInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "integer") {
                  return (
                    <IntegerInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "dropdown") {
                  return (
                    <DropDownInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "slider") {
                  return (
                    <SliderInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "boolean") {
                  return (
                    <BooleanInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "textarea") {
                  return (
                    <TextAreaInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "date") {
                  return (
                    <DateInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "file") {
                  return (
                    <FileEvidenceInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                    />
                  );
                }
                if (row[1].type === "relation") {
                  updateFormSchemaWithRealtion(
                    formSchema,
                    index,
                    setformSchema
                  );
                  return (
                    <RelationDropDownInput
                      data={row[1]}
                      value={formdata[row[0]]}
                      moreInfo={moreInfo}
                      orgFrameworkDetails={orgFrameworkDetails}
                      validationErrors={validationErrors}
                      onChangeHandler={changeHandler}
                      onClickHandler={clickHandler}
                    />
                  );
                }
                return null;
              })}
          </div>
          <div className="w-[45%]">
            <div className="flex flex-col relative w-full">
              <div className={`w-full ${links?.length === 0 && "hidden"}`}>
                <div className="text-lg font-semibold">
                  Helpful Background Reading:
                </div>
                <div>
                  <div className="link-list my-2 text-blue-600 font-semibold">
                    {links?.map((link, index) => (
                      <div className="links">
                        <Link
                          key={index}
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <Accordion size="lg" isflust={true}>
                  <AccordionItem
                    title="Webhook API"
                    className="curl-accordion-item"
                  >
                    <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      Integrate our form apis to your workflow
                    </p>

                    <div className="relative rounded-xl bg-black my-2">
                      <pre className="p-2 text-[#899fef]">{curlMessage}</pre>
                      <MdOutlineContentCopy
                        className="absolute top-0 right-0 m-4 text-white cursor-pointer"
                        onClick={() => handleCopyClick(curlMessage)}
                      />
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {isFormulaVisiable && (
              <div className="w-[40%] bg-grey-400 rounded-sm p-2 h-max">
                Test Calculation
              </div>
            )}
          </div>
        </div>
      </>
    </BreadCrumbsPage>
  );
};

export default CreateMetricForm;
