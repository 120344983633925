import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Input,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loader/Loading";
import {
  getDataWithFilterUrlwithLimit,
  getFilterDataWithPagination,
  getOnedata,
  StringLimit,
} from "../../controllers/APIController";
import SearchIcon from "../../Images/Search";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormatorDD } from "../../utils/helper";
import { Colors, numColors } from "../../utils/constant";
import NullData from "../../components/ErrorPage/NullData";

const Dashboard = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    ProfilePicture: "",
    // Theme: '',
  });

  const navigator = useNavigate();
  const { _orgId: currOrg } = useParams();

  const [responseData, setResponseData] = useState([]);
  const [categroyName, setCategoryName] = useState({});

  const [UserDetails, setUserDetails] = useState(null);
  const [Loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  useEffect(() => {
    setLoader(true);
    getOnedata("users", UserId).then((data) => {
      setLoader(false);
      console.log("data", data);
      setFormData({
        FirstName: data.FirstName,
        LastName: data.LastName,
        // Theme: data && data.Theme
      });
      setUserDetails(data);
    });
  }, [Response]);

  const UserProfile =
    UserDetails && UserDetails.ProfilePicture !== null
      ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
          UserDetails.ProfilePicture && UserDetails.ProfilePicture.url
        }`
      : "";

  const navigateHandler = (metric, id) => {
    const category = categroyName[metric]?.toLowerCase().replaceAll(" ", "-");
    const Navigator = metric?.toLowerCase().replaceAll(" ", "-");
    if (category === "reference-data" || category === "framework") {
      navigator(`/${currOrg}/${category}/${Navigator}/${id}`);
    } else navigator(`/${currOrg}/metrics/${category}/${Navigator}/${id}`);
    console.log(metric, categroyName, category);
  };

  useEffect(() => {
    getDataWithFilterUrlwithLimit(
      "responses",
      `${orgFilterUrl}&sort[0]=updatedAt:desc&pagination[limit]=3`
    )
      .then((data) => {
        console.log(data?.data);
        setResponseData(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const categoryList = {};
    const result = responseData.map(async (item) => {
      const url =
        orgFilterUrl + "&filters[Name][$eqi]=" + item?.attributes?.Metric_Id;
      const response = await getFilterDataWithPagination("metrics", url, 1, 1);
      categoryList[item?.attributes?.Metric_Id] =
        response?.data[0]?.attributes?.Category || "Unknown";
      return response;
    });

    Promise.all(result).then(() => {
      setCategoryName(categoryList);
    });
  }, [responseData]);


  console.log(responseData);
  return (
    <BreadCrumbsPage>
      <div>
        {Loader ? (
          <div
            className={`absolute z-[100]  inset-0 h-screen w-screen bg-slate-50`}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
        <div>
          <div className="text-3xl">
            Hello{" "}
            {UserDetails && UserDetails.FirstName !== null
              ? UserDetails.FirstName
              : UserDetails && UserDetails.email}
          </div>
          <div className="mt-8">
            <Input
              placeholder="Search for anything inside Audyt ESG"
              startContent={
                <SearchIcon
                  className={`text-large text- pointer-events-none flex-shrink-0 `}
                />
              }
            />
          </div>
          <div className="mt-8">
            <div className="flex justify-between">
              <div className="grid grid-cols-2 gap-2">
                <Card
                  className="py-2 w-[175px] h-[175px] border border-divider overflow-auto"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <p className="text-tiny uppercase font-bold"></p>
                    <small className="text-default-500">Score</small>
                    <h4 className="font-bold text-sm">Overall ESG Score</h4>
                  </CardHeader>
                  <CardBody className="overflow-visible py-2">
                    <div className="text-3xl flex items-center w-full h-full justify-center">
                      A
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="py-2 w-[175px] h-[175px] border border-divider overflow-auto"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <p className="text-tiny uppercase font-bold"></p>
                    <small className="text-default-500">Score</small>
                    <h4 className="font-bold text-sm">Environment Score</h4>
                  </CardHeader>
                  <CardBody className="overflow-visible py-2">
                    <div className="text-3xl flex items-center w-full h-full justify-center">
                      A
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="py-2 w-[175px] h-[175px] border border-divider overflow-auto"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <p className="text-tiny uppercase font-bold"></p>
                    <small className="text-default-500">Score</small>
                    <h4 className="font-bold text-sm">Social Score</h4>
                  </CardHeader>
                  <CardBody className="overflow-visible py-2">
                    <div className="text-3xl flex items-center w-full h-full justify-center">
                      A
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="py-2 w-[175px] h-[175px] border border-divider overflow-auto"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <p className="text-tiny uppercase font-bold"></p>
                    <small className="text-default-500">Score</small>
                    <h4 className="font-bold text-sm">Governance Score</h4>
                  </CardHeader>
                  <CardBody className="overflow-visible py-2">
                    <div className="text-3xl flex items-center w-full h-full justify-center">
                      B
                    </div>
                  </CardBody>
                </Card>
              </div>

              <Card
                className="py-2 w-[800px] h-[354px] border border-divider"
                shadow="none"
              >
                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                  <p className="text-tiny uppercase font-bold flex items-center gap-4">
                    Metrics Updated
                    <Tooltip content="View more documents">
                      <Link
                        onClick={() => navigator(`/${currOrg}/logs`)}
                        showAnchorIcon
                        color="danger"
                        className="cursor-pointer"
                      ></Link>
                    </Tooltip>
                  </p>
                </CardHeader>
                <CardBody className="overflow-visible pt-8">
                  <Table
                    removeWrapper
                    aria-label="Example static collection table"
                  >
                    <TableHeader>
                      <TableColumn>Metric</TableColumn>
                      <TableColumn>Updated at</TableColumn>
                      <TableColumn>Updated by</TableColumn>
                    </TableHeader>
                    <TableBody emptyContent={<NullData />}>
                      {responseData?.map((data) => {
                        const colorIndex =
                          data?.attributes?.users_permissions_user?.data?.id %
                          numColors;
                        return (
                          <TableRow
                            key="1"
                            className="cursor-pointer hover:bg-grey-200"
                            onClick={() =>
                              navigateHandler(
                                data?.attributes?.Metric_Id,
                                data?.id
                              )
                            }
                          >
                            <TableCell>
                              <div className="flex gap-4 items-center">
                                <lord-icon
                                  src="https://cdn.lordicon.com/nizfqlnk.json"
                                  trigger="hover"
                                  target="#Metrics"
                                  state="morph-home-2"
                                  colors={`primary: "#000000"`}
                                  style={{ width: "25px", height: "25px" }}
                                ></lord-icon>
                                {data?.attributes?.Metric_Id}
                              </div>
                            </TableCell>
                            <TableCell>
                              {DateFormatorDD(data?.attributes?.updatedAt)}
                            </TableCell>
                            <TableCell>
                              <div className="flex gap-4 items-center">
                                <Avatar
                                  className="h-6 w-6"
                                  color={Colors[colorIndex]}
                                  name={data?.attributes?.users_permissions_user?.data?.attributes?.email
                                    .toUpperCase()
                                    .slice(0, 1)}
                                  src={
                                    data?.attributes?.users_permissions_user
                                      ?.data?.attributes?.ProfilePicture &&
                                    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.attributes?.users_permissions_user?.data?.attributes?.ProfilePicture.url}`
                                  }
                                />
                                <div className="text-tiny text-default-500 font-bold">
                                  {StringLimit(
                                    data?.attributes?.users_permissions_user
                                      ?.data?.attributes?.email,
                                    30
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default Dashboard;
