import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import { FieldList } from "../../utils/constant";
import EditFormulaModal from "./EditFormulaModal";

const FormulaInfoTable = ({ fieldInfoList, setFieldInfoList }) => {
  const [editItem, setEditItem] = useState({});
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const headers = ["Formula Name", ""];

  const updateFieldHandler = (item) => {
    const newFieldInfoList = fieldInfoList.map((data) => {
      if (data.id === item.id) {
        return item;
      } else return data;
    });
    setFieldInfoList(newFieldInfoList);
  };


  const deleteHandler = (id) => {
    const newFieldInfoList = fieldInfoList.filter((item) => item.id !== id);
    setFieldInfoList(newFieldInfoList);
  };

  return (
    <>
      {fieldInfoList.length > 0 && (
        <div className="m-4">
          <Table removeWrapper>
            <TableHeader>
              {headers.map((header, headerIndex) => (
                <TableColumn key={headerIndex}>{header}</TableColumn>
              ))}
            </TableHeader>
            <TableBody>
              {fieldInfoList &&
                fieldInfoList.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell className="flex items-center gap-2">
                        <span>
                          {item.title}{" "}
                          {item.Required && (
                            <span style={{ color: "#f31b66" }}> *</span>
                          )}
                        </span>
                      </TableCell>

                      <TableCell>
                        <div className="flex items-center justify-end gap-4">
                          <div
                            className="cursor-pointer flex items-center justify-center"
                            onClick={() => {
                              setEditItem(item);
                              onOpen();
                            }}
                          >
                            <Tooltip size="sm" content="Edit field">
                              <lord-icon
                                src="https://cdn.lordicon.com/lzgmgrnn.json"
                                trigger="hover"
                                colors={`primary #000000`}
                                style={{ width: "20px", height: "20px" }}
                              ></lord-icon>
                            </Tooltip>
                          </div>
                          <div
                            className="flex gap-4 text-center justify-end cursor-pointer"
                            onClick={() => deleteHandler(item.id)}
                          >
                            <Tooltip size="sm" content="Delete field">
                              <lord-icon
                                src="https://cdn.lordicon.com/wpyrrmcq.json"
                                trigger="hover"
                                colors={`primary #000000`}
                                style={{ width: "20px", height: "20px" }}
                              ></lord-icon>
                            </Tooltip>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      )}
      {isOpen && (
          <EditFormulaModal
            onSubmitHandler={updateFieldHandler}
            isOpen={isOpen}
            onOpen={onOpen}
            onOpenChange={onOpenChange}
            fieldType={1}
            id={editItem.id}
            item={editItem}
          />
        )}
    </>
  );
};

export default FormulaInfoTable;
