import {
  Accordion,
  AccordionItem,
  Divider,
  Input,
  Textarea,
} from "@nextui-org/react";
import React from "react";

const FrameworFieldAccodian = ({
  title,
  setMetricNameList,
  MetricNameList,
  setOverviewList,
  overviewList,
  setGuidanceList,
  guidanceList,
}) => {

  return (
    <div className="flex w-full mb-8">
      <Accordion variant="bordered" isCompact={true}>
        <AccordionItem key="1" aria-label="Accordion 1" title={title}>
          <Divider className="mb-12 h-[2px]"/>
          <Input
            type="text"
            className="my-4"
            variant="faded"
            label={`Metric Name`}
            labelPlacement="outside"
            placeholder=" "
            value={MetricNameList?.[title]}
            onChange={(event) =>
              setMetricNameList({
                ...MetricNameList,
                [title]: event.target.value,
              })
            }
          />
          <Textarea
            type="text"
            className="my-4"
            variant="faded"
            label={`Overview`}
            labelPlacement="outside"
            placeholder=" "
            value={overviewList?.[title]}
            onChange={(event) =>
              setOverviewList({
                ...overviewList,
                [title]: event.target.value,
              })
            }
          />
          <Textarea
            type="text"
            className="my-4"
            variant="faded"
            label={`Guidance`}
            labelPlacement="outside"
            placeholder=" "
            value={guidanceList?.[title]}
            onChange={(event) =>
              setGuidanceList({
                ...guidanceList,
                [title]: event.target.value,
              })
            }
          />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FrameworFieldAccodian;
