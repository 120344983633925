import React from "react";
import { Card, Skeleton } from "@nextui-org/react";

const TableSkeleton = () => {
  return (
    <Card className="w-full space-y-5 mb-4 mt-8">
      <div className="space-y-6">
        {/* Table header skeleton */}
        <div className="flex justify-between p-4 bg-grey-600">
          <Skeleton className="w-1/6 rounded-lg">
            <div className="h-5 w-full rounded-lg bg-default-300"></div>
          </Skeleton>
          <Skeleton className="w-1/6 rounded-lg">
            <div className="h-5 w-full rounded-lg bg-default-300"></div>
          </Skeleton>
          <Skeleton className="w-1/6 rounded-lg">
            <div className="h-5 w-full rounded-lg bg-default-300"></div>
          </Skeleton>
          <Skeleton className="w-1/6 rounded-lg">
            <div className="h-5 w-full rounded-lg bg-default-300"></div>
          </Skeleton>
          <Skeleton className="w-1/6 rounded-lg">
            <div className="h-5 w-full rounded-lg bg-default-300"></div>
          </Skeleton>
        </div>

        {/* Table rows skeleton */}
        {[...Array(14)].map((_, index) => (
          <div className="flex justify-between px-4" key={index}>
            <Skeleton className="w-1/6 rounded-lg">
              <div className="h-5 w-full rounded-lg bg-default-200"></div>
            </Skeleton>
            <Skeleton className="w-1/6 rounded-lg">
              <div className="h-5 w-full rounded-lg bg-default-200"></div>
            </Skeleton>
            <Skeleton className="w-1/6 rounded-lg">
              <div className="h-5 w-full rounded-lg bg-default-200"></div>
            </Skeleton>
            <Skeleton className="w-1/6 rounded-lg">
              <div className="h-5 w-full rounded-lg bg-default-200"></div>
            </Skeleton>
            <Skeleton className="w-1/6 rounded-lg">
              <div className="h-5 w-full rounded-lg bg-default-200"></div>
            </Skeleton>
          </div>
        ))}
        <div className="mt-4"></div>
      </div>
    </Card>
  );
};

export default TableSkeleton;
