import React from "react";
import { Colors } from "../../utils/constant";
import { capitalize, UpdateOneData } from "../../controllers/APIController";
import { Avatar } from "@nextui-org/react";

const SingleInvitationNotification = ({ data, onRender, UserId }) => {
  //   const colorIndex = user.id % numColors;

  const orgName = capitalize(
    data?.attributes?.organization?.data?.attributes?.Name || "Unknown"
  );
  const senderEmail =
    data?.attributes?.sendBy?.data?.attributes?.email || "Unknown";
  console.log(data, orgName, senderEmail);

  const acceptInvitationHandler = async () => {
    const orgId = data?.attributes?.organization?.data?.id;

    const invitationId = data?.id;

    const publishOrgData = {
      user_lists: {
        connect: [UserId],
      },
    };
    const publishInvitationData = {
      Status: "Accepted",
    };

    const orgResponse = await UpdateOneData(
      "organizations",
      orgId,
      publishOrgData
    );
    const invitationResponse = await UpdateOneData(
      "invitations",
      invitationId,
      publishInvitationData
    );

    console.log(invitationResponse, orgResponse);
    onRender((pre) => !pre);
  };

  const rejectInvitationHandler = async () => {
    const invitationId = data?.id;

    const publishData = {
      Status: "Rejected",
    };
    const r = await UpdateOneData("invitations", invitationId, publishData);
    console.log(r);
    onRender((pre) => !pre);
  };

  return (
    <div className="flex items-center justify-between my-2">
      <div className="flex flex-row items-center gap-2">
        <Avatar
          color={Colors[1]}
          src={"https://avatars.githubusercontent.com/u/86160567?s=200&v=4%22"}
          size="sm"
          className="h-8 w-8"
        />
        <div className="flex flex-col text-xs">
          <p className="text-xs">{senderEmail}</p>
          <p className="text-xs text-slate-400">
            {orgName} send you invitation.
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <lord-icon
          src="https://cdn.lordicon.com/cgzlioyf.json"
          trigger="hover"
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={acceptInvitationHandler}
          className=""
        ></lord-icon>
        <lord-icon
          src="https://cdn.lordicon.com/wpyrrmcq.json"
          trigger="hover"
          colors={`primary #000000`}
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={rejectInvitationHandler}
        ></lord-icon>
      </div>
    </div>
  );
};

export default SingleInvitationNotification;
