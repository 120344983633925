import { Chip } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
const RenderTags = ({ data, id, onHandlerTagRemove }) => {
  return (
    <Chip
      key={id}
      className=""
      size="sm"
      color={["secondary", "success", "warning", "danger", "primary"][id % 6]}
      variant="flat"
      onClose={() => onHandlerTagRemove(data)}
    >
      {data}
    </Chip>
  );
};
const TagsInput = ({tags,setTags,IntialvalidationError,ValidatonFun=null,inputCss=""}) => {
  
  console.log(IntialvalidationError);
  const [tagInput, setTagInput] = useState("");

  const [Validatetags, setValidatetags] = useState("");
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const Text = tagInput.trim().toLowerCase();

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const Validation = () => {
    if(ValidatonFun!==null){
      return ValidatonFun(tags,Text,setValidatetags);
    }
    let isValid = true;

    if (tags.includes(Text)) {
      setValidatetags("Entered email already exists in invite list.");
      isValid = false;
    } else if (!EmailRegex.test(Text)) {
      setValidatetags("Enter a valid email.");
      isValid = false;
    } else {
      setValidatetags(null);
    }

    return isValid;
  };

  const handleKeyPress = (e) => {
    if (tagInput.trim() !== "" && e.key === "Enter") {
      const Validate = Validation();
      if (Validate) {
        const newValues = [...tags,Text];
        setTags(newValues);
        setValidatetags("");
        setTagInput("");
      }
    }
  };
  
  useEffect(()=>{
    console.log(IntialvalidationError);
    setValidatetags(IntialvalidationError);
  },[IntialvalidationError]);
  
  console.log(tags,Validatetags);
  return (
    <div>
      <div className="w-full">
        <div className="text-sm my-4">Enter your team members emails</div>
        <div
          style={{ Background: "#f4f4f5" }}
          className={`border rounded-xl  flex flex-col border-slate-200 p-2 `+inputCss}
        >
          <input
            className={`bg-transparent border-0 rounded text-sm `}
            key={"outside"}
            style={{ outline: "none" }}
            type="text"
            variant="bordered"
            labelPlacement={"outside"}
            placeholder="Press enter to add multiple emails"
            value={tagInput && tagInput.trim()}
            onChange={(e) => {
              setTagInput(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleKeyPress(e);
              }
            }}
          />
          <div className="pt-4 flex flex-wrap gap-2">
            {tags &&
              tags?.map((item, index) => (
                <RenderTags
                  data={item}
                  id={index}
                  onHandlerTagRemove={handleTagRemove}
                />
              ))}
          </div>
          <p className="pt-2 text-xs text-red-400">
            {Validatetags || " "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TagsInput;
