import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../../components/BreadCrumbsPage";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import MarkdownEditor from "../../Dashboard/MarkdownEditor";
import {
  DropDownInput,
  TextAreaInput,
  TextInput,
} from "../../FormBuilder/InputField/InputField";
import { getDataWithFilterUrl, PublishData } from "../../../controllers/APIController";

/*
Name,
Domain,
Policy,
Objective
Markdown,
Control-Metircs,
*/

const ProcedureDataSchema = {
  Name: {
    id: 1,
    name: "Text Input",
    type: "text",
    title: "Name",
    required: true,
    char_limit: "",
    placeholder: "Enter Procedure Name",
  },
  Objective: {
    id: 2,
    name: "Text Area",
    type: "textarea",
    title: "Objective",
    required: false,
    placeholder: "Enter the objective of the Procedure",
  },
  "Choose Domain": {
    id: 3,
    name: "Drop Down",
    type: "dropdown",
    title: "Choose Domain",
    values: [],
    required: true,
    ismultiselect: false,
  },
  "Choose Policy": {
    id: 3,
    name: "Drop Down",
    type: "dropdown",
    title: "Choose Policy",
    values: [],
    required: true,
    ismultiselect: false,
  },
};

const ProcedureCreate = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [procedureName, setProcedureName] = useState("");
  const [objective, setObjective] = useState("");
  const [DomainName, setDomainName] = useState("");
  const [DomainList, setDomainList] = useState([]);

  const [policyName, setPolicyName] = useState("");
  const [PolicyList, setPolicyList] = useState([]);

  const [metricValueList, setMetricValueList] = useState("");
  const [metricFormList, setMetricFormList] = useState([]);
  const [markDownContent, setMarkDownContent] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const navigate = useNavigate();
  const { _orgId: currOrg } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;
  let schemaData = ProcedureDataSchema;

  const validationHandler = () => {
    let isValid = true;
    const errorMessage = {};
    if (procedureName.trim() === "") {
      errorMessage["Name"] = "Procedure Name is required!";
      isValid = false;
    }
    if (DomainName.trim() === "") {
      errorMessage["Choose Domain"] = "Domain is required!";
      isValid = false;
    }
    if (policyName.trim() === "") {
      errorMessage["Choose Policy"] = "Policy name is required!";
      isValid = false;
    }

    setValidationErrors(errorMessage);

    return isValid;
  };
  const submitHandler = async () => {
    const isValid = validationHandler();
    if (!isValid) {
      return;
    }

    const publishData = {
      Name: procedureName,
      DomainName: DomainName,
      Policy: policyName,
      Objective: objective,
      ControlMetrics: {
        controlMetrics: metricValueList.split(","),
      },
      ProcedureFramework: markDownContent,
      Organization: OrgInfo,
    };
    let result = await PublishData({ data: publishData }, "procedures");
    navigate(`/${currOrg}/policies-builder`);
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics")
      .then((data) => {
        let list = data.data;
        list.sort((a, b) => {
          const nameA = a?.attributes?.Name?.trim().toLowerCase() || "";
          const nameB = b?.attributes?.Name?.trim().toLowerCase() || "";
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        list = list.map((item) => {
          return {
            id: item.id,
            title: item?.attributes?.Name,
          };
        });
        setMetricFormList(list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDataWithFilterUrl("domains")
      .then((data) => {
        let list = data.data;
        list = list
          .sort((a, b) => {
            const nameA = a?.attributes?.Name?.trim().toLowerCase() || "";
            const nameB = b?.attributes?.Name?.trim().toLowerCase() || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          ?.map((item) => {
            return {
              id: item.id,
              name: item?.attributes?.Name,
            };
          });

        setDomainList(list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const url = orgFilterUrl + "&filters[Domain][$eqi]=" + DomainName;
    getDataWithFilterUrl("policies", url)
      .then((data) => {
        let list = data.data;
        list = list
          .sort((a, b) => {
            const nameA = a?.attributes?.Name?.trim().toLowerCase() || "";
            const nameB = b?.attributes?.Name?.trim().toLowerCase() || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          ?.map((item) => {
            return {
              id: item.id,
              name: item?.attributes?.Name,
            };
          });

        setPolicyList(list);
        setPolicyName("");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [DomainName]);

  schemaData["Choose Domain"].values = DomainList;
  schemaData["Choose Policy"].values = PolicyList;

  return (
    <BreadCrumbsPage>
      <div>
        <p className="text-3xl font-medium">Procedure</p>
        <div className="my-8">
          Create or edit ESG Policies that would support your ESG Reporting.
        </div>
        <div className={`flex justify-end cursor-pointer`}>
          <Button
            color="danger"
            size="md"
            onClick={submitHandler}
            className={`cursor-pointer`}
          >
            Submit
          </Button>
        </div>

        <div className="my-16 w-[50%]">
          <TextInput
            data={schemaData["Name"]}
            value={procedureName}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => setProcedureName(value)}
          />
          <TextAreaInput
            data={schemaData["Objective"]}
            value={objective}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => setObjective(value)}
          />
          <DropDownInput
            data={schemaData["Choose Domain"]}
            value={DomainName}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => setDomainName(value)}
          />
          <DropDownInput
            data={schemaData["Choose Policy"]}
            value={policyName}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => setPolicyName(value)}
          />

          {/* Form-Metric Choosen Input */}
          <Select
            label="Control Metrics"
            placeholder="Select any Metrics"
            variant="faded"
            selectionMode="multiple"
            labelPlacement="outside"
            className="my-8"
            onSelectionChange={(event) =>
              setMetricValueList(
                Array.from(event).join(",").replaceAll("_", " ")
              )
            }
          >
            {metricFormList?.map((item) => (
              <SelectItem key={item.id} value={item.title} className="py-2">
                {item.title}
              </SelectItem>
            ))}
          </Select>
          <div>
            <p className="text-sm font-medium">Policy</p>
            <MarkdownEditor
              descriptionContent={markDownContent}
              setDescriptionContent={setMarkDownContent}
            />
          </div>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default ProcedureCreate;
