import React from 'react'

const CreateFactorMapping = () => {
  return (
    <div>
        <div className='mt-8 text-lg'>
            Create factor mappings with your metrics and your factor library
        </div>
    </div>
  )
}

export default CreateFactorMapping