import React from "react";
import CardSkeleten from "./CardSkeleten";

const CardSkeletonContainer = () => {
  return (
    <>
      <div className="flex flex-wrap item-center justify-between gap-6">
        <CardSkeleten />
        <CardSkeleten />
        <CardSkeleten />
        <CardSkeleten />
        <CardSkeleten />
        <CardSkeleten />
        <CardSkeleten />
      </div>
    </>
  );
};

export default CardSkeletonContainer;
