import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React from "react";

const NavbarFooterModal = ({ isOpen, onOpenChange }) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="center"
      size="2xl"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Feedback</ModalHeader>
            <ModalBody className="gap-0">
              <p>We value your input!</p>
              <p>
                Whether it's a suggestion for improvement, a bug you've
                encountered, or simply your thoughts on how we can make our
                product better, we're all ears.
              </p>
              <Textarea
                autoFocus
                isRequired
                label="Share your feedback"
                variant="faded"
                minRows={10}
                className="pt-16"
                labelPlacement="outside"
              />
            </ModalBody>
            {/* Your buttons for submitting or canceling feedback can go here */}

            <ModalFooter>
              <Button color="default" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button color="danger" onPress={onClose}>
                Submit Feedback
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default NavbarFooterModal;
