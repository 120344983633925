import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  capitalize,
  StringLimit,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import CardSkeleten from "../Skeleten/CardSkeleten";

const SingleControlMetricCard = ({ id, FrequencyCounterList }) => {
  const [loader, setLoader] = useState(true);
  const [metricInfo, setMetricInfo] = useState(null);
  const { _orgId } = useParams();
  const navigate = useNavigate();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const navigateHandler = (metric, id) => {
    const category = metricInfo?.attributes?.Category?.toLowerCase().replaceAll(
      " ",
      "-"
    );
    const Navigator = metricInfo?.attributes?.Name?.toLowerCase().replaceAll(
      " ",
      "-"
    );
    if (category === "reference-data" || category === "framework") {
      navigate(`/${_orgId}/${category}/${Navigator}`);
    } else navigate(`/${_orgId}/metrics/${category}/${Navigator}`);
  };

  useEffect(() => {
    // Firsrt We fetch the Schema Data from Metric Collection and then We fetch the data from Response collection for Particular Id
    const metricFilteredUrl = "filters[id][$eqi]=" + id;
    getDataWithFilterUrl("metrics", `${metricFilteredUrl}&${orgFilterUrl}`)
      .then((data) => {
        setMetricInfo(data?.data[0]);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const currCount = +metricInfo?.attributes?.currentCounter || 0;
  const IsCompleted =
    +metricInfo?.attributes?.currentCounter >=
    +metricInfo?.attributes?.requiredCounter
      ? true
      : false;
  console.log(metricInfo, IsCompleted);

  return (
    <div className="cursor-pointer" onClick={() => navigateHandler()}>
      {!loader ? (
        <Card
          key={id}
          className="h-[250px] p-2 border border-divider"
          shadow="none"
        >
          <CardHeader className="flex gap-3">
            <lord-icon
              src="https://cdn.lordicon.com/nizfqlnk.json"
              trigger="hover"
              target="#Metrics"
              state="morph-home-2"
              colors={`primary: "#000000"`}
              style={{ width: "25px", height: "25px" }}
            ></lord-icon>
            <div className="flex flex-col">
              <p className="text-md">{metricInfo?.attributes?.Name}</p>
              <p className="text-small text-default-500">
                {" "}
                {capitalize(metricInfo?.attributes?.Category)}
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <div>{StringLimit(metricInfo?.attributes?.Description, 90)}</div>
          </CardBody>
          <CardFooter>
            <div className="flex justify-between w-full items-center">
              <Chip color={IsCompleted ? "success" : "danger"} variant="flat">
                {IsCompleted ? "Completed" : "Uncompleted"}
              </Chip>

              <div className="flex gap-2 items-center">
                <lord-icon
                  src="https://cdn.lordicon.com/rbbnmpcf.json"
                  trigger="hover"
                  target="#Metrics"
                  state="morph-home-2"
                  colors={`primary: "#000000"`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                {currCount}
              </div>
            </div>
          </CardFooter>
        </Card>
      ) : (
        <CardSkeleten width="full" />
      )}
    </div>
  );
};

export default SingleControlMetricCard;
