import { Tab, Tabs } from "@nextui-org/react";
import React from "react";
import JsonFormBuilder from "./JsonFormBuilder";
import UIFormBuilder from "./UIFormBuilder";
import { useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

export const FormBuilder = () => {
  const { _orgId: currOrg } = useParams();

  let tabs = [
    {
      id: "Using Form Builder UI",
      label: "Using Form Builder UI",
      content: <UIFormBuilder />,
    },
    {
      id: "Using JSON",
      label: "Using JSON",
      content: <JsonFormBuilder />,
    },
  ];

  return (
    <BreadCrumbsPage>
      <div className="w-full">
        <div className="text-3xl font-medium">Create a metric</div>
        <div className="my-8">
          Create a metric and build that would support your ESG Reporting
        </div>
        <Tabs aria-label="Dynamic tabs" items={tabs} variant="light">
          {(item) => (
            <Tab key={item.id} title={item.label}>
              {item.content}
            </Tab>
          )}
        </Tabs>
      </div>
    </BreadCrumbsPage>
  );
};
