import React from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import ComingSoon from "../../components/ComingSoon";
import { Tab, Tabs } from "@nextui-org/react";
import EmissionInsight from "./EmissionInsight";
import WasteInsight from "./WasteInsight";
import WaterInsight from "./WaterInsight";

const Analytics = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  console.log(
    `${process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS}/orgnization/${OrgInfo}`,
    process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS
  );
  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Sustainibility Activities</div>
        <div className="mt-4">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis
          fuga, quam ipsa minima quidem aliquid harum accusantium nobis nihil
          veniam! Quasi inventore voluptates natus quo, voluptas laboriosam
          iusto, praesentium voluptatibus, asperiores labore maiores dignissimos
          magnam?
        </div>
        <div className="mt-8">
          <Tabs variant="light">
            <Tab key="emission-insight" title="Emissions Insight">
              <EmissionInsight />
            </Tab>
            <Tab key="waste-insight" title="Water Insight">
              <WasteInsight />
            </Tab>
            <Tab key="water-insight" title="Waste Insight">
              <WaterInsight />
            </Tab>
          </Tabs>
        </div>
        {/* <ComingSoon /> */}
      </div>
    </BreadCrumbsPage>
  );
};

export default Analytics;
