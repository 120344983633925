export const LoginUserGrabJwt = async (email) => {
  const Payload = {
    identifier: email,
    password: "JhNOK01mFsaoZh",
  };

  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/?populate=*`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();

  return data;
};

export const RegisterApprovedUser = async (email, password) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/register?populate=*`;

  const payload = {
    email: email,
    password: "JhNOK01mFsaoZh",
    Password2: btoa(password),
    username: email,
    Theme: "Light",
  };

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-type": "Application/JSOn",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = response.json();

  return data;
};

export const NavigateSign = async (email, password2) => {
  const lastUsedRoute = sessionStorage.getItem("lastRoute");

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password2, lastUsedRoute }),
    }
  );

  const data = await response.json();
  console.log(data);

  if (data?.data?.userResponse) {
    sessionStorage.setItem("userData", JSON.stringify(data.data.userResponse));
  }

  return data?.data?.navigate_route;
};
