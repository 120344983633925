import { Image } from '@nextui-org/react'
import React from 'react'

import logo from "../../Images/logo.svg"
const Done = () => {
    return (
        <div className={`flex h-screen items-center justify-center bg-slate-50`}>

            <main className='mx-auto h-1/2 w-full max-w-[450px]'>
                <div className="flex justify-center w-full mb-8">

                    <Image
                        width={80}
                        alt="NextUI hero Image"
                        src={logo}
                    />
                </div>
                <h1 className='mb-1.5 mt-8 flex justify-center w-full sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                    Survey Response Submitted
                </h1>
                <div className='mt-8'>
                    Thank you for submitting your survey form. Your response has been recorded by Audyt and would be used for reporting and analysis purposes.
                </div>
            </main>
        </div>
    )
}

export default Done