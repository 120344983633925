import "./App.css";
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutesList from "./routes.json";
import { useEffect } from "react";
import getComponent from "./getComponent.js";

const renderRoutes = (routesList) => {
  return routesList.map((route, index) => {
    const { path, element, props, nestedRoutes } = route;
    const Component = getComponent(element);
    return (
      <Route key={index} path={path} element={<Component {...props} />}>
        {nestedRoutes && renderRoutes(nestedRoutes)}
      </Route>
    );
  });
};

const App = () => {
  
  if (process.env.NODE_ENV === "production") {
    console.log = console.warn = console.error = () => {};
  }
  
  // console.log = () => {};
  useEffect(() => {
    document.title = "Audyt ESG | Sustainability Reporting"; // replace it with the name of the application for the Tab Name. To change the favicon cahnge inside public
  }, []);

  return (
    <NextUIProvider>
      {/* <NextThemesProvider> */}
      <div>
        <BrowserRouter>
          <Routes>{renderRoutes(RoutesList)}</Routes>
        </BrowserRouter>
      </div>
      {/* </NextThemesProvider> */}
    </NextUIProvider>
  );
};

export default App;
