import React, { useState } from "react";
import TagsInput from "../FormBuilder/InputField/TagsInput";
import { Button } from "@nextui-org/react";
import { ProgessColors } from "../../utils/constant";
import { createOrg, updateUserLastOrg } from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";

const OrgInviteInfo = ({
  DataList,
  setDataList,
  TitleText,
  DescriptionText,
  currentPage,
  setCurrrentPage,
  totalStep,
  defaultOrgId,
}) => {
  const [validationError, setValidationErrors] = useState("");
  const [orgCreateStatus, setOrgCreateStatus] = useState(true);
  const navigate = useNavigate();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const Validation = (tags, Text, setValidatetags) => {
    let isValid = true;

    if (tags.includes(Text)) {
      setValidatetags("Entered email already exists in invite list.");
      isValid = false;
    } else if (!EmailRegex.test(Text)) {
      setValidatetags("Enter a valid email.");
      isValid = false;
    } else {
      setValidatetags(null);
    }
    return isValid;
  };

  const submitHandler = async () => {
    try {
      const r = await createOrg({ ...DataList, UserId, defaultOrgId });

      const OrgUrlValue = DataList["Name"]?.toLowerCase()?.replaceAll(" ", "-");
      const orgId = r?.data?.id;

      const updatedUserResponse = await updateUserLastOrg(
        UserId,
        OrgUrlValue,
        orgId
      );
      
      setOrgCreateStatus(true);
      navigate(`/${OrgUrlValue}/dashboard`);
    } catch (error) {
      setOrgCreateStatus(false);
      return;
    }
  };
  return (
    <>
      <div className="flex justify-center items-center mt-8 font-semibold text-2xl">
        {TitleText || "Create an organization"}
      </div>
      <div className="w-full flex justify-center">
        <div className="mt-8  w-[650px]">
          {DescriptionText ||
            `Create an organization to embark on your ESG (Environmental, Social,
          and Governance) reporting journey. Begin evaluating and improving your
          sustainability and ethical practices today.`}
        </div>
      </div>
      <div className="flex flex-col mx-auto mt-8 h-[360px] w-[500px]">
        <TagsInput
          tags={DataList?.tags}
          setTags={(value) => {
            setDataList({ ...DataList, tags: value });
          }}
          IntialvalidationError={validationError}
          ValidatonFun={Validation}
          inputCss="h-24"
        />
        {!orgCreateStatus && (
          <p className="flex items-center justify-center mt-16 text-red-500">
            Something went wrong
          </p>
        )}
      </div>

      <div
        className={`w-full flex items-center my-2 ${
          currentPage === 0 ? "justify-end" : "justify-between"
        }`}
      >
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className="text-center cursor-pointer"
          isDisabled={currentPage === 0}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>

        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={submitHandler}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default OrgInviteInfo;
