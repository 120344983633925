import React, { useState } from "react";
import { Button, Input } from "@nextui-org/react";
import { PublishData } from "../../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbsPage from "../../../components/BreadCrumbsPage";

const DomainCreate = () => {
  const [textValue, setTextValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigator = useNavigate();

  const { _orgId: currOrg } = useParams();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const handleSubmit = async () => {
    if (textValue.trim() === "") {
      setErrorMessage("Domain name is required");
      return;
    }
    const result = await PublishData(
      { data: { Name: textValue, Organization: OrgInfo } },
      "domains"
    );
    navigator(`/${currOrg}/policies-builder`);
    return result;
  };

  return (
    <BreadCrumbsPage>
      <div>
        <p className="text-3xl font-medium">Domain</p>
        <div className="my-8">
          Create or edit ESG Policies that would support your ESG Reporting.
        </div>
        <div className={`flex justify-end cursor-pointer`}>
          <Button color="danger" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <div className="my-16">
          <Input
            type="text"
            className="mb-16 w-[50%]"
            isRequired
            label="Domain"
            placeholder={"Enter Domain Name"}
            variant="faded"
            labelPlacement="outside"
            value={textValue}
            onChange={(event) => setTextValue(event.target.value)}
            isInvalid={errorMessage !== ""}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default DomainCreate;
