import React from "react";
import { Navigate } from "react-router-dom";

const MainPage = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));

  return UserLogged ? (
    <Navigate to={`/${UserLogged?.user?.LastOrg}/dashboard`} />
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default MainPage;
