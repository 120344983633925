import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { getAlldata } from "../../controllers/APIController";
import ExcelTemplate from "./ExcelTemplate";
import ImportDataButton from "./ImportDataButton";
import { Button, useDisclosure } from "@nextui-org/react";
import useGetDefaultOrg from "../../hooks/useGetDefaultOrg";
import AddTemplateModal from "./AddTemplateModal";
import LoadingPage from "../../components/Loader/LoadingPage";
import {
  importSectionDescription,
  mainDescription,
  mainHeading,
} from "../../constant/DataImport/dataimport.constant";

const DataImport = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { OrgDetail: DefaultOrgDetail, isLoading: isLoadingDefaultOrg } =
    useGetDefaultOrg();
  const [TemplateData, setTemplateData] = useState([]);

  const [reRenderPage, setReRenderPage] = useState(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const isDefaultOrg = DefaultOrgDetail?.id === OrgInfo;

  useEffect(() => {
    getAlldata("templates")
      .then((data) => {
        setTemplateData(data?.data);
        setIsLoadingPage(false);
      })
      .catch((err) => console.log(err));
  }, [reRenderPage]);

  return (
    <>
      <AddTemplateModal
        onSubmitHandler={1}
        id={1}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        setReRenderPage={setReRenderPage}
      />
      <BreadCrumbsPage>
        <LoadingPage isLoading={isLoadingPage || isLoadingDefaultOrg}>
          <div className="text-3xl font-medium">{mainHeading} </div>
          <div className="mt-4">{mainDescription}</div>
          <div className="my-8">
            <div className="flex items-center justify-between font-medium">
              <p className="text-xl">Browser Excel Templates</p>
              {isDefaultOrg && (
                <Button color="danger" onClick={onOpen}>
                  Create Template
                </Button>
              )}
            </div>
            <div className="my-8 flex items-center flex-wrap gap-x-12 gap-y-8">
              {TemplateData?.map((data) => (
                <ExcelTemplate
                  data={data}
                  isDefaultOrg={DefaultOrgDetail?.id === OrgInfo}
                  setReRenderPage={setReRenderPage}
                  reRenderPage={reRenderPage}
                />
              ))}
            </div>
          </div>

          <div className="">
            <div className="flex items-center justify-between">
              <p className="text-xl">Import Your Data</p>
              <ImportDataButton />
            </div>
            <p className="my-8">{importSectionDescription}</p>
          </div>
        </LoadingPage>
      </BreadCrumbsPage>
    </>
  );
};

export default DataImport;
