import React from "react";

const SingleFrameworkInfo = ({ title, data }) => {
  return (
    <div className="flex flex-col">
      <p className="font-medium text-xs my-1">{title || "CBRR"} </p>
      <div className="flex flex-col text-xs mx-2 text-gray">
        <p className="mb-1">
          <span className="text-black">Gridance : </span>
          <span>{data["guidance"] || "--"}</span>
        </p>
        <p className="mb-1">
          <span className="text-black">Overview : </span>
          <span>{data["overview"] || "--"}</span>
        </p>
        <p className="mb-1">
          <span className="text-black">Reference : </span>
          <span>{data["reference"] || "--"}</span>
        </p>
      </div>
    </div>
  );
};

const FieldInputTooltip = ({
  data,
  selectedFramework,
  orgFrameworkDetails,
}) => {
  const getFrameworkName = (id) => {
    let name = "Unknown";
    orgFrameworkDetails?.forEach((item) => {
      console.log(item?.id, +id, item?.id === +id);
      if (item?.id === +id) {
        name = item?.attributes?.Name;
      }
    });
    return name;
  };
  return (
    <div className="flex flex-col px-2 py-2 w-[250px] max-h-[250px] overflow-auto hide-scollbar">
      {selectedFramework?.map((id) => {
        const name = getFrameworkName(id);
        if (data?.[name]) {
          return <SingleFrameworkInfo title={name} data={data?.[name]} />;
        } else return null;
      })}
    </div>
  );
};

export default FieldInputTooltip;
