import { useEffect, useState } from "react";
import {
  getSingleCollectionData,
} from "../controllers/APIController";

export default function useGetFrequencyCounter() {
  const [FrequencyCounterList, setFrequencyCounterList] = useState([]);

  useEffect(() => {
    getSingleCollectionData("frequency-counter")
      .then((data) => {
        setFrequencyCounterList(data?.data?.attributes?.FrequencyCounter?.list);
      })
      .catch((err) => console.log(err));
  }, []);

  return { FrequencyCounterList};
}
