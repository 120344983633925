import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";

import {
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

import { useParams } from "react-router-dom";
import LoadingPage from "../../components/Loader/LoadingPage";
import CardSkeletonContainer from "../../components/Skeleten/CardSkeletonContainer";
import PolicyCard from "../../components/Policies/PolicyCard";

const Policies = () => {

  const [domains, setDomains] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;
  
  const { _orgId: currOrg } = useParams();

  useEffect(() => {
    getDataWithFilterUrl("domains")
      .then((data) => {
        setDomains(data.data);
        getDataWithFilterUrl("policies",orgFilterUrl)
          .then((data) => {
            setPolicies(data.data);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error fetching policies:", error));
      })
      .catch((error) => console.error("Error fetching domains:", error));
  }, []);

  return (
    <BreadCrumbsPage>
      <div>
        <div className="flex items-center justify-between">
          <p className="text-3xl font-medium">ESG Policies</p>
          <div>
            <Button size="sm" variant="light" className="border border-divider">
              Generate Policy PDF
            </Button>
          </div>
        </div>
        <div className="mt-8">
          <LoadingPage
            isLoading={isLoading}
            alterElement={<CardSkeletonContainer />}
          >
            <Tabs variant="light">
              {domains &&
                domains.map((domain) => (
                  <Tab key={domain.id} title={domain.attributes.Name}>
                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                      {policies &&
                        policies
                          .filter(
                            (policy) =>
                              policy.attributes.Domain ===
                              domain.attributes.Name
                          )
                          .map((filteredPolicy) => (
                            <PolicyCard
                              domain={domain}
                              filteredPolicy={filteredPolicy}
                              currOrg={currOrg}
                            />
                          ))}
                    </div>
                  </Tab>
                ))}
            </Tabs>
          </LoadingPage>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default Policies;
