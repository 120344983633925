import React from "react";

const NullData = ({ title}) => {
  return (
    <div>
      <div>
        <div className="italic font-medium pt-8 flex items-center justify-center text-coolGray-400 h-72">
          {title
            ? title
            : `No data present. Click on "create" to make a new entry`}
        </div>
      </div>
    </div>
  );
};

export default NullData;
