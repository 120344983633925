import React from "react";
import { Colors, numColors } from "../../utils/constant";
import { RegisterApprovedUser } from "../../controllers/loginController";
import { DeleteOnedata } from "../../controllers/APIController";
import { Avatar } from "@nextui-org/react";

const SingleUserRequestNotification = ({ user, onRender }) => {
  const colorIndex = user.id % numColors;
  const approveUserHandler = async (id, email, password) => {
    console.log(email, password, id);
    const result = await RegisterApprovedUser(email, password);
    const result1 = await rejectUserHandler(id);
  };

  const rejectUserHandler = async (id) => {
    console.log(id);
    const result = await DeleteOnedata("requests", id);
    console.log(result);
    onRender((pre) => !pre);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        <Avatar
          color={Colors[colorIndex]}
          name={
            user &&
            user?.attributes?.email &&
            user?.attributes?.email.slice(0, 1).toUpperCase()
          }
          size="sm"
          className="h-8 w-8"
        />
        <div className="flex flex-col text-xs">
          <p className="text-sm">{user?.attributes?.email}</p>
          <p className="text-xs text-slate-400">Account creation request</p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <lord-icon
          src="https://cdn.lordicon.com/cgzlioyf.json"
          trigger="hover"
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() =>
            approveUserHandler(
              user.id,
              user.attributes.email,
              user.attributes.password2
            )
          }
          className=""
        ></lord-icon>
        <lord-icon
          src="https://cdn.lordicon.com/wpyrrmcq.json"
          trigger="hover"
          colors={`primary #000000`}
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => rejectUserHandler(user.id)}
        ></lord-icon>
      </div>
    </div>
  );
};
export default SingleUserRequestNotification;
