import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const SingleSearchResult = ({ setInputText, data }) => {
  const navigator = useNavigate();
  const { _orgId } = useParams();
  const naviageHandler = (data) => {
    console.log(data);
    let URLPath = "";
    if (data.type === "Evidence") {
      URLPath = process.env.REACT_APP_STRAPI_IP_ADDRESS + data?.url;
      window.open(URLPath);
    } else if (data?.category?.trim()?.toLowerCase() === "reference data") {
      URLPath =
        `/${_orgId}/` +
        data.category
          .toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim() +
        "/" +
        data.Name.toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim();
      navigator(URLPath);
    } else {
      URLPath =
        `/${_orgId}` +
        "/metrics/" +
        data.category
          .toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim() +
        "/" +
        data.Name.toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim();
      navigator(URLPath);
    }
    setInputText("");
    // window.location.reload();
  };
  return (
    <div
      className="p-2 py-4 cursor-pointer"
      onClick={() => naviageHandler(data)}
    >
      <div className="flex cursor-pointer item-center justify-center">
        <div className="flex items-center justify-center">
          {data.type === "Evidence" ? (
            <lord-icon
              src="https://cdn.lordicon.com/rbbnmpcf.json"
              trigger="hover"
              target="#evidences"
              state="morph-home-2"
              colors={`primary: "#000000"`}
              style={{ width: "25px", height: "25px" }}
            ></lord-icon>
          ) : (
            <lord-icon
              src="https://cdn.lordicon.com/nizfqlnk.json"
              trigger="hover"
              target="#Metrics"
              state="morph-home-2"
              colors={`primary: "#000000"`}
              style={{ width: "25px", height: "25px" }}
            ></lord-icon>
          )}
        </div>
        <div className="flex flex-col items-start justify-between mx-2 ml-4 w-full">
          <div className="flex items-center justify-between w-full">
            <span className="font-medium">{data?.Name}</span>
            <span className="text-sm text-gray font-medium mr-8">
              {data?.type || "--"}
            </span>
          </div>
          {data.type !== "Evidence" && (
            <div className="w-full">{data.description || "-----"}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleSearchResult;
