import { EditorProvider } from "@tiptap/react";
import React, { useState } from "react";
import { MenuBar, extensions, props } from "../../components/Markdown/Markdown";

const MarkdownEditor = ({descriptionContent,setDescriptionContent}) => {
 console.log(descriptionContent);
  return (
    <div>
      <div className="min-h-96 my-4 border-[2px] rounded-lg">
        <EditorProvider
          placeholder="Enter your description"
          editorProps={props}
          slotBefore={
            <MenuBar
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
            />
          }
          extensions={extensions}
          content={descriptionContent}
        ></EditorProvider>
      </div>
    </div>
  );
};

export default MarkdownEditor;
