import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Image,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import { DeleteOnedata, StringLimit } from "../../controllers/APIController";
import DocumentIcon from "../../Images/Icons/document.svg";
import BookMarkIncon from "../../Images/Icons/bookmark.svg";
import DownloadIcon from "../../Images/Icons/DownloadIcon.svg";
import ExcelIcon from "../../Images/ExcelIcon.svg";
import { DeleteTableItem } from "../../utils/helper";
import EditTemplateModal from "./EditTemplateModal";

const ExcelTemplate = ({
  data,
  isDefaultOrg,
  reRenderPage,
  setReRenderPage,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const url = data?.attributes?.TemplateUrl;

  const deleteTemplateHandler = async (id) => {
    await DeleteOnedata("templates", id);
    setReRenderPage((pre) => !pre);
  };

  return (
    <>
      <EditTemplateModal
        onSubmitHandler={1}
        id={1}
        item={data}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        setReRenderPage={setReRenderPage}
      />
      <Card
        key={1}
        className="h-[220px] w-[350px] p-2 border border-divider cursor-pointer"
        shadow="none"
        isPressable={isDefaultOrg || false}
        onPress={() => {
          console.log("hello");

          onOpen();
        }}
      >
        <CardHeader className="flex gap-3">
          <Image
            alt="policy logo"
            height={40}
            radius="sm"
            src={ExcelIcon}
            width={40}
          />
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-start">
              <p className="text-base font-medium">
                {data?.attributes?.Name || "Reference Data"}
              </p>
              <p className="text-xs text-default-500">Updated just now</p>
            </div>
            <div className="flex items-center gap-4">
              <Tooltip content="Download Template">
                <Image
                  alt="info logo"
                  height={20}
                  width={20}
                  radius="sm"
                  src={DownloadIcon}
                  onClick={() => window.open(url)}
                />
              </Tooltip>

              {isDefaultOrg && (
                <DeleteTableItem
                  userRole="Admin"
                  toolTiptext="Delete Template"
                  item={data}
                  deleteHandler={deleteTemplateHandler}
                />
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="flex flex-col justify-between h-full">
            <div>{StringLimit(data?.attributes?.Description || "--", 80)}</div>
            <div className="flex items-center justify-end gap-4">
              <Image
                alt="info logo"
                height={20}
                width={20}
                radius="sm"
                src={BookMarkIncon}
              />
              <Image
                alt="info logo"
                height={20}
                width={20}
                radius="sm"
                src={DocumentIcon}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ExcelTemplate;
