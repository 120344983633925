import {
  Button,
  Pagination,
  Spinner,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteOnedata,
  StringLimit,
  UpdateOneData,
  getAlldata,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { DeleteTableItem, SearchInput } from "../../utils/helper";
import LoadingPage from "../../components/Loader/LoadingPage";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import CreatePolicyButtton from "./CreatePolicyButtton";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";

const PoliciesBuilder = () => {
  const [userRole, setUserRole] = useState("Admin");
  const [isLoading, setisLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [searchPolicyText, setSearchPolicyText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [policyList, setPolicyList] = useState([]);
  const navigate = useNavigate();

  const { _orgId: currOrg } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const createNewPolicyHandler = () => {
    navigate(`/${currOrg}/policies-builder/create`);
  };
  const handleNavigateToMetric = (id) => {
    navigate(`/${currOrg}/policies-builder/${id}`);
  };

  const deleteFormHandler = async (id) => {
    try {
      const result = await DeleteOnedata("policies", id);
      setReRender((pre) => !pre);
    } catch (error) {
      console.log(error);
    }
  };
  const changeActivationHandler = async (id, value) => {
    const PayloadData = {
      IsActivated: !value,
    };
    let result = await UpdateOneData("policies", id, PayloadData);
    setReRender((pre) => !pre);
  };

  const headers = [
    "IsActivated",
    "Policy Name",
    "Policy Category",
    "Description",
    "Action",
  ];

  const filteredPolicyList = policyList.filter((item) => {
    const name = item?.attributes?.PolicyName || "";
    const description = item?.attributes?.Description || "";
    const category = item?.attributes?.CategoryName || "";
    const matchesSearchTerm =
      name.toLowerCase().includes(searchPolicyText.toLowerCase()) ||
      description.toLowerCase().includes(searchPolicyText.toLowerCase()) ||
      category.toLowerCase().includes(searchPolicyText.toLowerCase());

    return matchesSearchTerm;
  });

  useEffect(() => {
    getDataWithFilterUrl("policies", orgFilterUrl)
      .then((data) => {
        const list = data.data;
        list.sort((a, b) => {
          if (
            a?.attributes?.PolicyName.trim().toLowerCase() <
            b?.attributes?.PolicyName.trim().toLowerCase()
          ) {
            return -1;
          }
          if (
            a?.attributes?.PolicyName.trim().toLowerCase() >
            b?.attributes?.PolicyName.trim().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

        setPolicyList(list);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRender]);

  const ItemPerPage = 20;
  const totalPage = Math.ceil(filteredPolicyList.length / ItemPerPage);

  return (
    <BreadCrumbsPage>
      <div>
        <p className="text-3xl font-medium">Policies Builder</p>
        <div className="my-8">
          Create or edit ESG Policies that would support your ESG Reporting.
        </div>
        <div className={`flex justify-end cursor-pointer`}>
          <CreatePolicyButtton />
        </div>

        <LoadingPage isLoading={isLoading}>
          {filteredPolicyList.length === 0 ? (
            <NullData />
          ) : (
            <div className="mt-4">
              <SearchInput
                searchText={searchPolicyText}
                setSearchText={setSearchPolicyText}
                placeholderText={"Search for an any ESG Policies"}
              />

              <div className="mt-8">
                {filteredPolicyList.length > 0 && (
                  <div className="">
                    <Table removeWrapper selectionMode="single">
                      <TableHeader>
                        {headers.map((header, headerIndex) => (
                          <TableColumn key={headerIndex}>{header}</TableColumn>
                        ))}
                      </TableHeader>
                      <TableBody>
                        {filteredPolicyList.map((item, index) => {
                          const lowerPageLimit =
                            (currentPage - 1) * ItemPerPage;
                          const upperPageLimit = currentPage * ItemPerPage;
                          if (index < lowerPageLimit || index >= upperPageLimit)
                            return;

                          return (
                            <TableRow
                              key={item.id}
                              onClick={() => handleNavigateToMetric(item.id)}
                              className="cursor-pointer"
                            >
                              <TableCell>
                                <Switch
                                  isSelected={item?.attributes?.IsActivated}
                                  color="success"
                                  onValueChange={(event) => {
                                    changeActivationHandler(
                                      item.id,
                                      item?.attributes?.IsActivated
                                    );
                                  }}
                                ></Switch>
                              </TableCell>
                              <TableCell>
                                {item?.attributes?.PolicyName}
                              </TableCell>
                              <TableCell>
                                {item?.attributes?.CategoryName}
                              </TableCell>
                              <TableCell>
                                {StringLimit(
                                  item?.attributes?.Description,
                                  50
                                ) || "--"}
                              </TableCell>
                              <TableCell>
                                <DeleteTableItem
                                  deleteHandler={deleteFormHandler}
                                  item={item}
                                  userRole={userRole}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </div>
              {filteredPolicyList.length > 0 && (
                <div className="w-full mt-8 flex items-center justify-center">
                  <Pagination
                    showControls
                    total={totalPage}
                    color="secondary"
                    page={currentPage}
                    onChange={setCurrentPage}
                  />
                </div>
              )}
            </div>
          )}
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default PoliciesBuilder;
