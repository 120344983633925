import { Card, CardBody, Chip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";

const colorSchema = {
  social: "primary",
  Environment: "success",
  survey: "default",
  governance: "danger",
  "Reference Data": "secondary",
  "Vendor details": "default",
};

const SingleSearchComponent = ({ data }) => {
  const [count, setCount] = useState(0);
  const navigator = useNavigate();
  const { _orgId } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const naviageHandler = (data) => {
    let URLPath = "";
    if (data.type === "Evidence") {
      URLPath = process.env.REACT_APP_STRAPI_IP_ADDRESS + data?.url;
      window.open(URLPath);
    } else if (data?.category?.trim()?.toLowerCase() === "reference data") {
      URLPath =
        `/${_orgId}/` +
        data.category
          .toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim() +
        "/" +
        data.Name.toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim();
      navigator(URLPath);
    } else {
      URLPath =
        `/${_orgId}` +
        "/metrics/" +
        data.category
          .toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim() +
        "/" +
        data.Name.toLowerCase()
          .replace(/[\s_]+/g, "-")
          .replace(/-+/g, "-")
          .trim();
      navigator(URLPath);
    }

    // window.location.reload();
  };

  useEffect(() => {
    if (data?.type === "Evidence") return;
    const url = orgFilterUrl + "&filters[Metric_Id][$eqi]=" + data?.Name;

    getDataWithFilterUrl("responses", url).then((responeData) => {
      setCount(responeData?.data?.length || 0);
    });
  }, []);
  return (
    <Card
      shadow="none"
      className="my-8 border-[1.5px] border-solid border-gray-light cursor-pointer"
    >
      <CardBody onClick={() => naviageHandler(data)}>
        <div className="flex flex-col">
          <div className="flex">
            {data.type === "Evidence" ? (
              <lord-icon
                src="https://cdn.lordicon.com/rbbnmpcf.json"
                trigger="hover"
                target="#evidences"
                state="morph-home-2"
                colors={`primary: "#000000"`}
                style={{ width: "40px", height: "40px" }}
              ></lord-icon>
            ) : (
              <lord-icon
                src="https://cdn.lordicon.com/nizfqlnk.json"
                trigger="hover"
                target="#Metrics"
                state="morph-home-2"
                colors={`primary: "#000000"`}
                style={{ width: "40px", height: "40px" }}
              ></lord-icon>
            )}
            <div className="flex items-start justify-between mx-2 w-full">
              <div className="flex flex-col justify-between w-full">
                <span className="font-medium text-lg">{data?.Name}</span>
                <span className="text-sm text-gray font-medium mr-8">
                  {data?.type || "--"}
                </span>
              </div>
            </div>
          </div>
          {data?.type === "Metric" && (
            <div className="my-2">
              <div className="flex items-center gap-2 my-2">
                <Chip
                  color={colorSchema[data?.category || "default"]}
                  variant="flat"
                  size="sm"
                >
                  {data?.category}
                </Chip>
                {data?.TagList?.map((title) => (
                  <Chip
                    color={colorSchema[data?.category || "default"]}
                    variant="flat"
                    size="sm"
                  >
                    {title}
                  </Chip>
                ))}

                <Chip color="warning" variant="flat" size="sm">
                  No of Response : {count}
                </Chip>
              </div>
              <p className="font-medium">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Molestias distinctio ex libero.
              </p>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default SingleSearchComponent;
