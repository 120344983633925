import { useEffect, useState } from "react";
import { getAlldata } from "../controllers/APIController";

export default function useGetAllModels() {
  const [allModels, setAllModels] = useState([]);

  useEffect(() => {
    getAlldata("models")
      .then((data) => {
        const list = data?.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.attributes?.Title,
            functionDef: item?.attributes?.FunctionDef,
          };
        });
        setAllModels(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return { allModels, setAllModels };
}
