import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import EditUIFormBuilder from "./EditUIFormBuilder";
import EditJsonFormBuilder from "./EditJsonFormBuilder";
import { useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import {
  getDataWithFilterUrl,
  UpdateOneData,
} from "../../controllers/APIController";

export const EditFormBuilder = () => {
  const { _id, _orgId } = useParams();

  const [metricInfo, setMetricInfo] = useState({});
  const [originId, setOriginId] = useState(null);
  let tabs = [
    {
      id: "Using Form Builder UI",
      label: "Using Form Builder UI",
      content: <EditUIFormBuilder />,
    },
    {
      id: "Using JSON",
      label: "Using JSON",
      content: <EditJsonFormBuilder />,
    },
  ];

  const resetOriginHandler = async () => {
    const filteredUrl = `filters[id][$eqi]=${originId}`;
    const response = await getDataWithFilterUrl("metrics", filteredUrl);
    const originMetricInfo = response?.data[0]?.attributes;

    const data = {
      ...originMetricInfo,
      originId: originId,
    };

    const result = await UpdateOneData("metrics", metricInfo?.id, data);
    // setRenderPage((pre) => !pre);

    window.location.reload();
  };
  useEffect(() => {
    const filteredUrl = `filters[id][$eqi]=${_id}`;
    getDataWithFilterUrl("metrics", filteredUrl).then((data) => {
      setMetricInfo(data?.data[0]);
      setOriginId(data?.data[0]?.attributes?.originId);
    });
  }, []);

  return (
    <BreadCrumbsPage>
      <div className="w-full">
        <div className="flex items-center justify-between text-3xl font-medium">
          <span>Edit a metric</span>
          <Button
            color="danger"
            size="sm"
            onClick={resetOriginHandler}
            variant="ghost"
            className={`cursor-pointer ${!originId ? "hidden" : ""}`}
          >
            Reset to default
          </Button>
        </div>
        <div className="my-8">
          Edit a metric and build that would support your ESG Reporting
        </div>
        <Tabs aria-label="Dynamic tabs" items={tabs} variant="light">
          {(item) => (
            <Tab key={item.id} title={item.label}>
              {item.content}
            </Tab>
          )}
        </Tabs>
      </div>
    </BreadCrumbsPage>
  );
};
