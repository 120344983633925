import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Image} from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

function ThankYou() {
  return (
    <div>
      <div className="flex justify-center p-10">
        <Image width={100} alt="NextUI hero Image" src="/Assets/logo.svg" />
      </div>
      <div className="text-3xl font-medium text-center">
        Begin your ESG Journey
      </div>
      <p className="ml-96 mt-20">Hey,</p>
      <div className="flex flex-col justify-center items-center text-center" style={{ margin: 'auto', maxWidth: '800px' }}>
  
  <p className="mt-6">
    Thank you for choosing AudytESG as your ESG Partner. Your choice of
    AudytESG reaffirms our collective dedication to driving positive change
    in the corporate world. Together, we can elevate your organization's ESG
    performance and contribute to a more sustainable future for us all. If
    you have any questions or require assistance at any point, our support
    team is readily available at
    <span className=" font-semibold ml-2 mr-2">support-esg@audyt.io</span>
    We value the trust you've placed in AudytESG and look forward to a
    successful partnership. Thank you once again for choosing AudytESG as
    your preferred ESG software provider. We are excited about the positive
    impact we can create together.
  </p>
 
 
  
</div>
<div className="ml-96 mt-6">
<p>Best Regards,</p>
  <p className="font-semibold">Audyt Team</p>
</div>
<div className="flex justify-center">
    <Link to= {"/"}>
<Button color="primary">
    Head over to your ESG Dashboard
    <FontAwesomeIcon icon={faArrowRight}/>
  </Button>
  </Link>
  </div>


    </div>
  );
}

export default ThankYou;
