import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import TagsInput from "../FormBuilder/InputField/TagsInput";
import { InviteUser, sendSurvey } from "../../controllers/APIController";
import { addUsersToInvitation } from "../../utils/helper";

const InviteModal = ({ isOpen, onOpenChange, UserData, invitationList }) => {
  const [tags, setTags] = useState([]);
  const [validationError, setValidationErrors] = useState("");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const UserId =  UserLogged && UserLogged.user.id;
  const submitHandler = async (onClose) => {
    // await onSubmitHandler(id);
    if (tags.length === 0) {
      setValidationErrors("Please enter a email");
      return;
    }

    await addUsersToInvitation(tags, OrgInfo,UserId);
    
    setTags([]);
    onClose();
  };

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setValidationErrors("");
      setTags([]);
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
  };
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const Validation = (tags, Text, setValidatetags) => {
    let isValid = true;
    const EmailExists =
      UserData && UserData.some((data) => data.email === Text);

    if (tags.includes(Text)) {
      setValidatetags("Entered email already exists in invite list.");
      isValid = false;
    } else if (EmailExists) {
      setValidatetags("Entered email already exists.");
      isValid = false;
    } else if (invitationList.includes(Text)) {
      setValidatetags("invitation already send to mentioned email.");
      isValid = false;
    } else if (!EmailRegex.test(Text)) {
      setValidatetags("Enter a valid email.");
      isValid = false;
    } else {
      setValidatetags(null);
    }
    return isValid;
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Invite your team to your Audyt
              </ModalHeader>
              <ModalBody className="mb-4">
                <TagsInput
                  tags={tags}
                  setTags={setTags}
                  IntialvalidationError={validationError}
                  ValidatonFun={Validation}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteModal;
