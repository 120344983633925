import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  changeHandler,
  FileInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";

import { UpdateOneData } from "../../controllers/APIController";
import {
  AddTemplateSchema,
  intialtemplateData,
} from "../../constant/ImportData/importdata.constant";

const EditTemplateModal = ({
  item,
  id,
  isOpen,
  onOpen,
  onOpenChange,
  setReRenderPage,
}) => {
  const schemaData = AddTemplateSchema;

  const [data, setData] = useState(intialtemplateData);
  const [validationErrors, setValidationErrors] = useState({});

  const validationHandler = () => {
    setValidationErrors({});
    let isValid = true;
    const err = {};
    if (data["Name"].trim() === "") {
      err["Name"] = "Name is required!";
      isValid = false;
    }
    if (data["Description"].trim() === "") {
      err["Description"] = "Description is required!";
      isValid = false;
    }
    if (
      data["Attached Template"] === "" ||
      data["Attached Template"] === null ||
      data["Attached Template"] === undefined
    ) {
      err["Attached Template"] = "File is required!";
      isValid = false;
    }

    setValidationErrors(err);

    return isValid;
  };

  const submitHandler = async (onClose) => {
    const validationReponse = validationHandler();
    if (!validationReponse) return;

    const publishData = {
      Name: data["Name"],
      Description: data["Description"],
      TemplateUrl: data["Attached Template"]?.url,
      TemplateId: data["Attached Template"]?.value.toString(),
    };
    console.log(publishData);

    await UpdateOneData("templates", item?.id, publishData);

    setReRenderPage((pre) => !pre);
    onClose();
  };

  useEffect(() => {
    const name = item?.attributes?.Name;
    const description = item?.attributes?.Description;
    const templateUrl = item?.attributes?.TemplateUrl;
    const templateId = item?.attributes?.TemplateId;
    setData({
      Name: name,
      Description: description,
      "Attached Template": {
        value: templateId,
        url: templateUrl,
      },
    });
  }, [item]);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Template
              </ModalHeader>
              <ModalBody>
                <TextInput
                  data={schemaData["Name"]}
                  value={data["Name"]}
                  validationErrors={validationErrors}
                  onChangeHandler={(title, value, type) => {
                    changeHandler(title, value, type, data, setData);
                  }}
                  cssStyle="my-4"
                />
                <TextAreaInput
                  data={schemaData["Description"]}
                  value={data["Description"]}
                  validationErrors={validationErrors}
                  onChangeHandler={(title, value, type) => {
                    changeHandler(title, value, type, data, setData);
                  }}
                  cssStyle="my-4"
                />

                <FileInput
                  data={schemaData["Attached Template"]}
                  value={data["Attached Template"]}
                  inputfiledId="attached-template"
                  onChangeHandler={async (title, value, type) => {
                    console.log(title, value, type);
                    await changeHandler(title, value, type, data, setData);
                  }}
                  validationErrors={validationErrors}
                  cssStyle="mb-8"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditTemplateModal;
