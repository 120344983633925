import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

import {
  Image,
  Card,
  CardHeader,
  CardBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import MarkdownWithInputs from "../../components/Policies/MarkdownwithInputs";
import { editIcon } from "../../utils/constant";
import EditPolicyFrameWorkModal from "./EditPolicyFrameWorkModal";
import {
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  UpdateOneData,
} from "../../controllers/APIController";
import LoadingPage from "../../components/Loader/LoadingPage";
import { getImageForDomain } from "../../constant/Policies/policyConstant";
import ProcedureCard from "../../components/Policies/ProcedureCard";

const getAllProcedureFields = (text) => {
  let data = {};
  const handlebarRegex = /{{([^}]+)}}/g;
  let lastIndex = 0;
  let match;
  while ((match = handlebarRegex.exec(text)) !== null) {
    const x = match[1].trim();
    data = { ...data, [x]: "" };
    lastIndex = handlebarRegex.lastIndex;
  }
  return data;
};
const SinglePolicy = () => {
  const { _policy, _orgId: currOrg } = useParams();
  const policyName = _policy ? _policy.replace(/-/g, " ") : "";
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const HeadingTitle = capitalize(separateDasFromUrl(policyName));
  const filteredUrl = orgFilterUrl + "&filters[Name][$eqi]=" + HeadingTitle;
  const procedurefilteredUrl =
    orgFilterUrl + "&filters[Policy][$eqi]=" + HeadingTitle;

  const [loader, setLoader] = useState(true);
  const [markdownPolicyData, setMarkDownPolicyData] = useState({});
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [reRenderPage, setReRenderPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const [policy, setPolicy] = useState(null);
  const [procedures, setProcedures] = useState([]);

  const validation = () => {
    let flag = false;
    const err = {};
    for (let key in markdownPolicyData) {
      if (markdownPolicyData[key] === "") {
        flag = true;
        err[key] = `Filled this field!!`;
      }
    }
    if (flag) setErrorMessage(err);
    return flag;
  };

  const submitHandler = async () => {
    if (validation()) return;

    const updatedPolicyResponseData = {
      Response: { ...markdownPolicyData },
    };

    const result = await UpdateOneData(
      "policies",
      policy?.id,
      updatedPolicyResponseData
    );

    window.location.reload();
  };

  const resetPolicyData = async () => {
    const policyOriginId = policy?.attributes?.OriginId;
    const filteredUrl = `filters[id][$eqi]=${policyOriginId}`;
    const response = await getDataWithFilterUrl("policies", filteredUrl);
    const originPolicyInfo = response?.data[0]?.attributes;

    const data = {
      PolicyFramework: originPolicyInfo?.PolicyFramework,
      Response: null,
    };
    const result = await UpdateOneData("policies", policy?.id, data);

    window.location.reload();
  };


  useEffect(() => {
    getDataWithFilterUrl("policies", filteredUrl)
      .then((data) => {
        if (data.data && data.data.length > 0) {
          setPolicy(data.data[0]);
          const policyResponse =
            data.data[0]?.attributes?.Response ||
            getAllProcedureFields(data.data[0]?.attributes?.PolicyFramework);
          setMarkDownPolicyData(policyResponse);
        } else {
          setPolicy(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getDataWithFilterUrl("procedures", procedurefilteredUrl)
      .then((data) => {
        console.log(procedurefilteredUrl, data);
        setProcedures(data.data || []); // Set procedures array or empty array if no data
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filteredUrl, procedurefilteredUrl, reRenderPage]); // Include filteredUrl and procedurefilteredUrl in dependency array

  console.log(policy?.attributes?.PolicyFramework);
  
  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={loader}>
        <div>
          {policy && (
            <div className="flex justify-between">
              <div className="flex gap-4 items-center">
                <Image
                  alt="policy logo"
                  height={40}
                  radius="sm"
                  src={getImageForDomain(policy.attributes.Domain)}
                  width={40}
                />
                <p className="text-3xl font-medium">{policyName}</p>
              </div>
              <Button color="danger" onClick={submitHandler}>
                Update Policy
              </Button>
            </div>
          )}
          <div className="mt-8">{policy && policy.attributes.Description}</div>
          {
            <EditPolicyFrameWorkModal
              onReRender={setReRenderPage}
              onSubmitHandler={1}
              id={policy?.id}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
            />
          }
          <div className="flex items-start gap-8 mt-8">
            <div className="w-[65%]">
              <Card
                key={policy?.id}
                className="px-4 py-2 border border-divider"
                shadow="none"
              >
                <CardHeader className="flex items-center justify-end gap-2">
                  <Button
                    color="danger"
                    variant="ghost"
                    onClick={() => {
                      onOpen();
                    }}
                    // startContent={}
                    className="flex items-center justify-center"
                  >
                    {editIcon}
                    <span>Edit</span>
                  </Button>
                  <Button
                    color="secondary"
                    variant="ghost"
                    onClick={resetPolicyData}
                    // startContent={}
                    className="flex items-center justify-center"
                  >
                    <span>Reset</span>
                  </Button>
                </CardHeader>
                <CardBody>
                  <MarkdownWithInputs
                    markdownContent={policy?.attributes?.PolicyFramework}
                    procedureData={markdownPolicyData}
                    onChangeProcedureData={setMarkDownPolicyData}
                    errorMessage={errorMessage}
                  />
                </CardBody>
              </Card>
            </div>
            <div className="w-[30%] max-h-[500px] overflow-auto hide-scollbar">
              {procedures.length > 0 && (
                <>
                  <p className="text-xl font-medium mb-4">Procedures</p>
                  <div className="flex flex-col gap-4">
                    {procedures?.map((procedure) => (
                      <ProcedureCard
                        currOrg={currOrg}
                        _policy={_policy}
                        procedure={procedure}
                        key={procedure?.id}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default SinglePolicy;
