import { Button, Image, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import logo from "../../Images/logo.svg";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import {
  getDataWithFilterUrl,
  UpdateOneData,
} from "../../controllers/APIController";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decryptedText, encryptedText } from "../../utils/helper";
import LinkExpire from "../../components/ErrorPage/LinkExpire";

const SetNewPassword = () => {
    
  const [searchParams, setSearchParams] = useSearchParams();
  const encryptedEmail = searchParams.get("email");
  const limit = searchParams.get("limit");

  const [isVisible, setIsVisible] = useState(false);
  const [newPassword, SetNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [validationError, setValidationError] = useState("");

  const navigate = useNavigate();
  const toggleVisibility = () => setIsVisible(!isVisible);
  const validatePassword = () => {
    console.log(newPassword);

    let isValid = true;
    let errorMessage = "";
    const PassWordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (newPassword === "") {
      isValid = false;
      errorMessage = "Password is required.";
    } else if (!PassWordRegex.test(newPassword)) {
      isValid = false;
      errorMessage =
        "Password should contain lower-case, upper-case, digit, Special Character and have minimum length 8.";
    }

    setValidationError(errorMessage);
    return isValid;
  };

  const SubmitHandler = async () => {
    if (!validatePassword()) {
      return;
    }

    const encryptPassword = encryptedText(newPassword);

    console.log(encryptPassword);

    const result = await UpdateOneData("users", userDetail.id, {
      Password2: encryptPassword,
    });

    console.log(result);
    navigate("/sign-in");
  };

  useEffect(() => {
    getDataWithFilterUrl("users", `filters[email][$eqi]=${email}`)
      .then((data) => {
        if (data?.length > 0) setUserDetail(data[0]);
      })
      .catch((err) => console.log(err));
  }, [email]);

  useEffect(() => {
    const decryptedEmail = decryptedText(encryptedEmail);
    setEmail(decryptedEmail);
  }, [encryptedEmail]);

  console.log(userDetail);
  const currDate = Date.now();
  const isValidTime = currDate <= +limit;

  return (
    <>
      {!isValidTime ? (
        <LinkExpire />
      ) : (
        <>
          <div className={`flex h-screen items-center justify-center`}>
            <main className="mx-auto min-h-[500px] w-full max-w-[450px]">
              <Image width={50} alt="NextUI hero Image" src={logo} />
              <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
                Set New Password
              </h1>
              <p className="pb-8 mt-8 sm:text-left text-base text-slate-11 font-normal">
                Include the email address associated with your account and we’ll
                send you an email with instructions to reset your password.
              </p>
              <form data-gtm-form-interact-id="0">
                <div className="mb-4 space-y-2">
                  <Input
                    radius="sm"
                    className="border-slate-6"
                    key={"outside"}
                    type={isVisible ? "text" : "password"}
                    label="New Password"
                    variant={"faded"}
                    labelPlacement={"outside"}
                    value={newPassword}
                    onChange={(e) => SetNewPassword(e.target.value)}
                    placeholder="Enter new password"
                    isInvalid={validationError && validationError !== ""}
                    errorMessage={validationError}
                    endContent={
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={toggleVisibility}
                        aria-label="toggle password visibility"
                      >
                        {isVisible ? (
                          <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        ) : (
                          <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        )}
                      </button>
                    }
                  />
                </div>
                <div className="mb-4 space-y-2"></div>
                <div className="pt-8">
                  <Button
                    id="CreateAccount"
                    radius="sm"
                    onClick={SubmitHandler}
                    className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
                  >
                    Update Password
                    <lord-icon
                      src="https://cdn.lordicon.com/vduvxizq.json"
                      trigger="hover"
                      target="#CreateAccount"
                      colors={`primary: "#ffffff"`}
                      style={{
                        width: "18px",
                        height: "20px",
                      }}
                    />
                  </Button>
                </div>
              </form>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default SetNewPassword;
