import { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../controllers/APIController";

export default function useGetDefaultOrg() {
  const [OrgDetail, setOrgDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDataWithFilterUrl("organizations", `filters[Name][$eqi]=default`)
      .then((data) => {
        setOrgDetail(data?.data?.[0] || []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return { OrgDetail, isLoading };
}
