import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import React, { useState } from "react";
// import { ThemeProvider as NextThemesProvider } from 'next-themes';

const DeleteModal = ({
  isOpen,
  onOpenChange,
  DeleteHandler,
  Text = "Onboarding",
}) => {
  const [inputData, setInputData] = useState(null);

  // const Theme=sessionStorage.getItem("Theme")
  const deleteHandler = async (onClose) => {
    await DeleteHandler();
    onClose();
  };

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setInputData("");
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
  };

  return (
    <div className="h-full z-100 transform-null">
      {/* <NextThemesProvider defaultTheme={Theme}> */}
      <Modal
        size="xl"
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        placement="center"
        radius="sm"
      >
        <ModalContent className="py-4">
          {(onClose) => (
            <>
              <ModalBody className="gap-0">
                <span className=" block text-sm font-normal">
                  Are you sure you want to delete the{" "}
                  <strong className="font-bold text-slate-12 text-default-900">
                    {Text}
                  </strong>{" "}
                  collection?
                </span>
                <span className="text-sm text-danger mb-4 font-bold">
                  This can not be undone.
                </span>
                <Input
                  key="outside"
                  type="text"
                  radius="sm"
                  className="border rounded-lg border-content4"
                  label={
                    <p className="">
                      Type <strong>DELETE</strong> to confirm.
                    </p>
                  }
                  labelPlacement="outside"
                  placeholder=" "
                  onChange={(e) => setInputData(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>

                <Button
                  isDisabled={inputData !== "DELETE"}
                  color="danger"
                  onClick={() => deleteHandler(onClose)}
                >
                  Delete {Text}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {/* </NextThemesProvider> */}
    </div>
  );
};

export default DeleteModal;
