import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";

import { FieldList } from "../../utils/constant";
import PreviewForm from "../FormBuilder/PreviewForm";
import {
  UpdateOneData,
  UpdateSingleCollectionData,
  getDataWithFilterUrl,
  getSingleCollectionData,
} from "../../controllers/APIController";
import AddModal from "../FormBuilder/AddModal";
import FieldInfoTable from "../FormBuilder/FieldInfoTable";
import { useNavigate, useParams } from "react-router-dom";
import MetricTagInput from "../FormBuilder/InputField/MetricTagInput";

const initialFormData = {
  metricName: "",
  className: "",
  description: "",
};

const getJSonSchemaFromFieldInfoList = (fieldInfoList) => {
  const propertiesList = {};

  const requiredArr = [];
  fieldInfoList.forEach((data) => {
    const fieldInput = {};

    if (data["Required"]) {
      requiredArr.push(data["Title"]);
    }
    Object.keys(data).map((key) => {
      fieldInput[key.toLocaleLowerCase()] = data[key];
    });

    propertiesList[data["Title"]] = fieldInput;
  });

  const response = {
    type: "object",
    title: "",
    required: requiredArr,
    properties: propertiesList,
    description: "",
  };
  return response;
};

const getFieldInfoListFromGetJSonSchema = (List) => {
  const response = [];
  Object.keys(List).forEach((key) => {
    if (List[key].type === "relation") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        relatedTo: List[key].relatedto,
        metricName: List[key].metricname,
        isMultiSelect: List[key].ismultiselect,
      });
    } else if (List[key].type === "dropdown") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        values: List[key].values,
        isMultiSelect: List[key].ismultiselect,
      });
    } else if (List[key].type === "integer") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        min_val: List[key].min_val,
        max_val: List[key].max_val,
      });
    } else if (List[key].type == "slider") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        min_val: List[key].min_val,
        max_val: List[key].max_val,
        step_val: List[key].step_val,
      });
    } else if (List[key].type === "text" || List[key].type === "textarea") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        char_limit: List[key]?.char_limit || "",
      });
    } else if (List[key].type === "file") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        acceptFieldType: List[key]?.acceptfieldtype || [],
      });
    } else {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
      });
    }
  });

  return response;
};

const getLinkInfoListFromJson = (list) => {
  const response = [];

  list.forEach((item) => {
    response.push({
      id: item.id,
      Title: item.name,
      link: item.link,
      Type: "link",
      name: "link",
    });
  });
  return response;
};

const getLinkJsonReponse = (list) => {
  const reponse = [];
  list.forEach((item) => {
    reponse.push({
      id: item?.id,
      name: item.Title,
      link: item.link,
    });
  });
  return {
    Links: reponse,
  };
};
const UIMetricBuilderValidation = (formData, fieldInfoList, requiredArr) => {
  const err = {};
  let isValid = true;

  if (fieldInfoList.length === 0) {
    err["BuildData"] = "Build Data should have atleast one field";
    isValid = false;
  }

  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const EditUISurveyBuilder = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");

  const requiredArr = ["metricName", "categoryName", "jsonSchema"];
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [fieldType, setFieldType] = useState("");
  const [fieldInfoList, setFieldInfoList] = useState([]); // JsonSchema Input field info
  const [LinkInfoList, setLinkInfoList] = useState([]); // Link field info in Metric

  const [userRole, setUserRole] = useState("");
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged?.user?.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const navigate = useNavigate();

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenLink,
    onOpen: onOpenLink,
    onOpenChange: onOpenChangeLink,
  } = useDisclosure();

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagsValidation, setTagsValidation] = useState("");

  const { _id, _orgId } = useParams();
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const fieldListValues = Object.keys(FieldList).map((key) => {
    return [key, FieldList[key]];
  });
  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };

  const showPreviewHandler = () => {
    setShowPreview(false);
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const JsonSchemaData = getJSonSchemaFromFieldInfoList(fieldInfoList);

    const publishData = {
      Name: formData.metricName,
      ClassName: formData.className,
      Description: formData.description,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      TagList: tags,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };
  const submitHandler = async () => {
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }
    const JsonSchemaData = getJSonSchemaFromFieldInfoList(fieldInfoList);

    const publishData = {
      Name: formData.metricName,
      ClassName: formData.className,
      Description: formData.description,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      TagList: tags,
    };

    // console.log(publishData);

    let result = await UpdateOneData("metrics", _id, publishData);

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigate(`/${_orgId}/survey-builder`);
    // window.location.reload();
  };

  const addFieldHandler = (listItem) => {
    setFieldInfoList([...fieldInfoList, listItem]);
  };
  const addLinkHandler = (listItem) => {
    setLinkInfoList([...LinkInfoList, listItem]);
  };

  useEffect(() => {
    const filteredUrl = `filters[id][$eqi]=${_id}`;
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data[0];

        setFormData({
          metricName: response?.attributes?.Name || "",
          categoryName: response?.attributes?.Category || "",
          className: response?.attributes?.ClassName || "",
          description: response?.attributes?.Description || "",
          frameWork: response?.attributes?.Framework?.id || [],
        });

        getFieldInfoListFromGetJSonSchema(
          response?.attributes?.JsonSchema?.properties
        );
        setFieldInfoList(
          getFieldInfoListFromGetJSonSchema(
            response?.attributes?.JsonSchema?.properties
          )
        );
        setLinkInfoList(
          getLinkInfoListFromJson(response?.attributes?.Links?.Links)
        );

        setTags(response?.attributes?.TagList || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const userFilteredUrl = "filters[id][$eqi]=" + UserId;
    getDataWithFilterUrl("users", userFilteredUrl)
      .then((data) => {
        setUserRole(data[0]?.role?.name);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          value={formData["metricName"]}
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          value={formData["className"]}
          placeholder=" "
          labelPlacement="outside"
          name="className"
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          value={formData["description"]}
          className="my-4"
          name="description"
          onChange={changeHandler}
        />

        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* JsonSchema Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Build your Metric Schema</p>
            <Chip size="sm" radius="sm" color="danger" variant="flat">
              Beta
            </Chip>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Choose a data type and begin building your metric forms.
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Dropdown>
              <DropdownTrigger>
                <Button color="secondary" size="sm">
                  Add a new field
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                selectionMode="single"
                onSelectionChange={(event) => {
                  setFieldType(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                  onOpen();
                }}
              >
                {fieldListValues.map(
                  (row) =>
                    row[1].name !== "linkUrl" && (
                      <DropdownItem key={row[1].name} name={row[1].name}>
                        {row[1].title}
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          {isOpen && (
            <AddModal
              onSubmitHandler={addFieldHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
              fieldType={fieldType}
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={fieldInfoList}
            setFieldInfoList={setFieldInfoList}
          />
        </div>

        {/* Links Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Attach your reference links</p>
            <Chip size="sm" radius="sm" color="danger" variant="flat">
              Beta
            </Chip>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Attach reference links for users to fill your metrics easier
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenLink()}>
              Add a new link
            </Button>
          </div>

          {isOpenLink && (
            <AddModal
              onSubmitHandler={addLinkHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenLink}
              onOpen={onOpenLink}
              onOpenChange={onOpenChangeLink}
              fieldType="link"
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={LinkInfoList}
            setFieldInfoList={setLinkInfoList}
            headersList={["Name", "Link", ""]}
          />
        </div>

        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={submitHandler}
            className={`cursor-pointer ${
              userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
            }`}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default EditUISurveyBuilder;
