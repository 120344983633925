import { Button, Image, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import logo from "../../Images/logo.svg";
import { useNavigate } from "react-router-dom";

const LinkExpire = () => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate("/auth/reset-password");
  };
  return (
    <>
      <div className={`flex h-screen items-center justify-center`}>
        <main className="mx-auto min-h-[300px] w-full max-w-[450px]">
          <Image width={50} alt="NextUI hero Image" src={logo} />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Link Expired
          </h1>
          <p className="mt-8 sm:text-left text-base text-slate-11 font-normal">
            Reset reqest expired.Please generate a new password reset link.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore,
            dolorem sequi? Laboriosam!
          </p>
          <form data-gtm-form-interact-id="0">
            <div className="pt-8">
              <Button
                id="CreateAccount"
                radius="sm"
                onClick={clickHandler}
                className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
              >
                Send Reset Password Link
                <lord-icon
                  src="https://cdn.lordicon.com/vduvxizq.json"
                  trigger="hover"
                  target="#CreateAccount"
                  colors={`primary: "#ffffff"`}
                  style={{
                    width: "18px",
                    height: "20px",
                  }}
                />
              </Button>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default LinkExpire;
