import { Image } from "@nextui-org/react";
import React from "react";


const NotAccess = () => {


  return (
    <div className="flex items-center justify-center w-full h-128">
      <div className="flex flex-col w-[25%]">
        <Image width={80} alt="NextUI hero Image" src="/Assets/logo.svg" />
        <p className="my-4 text-xl font-bold">
          Don't have a access to that Page!!
        </p>
        <p className="text-sm font-medium">
          You do not have access to this page
        </p>
      </div>
    </div>
  );
};

export default NotAccess;
