import React, { useEffect, useState } from "react";
import { generateAPIKey } from "../../../utils/helper";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { FaCopy } from "react-icons/fa6";
import { PublishData } from "../../../controllers/APIController";
import Organization from "../Organization";

const ApiModal = ({ onSubmitHandler, isOpen, onOpen, onOpenChange }) => {
  const [keyName, setKeyName] = useState("");
  const [ApiKeyValue, setApiKeyValue] = useState();

  const [fieldError, setFieldError] = useState(null);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;

  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const handleCopyClick = () => {
    // Copy the value to the clipboard
    navigator.clipboard
      .writeText(ApiKeyValue)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const SubmitHandler = async (onClose) => {
    if (keyName === "") {
      setFieldError("Name field is required!");
      return;
    }

    const PayloadData = {
      api_key: ApiKeyValue,
      Organization: [OrgInfo],
      users_permissions_user: [UserId],
      name: keyName,
    };
    let result = await PublishData({ data: PayloadData }, "keys");
    onSubmitHandler();
    onClose();
  };

  const handleKeyDown = (event, onClose) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of form submission on Enter key press
      SubmitHandler(onClose);
    }
  };

  useEffect(() => {
    setApiKeyValue(generateAPIKey());
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <div
              onKeyDown={(event) => handleKeyDown(event, onClose)}
              tabIndex="0"
              className="outline-none"
            >
              <ModalHeader className="flex flex-col gap-1">
                Generate API Key
              </ModalHeader>
              <ModalBody>
                <div>
                  <Input
                    type="text"
                    className="mb-12"
                    isRequired
                    label={"Name"}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={keyName || ""}
                    onChange={(event) => {
                      setKeyName(event.target.value);
                    }}
                    isInvalid={fieldError}
                    errorMessage={fieldError}
                  />
                  <Input
                    type="text"
                    className="mb-8 mt-4"
                    isRequired
                    label={"API Key"}
                    endContent={
                      <FaCopy
                        onClick={handleCopyClick}
                        className="cursor-pointer"
                      />
                    }
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={ApiKeyValue || ""}
                    readOnly
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => SubmitHandler(onClose)}
                  onKeyDown={(event) => handleKeyDown(event, onClose)}
                >
                  Add
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApiModal;
