import React from "react";
import { useNavigate } from "react-router-dom";
import SingleSearchResult from "./SingleSearchResult";

const ShowSearchResults = ({
  searchResults,
  resultsRef,
  _orgId,
  InputText,
  setInputText,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="transition ease-in-out delay-200 absolute z-50 w-full px-8 pt-2 ">
        <div
          ref={resultsRef}
          className={`rounded-xl border border-divider divide-y divide-divider overflow-y-auto min-h-16 bg-white custom-scrollbar`}
          style={{ maxHeight: "325px" }}
        >
          <div
            className="p-4 cursor-pointer"
            onClick={() => {
              navigate(`/${_orgId}/search/${InputText}`);
              setInputText("");
            }}
          >
            <p
              id="File"
              className="flex flex-row items-center text-foreground gap-2"
            >
              <lord-icon
                src="https://cdn.lordicon.com/kkvxgpti.json"
                trigger="hover"
                colors={`primary #000000`}
                style={{ width: "20px", height: "20px" }}
              ></lord-icon>{" "}
              Search for "{InputText}"
            </p>
          </div>

          {searchResults?.length > 0 &&
            searchResults.map((data, index) => {
              return (
                <SingleSearchResult data={data} setInputText={setInputText} />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ShowSearchResults;
